import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fieldRequired } from '../../helpers/validation';
import { CanSavePoints } from '../../helpers/lookup';

export default class ScreenMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Confirmed: !CanSavePoints("9c")
        };
    }

    onCheckboxChange(evt) {
        this.setState({ Confirmed: evt.target.checked }, () => fieldRequired(this.state.Confirmed, "errConfirm", "* Required"));
    }

    render() {
        return (
            <>
                <Modal className='walkthrough-container' isOpen={true} fullscreen fade backdrop="static">
                    <ModalHeader className='text-center d-inline'>{this.props.Header}</ModalHeader>
                    <ModalBody className='text-justify mt-3'>
                        <h4 className="fs-5">Screen: 3 of 3</h4>
                        <p>You have the ability to navigate quickly between all the rich functionality available via the MENU</p>
                        <img src='/images/Walkthrough/menu-2.png' alt={"Posting"} className='img-fluid mb-3' />

                        

                        <div className='mt-3'>
                            <Input type='checkbox' className='me-2' defaultChecked={this.state.Confirmed} onChange={this.onCheckboxChange.bind(this)} />I've read the Menu and I understand how to navigate around the App
                            <div id="errConfirm" className='ErrorText'></div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="col-12 d-flex justify-content-between px-3">
                        <Button color='secondary' outline onClick={() => this.props.onStepChange("prev")} ><i className='fas fa-chevron-left me-2'></i> Prev</Button>
                        <Button color='success' onClick={this.onNextStep.bind(this)}>Complete <i className='far fa-check-circle ms-2'></i></Button>
                        {/* <Link to={"/"} color="success" className='btn btn-success'>Launch App <i className='fas fa-check-circle ms-2'></i></Link> */}
                    </ModalFooter>
                </Modal>
            </>
        )
    }

    onNextStep() {
        const isValid = fieldRequired(this.state.Confirmed, "errConfirm", "* Required");
        if (isValid) {
            this.props.onStepChange("next", "9c")
        }
    }
}

import React, { Component  } from 'react';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';
import { getTheme, getUser, setTheme, setThemeId } from '../../helpers/lookup';
import { InitiationCard } from '../UI/InitiationCard';
// import Toast from 'react-bootstrap/Toast';
// import { ToastContainer } from 'react-bootstrap';
import swal from "sweetalert";
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { LoadingSpinner } from '../../helpers/global';


//import { useParams } from 'react-router-dom';

export class CollaborationsList extends Component {

  static parentObj = null;
  static AllPosts = [];
  

  constructor(props) {
    super(props);

    CollaborationsList.parentObj = this;
    CollaborationsList.AllPosts = [];
    this.state = { themeData:[], editData: [], filteredData: [], urgentPosts: [], filteredUrgentPosts: [], loading: true, themes: [], showFilter: false, theme: '', distribution: '', showToast: false, themeId: '' };
    this.toggleFilter = this.toggleFilter.bind(this);
    
    this.setToast = this.setToast.bind(this);
    this.markAllAsRead = this.markAllAsRead.bind(this);
    
  }

  componentDidMount() {
    document.title = "NOW.CULI® :: Collaborations";
    this.loadData();
  }

  toggleFilter() {
    this.setState({ showFilter: !this.state.showFilter });
  }

  setToast(toastState) {
    this.setState({ showFilter: toastState });
  }


  static SearchCollaborations(keywords) {
    keywords = keywords.trim().toLowerCase();
    let filteredData = CollaborationsList.AllPosts.filter(c => !c.Urgent || c.Resolved);
    if (keywords.length > 2) {
      filteredData = filteredData.filter(
        (q) =>
          q.InitiationText.toLowerCase().indexOf(keywords) !== -1 ||
          q.Keywords.toLowerCase().indexOf(keywords) !== -1
      );
    } else {
      filteredData = CollaborationsList.parentObj.state.editData;
    }

    CollaborationsList.parentObj.setState({ filteredData });
    return filteredData.length > 0;
    }

  static renderDataTable(urgencyPosts, recencyPosts) {
    return (
      <div className="bg-gray">
        {urgencyPosts.length > 0 &&
          urgencyPosts.map((card, index) => (
            <InitiationCard key={card.Id} data={card} />
          ))
        }
        {recencyPosts.map((card, index) => (
          <InitiationCard key={card.Id} data={card} />
        ))}
      </div>
    );
  }

  async markAllAsRead(){
    
    //console.log("Mark all as read");
    let bearer = "bearer " + getAuthToken();
    let theme = getTheme();
    let themeId = this.state.themeId;
    
    ThrobbleHelper.toggleThrobble(true, "Marking all posts in "+theme+" as read...");

    try {
      const response =  await fetch(APIPath() + "/nowculi/Initiations/MarkAllAsReadById?themeId=" + themeId, {
        method: "POST",
        headers: {
          "Authorization": bearer,
          "Content-Type": "application/json"
        }
      });
      if (response.ok) {
        console.log("Marked all as read!");
        
        //CollaborationsList.parentObj.setState({ showToast: true })
        ThrobbleHelper.toggleThrobble(false);
        swal({
          title: "Mark All as Read", text: "All posts have been marked as read", icon: "success", button: { text: "Ok", }
        });
      }
    } catch (e) {
      console.error("Mark all as read error", e);
      ThrobbleHelper.toggleThrobble(false);
    }
  }

  render() {
    let data = this.state.filteredData;
    let urgentData = this.state.filteredUrgentPosts;
    let alreadyFiltered = false;

    if (this.state.distribution !== '') {
      if (alreadyFiltered) {
        data = data?.filter(item => item.ShareWith === this.state.distribution);
        urgentData = urgentData?.filter(item => item.ShareWith === this.state.distribution);
      } else {
        data = CollaborationsList.AllPosts.filter(item => item.ShareWith === this.state.distribution && (!item.Urgent || item.Resolved));
        urgentData = CollaborationsList.AllPosts.filter(item => item.ShareWith === this.state.distribution && (item.Urgent && !item.Resolved));
      }
    }
    let contents = this.state.loading ? LoadingSpinner() : CollaborationsList.renderDataTable(urgentData, data);
    
    return (
      <>
        <h1 className='fw-normal text-question'>{this.state.theme}</h1>
        <div className="list-filter text-center" >
          <div className='row'>
            <div className='col-6'>
              <div className="filter-container" onClick={this.toggleFilter}>
                
                <span className="text-dark">Showing Posts Shared With:</span><br /><span id="filterQShare" className="selectedFilter">{this.state.distribution ? this.state.distribution : "MY ZONE"}</span>
              </div>
              <div className="text-center" style={{ color: "#f1592a" }}><i>TAP TO CHANGE</i></div>
            </div>
            <div className='col-6 text-end'>
              <button className='btn-mark-all' onClick={this.markAllAsRead}>Mark all as read</button>
            </div>
          </div>
          
        </div>

        <div className="text-left px-3 bg-light pb-2" hidden={!this.state.showFilter}>
          <br />
          <b>Apply Content Filter</b><br />

          <div className="d-flex justify-content-between align-items-center">
            <div className="ui-block-a">
              <label className='px-3'>By distribution</label>
            </div>
            <div className="">

              <div className="allQuestSelect">
                <select className='dd-select fw-bold' value={this.state.distribution} onChange={(e) => this.setState({ distribution: e.target.value })}>
                  <option value="">MY ZONE</option>
                  <option value="group">Group</option>
                  <option value="country">Country</option>
                  <option value="region">Region</option>
                  <option value="division">Division</option>
                  <option value="team">Team</option>
                </select>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className='bg-gray'>
          {contents}
        </div>
       
      </>
    );
  }

  async loadData() {
    let idx = window.location.href.lastIndexOf("/");
    let themeId = window.location.href.substring(idx + 1);
    
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch(APIPath() + '/nowculi/Initiations/GetCollaborationsById/' + themeId, {
        method: 'GET',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        const dataFilteredUsingShareWith = this.filterUsingShareWith(data.InitiationData);
        setTheme(data.Theme.themeName);
        setThemeId(themeId);
        this.setState({  themeId: themeId, themeData: data.Theme, editData: dataFilteredUsingShareWith, filteredData: dataFilteredUsingShareWith, theme: decodeURI(data.Theme.themeName), loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }
  }

  filterUsingShareWith(data) {
    const userData = getUser();
    let filteredDataArray = [];
    if (data && data.length > 0) {
      data.map((obj) => {
        if (!obj.ShareWith) {
          filteredDataArray.push(obj);
          return null;
        }

        const allowedUserProperties = new Set([
          userData.Division,
          userData.OrganisationName,
          userData.Region,
          userData.Team,
          userData.UserCountry,
          ...userData.MentorMentees.map((partner) => partner.PartnerCode),
        ]);

        if (allowedUserProperties.has(obj.ShareWith)) {
          filteredDataArray.push(obj);
        }

        return null;
      }).filter(Boolean);
    }
    return filteredDataArray;
  }

}

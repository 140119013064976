import swal, { SweetAlertOptions } from "sweetalert2";

export const RenderSwirl = (title, text, icon, CallbackMethod) => {
    const swalOptions = {
        title,
        text,
        icon,
        showCancelButton: false,
        focusConfirm: true,
        confirmButtonText: "Ok",
        confirmButtonAriaLabel: "Ok",
        backdrop: true,
        background: "#fff"
    }

    swal.fire(swalOptions).then(() => {
        if (CallbackMethod)
            CallbackMethod();
    });
}

import React, { Component } from 'react';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';
import { MyContext } from '../../Provider';
import Row from './Row';

export class CompanyPurpose extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return (
            <>
                <div className="container-toolkit-article">
                    <h1>Purpose</h1>

                    <div className="d-flex flex-column align-items-center">
                        <img id="purposeImg" src={`https://m4smkmstor.blob.core.windows.net/purposemodels/${this.context.companyPurpose?.Model}`} className="img-responsive center-block" height={100} alt={this.context.companyPurpose?.Model} />
                        <p className='img-tag'>Press and Hold image to zoom </p>
                    </div>

                    <h2>Program name</h2>
                    <p className="plr-10" id="prName">{this.context.companyPurpose?.Name}</p>
                    <div className="container-purpose mp-0">
                        <Row Title={"Program"} />
                        <Row Title={"Purpose"} />
                        <Row Title={"Outcomes and Metrics"} />
                        <Row Title={"Themes and Knowledge Areas"} />
                        <Row Title={"Program benefits"} />
                        <Row Title={"Collaboration Tools"} />
                    </div>
                </div>
            </>
        )
    }

    async loadData() {
        const bearer = "Bearer " + getAuthToken();
        const UserData = JSON.parse(localStorage.getItem("CurrentUser"))
        try {
            const response = await fetch(
                APIPath() + `/nowculi/CompanyPurpose/?id=${UserData?.OrganisationId}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
                this.context.setCompanyPurpose(data)
            } else {
                console.log(response.status + ": " + response.statusText);
            }
        } catch (e) {
            console.error(e);
        }
    }

}

CompanyPurpose.contextType = MyContext


import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { fieldRequired } from '../../helpers/validation';
import { delint, linter } from '../../helpers/global';
import moment from 'moment';

export default class EditPost extends Component {

    static parentObj = null;
    constructor(props) {
        super(props);
        EditPost.parentObj = this;
        this.state = {
            showEditPost: false,
            Id: "",
            InitiationText: "",
            showUrgentCheckbox: false,
            Urgent: false,
            Resolved: false,
            ResolveDate: null
        }
        this.UpdatePost = this.UpdatePost.bind(this);
    }

    componentDidMount() {
        let { Id, InitiationText, Urgent, Resolved, ResolveDate } = this.props;
        InitiationText = delint(InitiationText);
        this.setState({ Id, InitiationText, Urgent, showUrgentCheckbox: Urgent && !Resolved, Resolved, ResolveDate });
    }

    static toggleEditPost() {
        let showEditPost = !EditPost.parentObj.state.showEditPost;
        EditPost.parentObj.setState({ showEditPost });
    }

    render() {
        let { showEditPost, InitiationText, Urgent, Resolved, ResolveDate, showUrgentCheckbox } = this.state;
        return (
            <Modal isOpen={showEditPost} zIndex={30000} className="modal-cyan">
                <ModalHeader className='shadow-sm'>EDIT INPUT</ModalHeader>
                <ModalBody className='border-0 shadow-lg'>
                    <div className="mb-3">
                        <textarea className='form-control' style={{ width: "100%", height: "80px" }} defaultValue={InitiationText} onChange={e => this.setState({ InitiationText: e.target.value })}></textarea>
                        <div id="errText" className='ErrorText'></div>
                    </div>
                    {(showUrgentCheckbox) &&
                        <div className={"px-3  d-flex"}>
                            <div className="w-75">
                                <label>
                                    <i className='fas fa-circle me-1 label-icon'></i>
                                    Is this issue resolved:</label>
                            </div>
                            <div className="w-25">
                                <div className="onoffswitch">
                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="questionResolved"
                                        tabIndex="0" data-role="none" checked={Resolved} onChange={e => this.setState({ Resolved: e.target.checked })} />
                                    <label className="onoffswitch-label" htmlFor="questionResolved">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }

                    {ResolveDate && <div className='px-3'>Issue was resolved on {moment(ResolveDate).format("ddd MMM DD YYYY HH:mm")}</div>}

                    <div className='d-flex justify-content-end mt-2 '>
                        <button className='btn-small-action' onClick={this.UpdatePost}>Update <i className='fas fa-chevron-right ms-2'></i></button>
                    </div>
                </ModalBody>
                <ThrobbleHelper />
            </Modal>
        );
    }

    async UpdatePost(evt) {
        evt.stopPropagation();

        let IsValid = fieldRequired(this.state.InitiationText, "errText", "* Required");

        if (IsValid) {
            let bearer = "Bearer " + getAuthToken();
            let data = {
                id: this.state.Id,
                mkm_input: linter(this.state.InitiationText),
                uResolved: this.state.Resolved
            }
            ThrobbleHelper.toggleThrobble(true, "Updating your input...");

            const Response = await fetch(APIPath() + "/nowculi/initiations", {
                method: "POST",
                headers: {
                    "Authorization": bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (Response.ok) {
                window.location.reload();
            } else {
                swal("Input Update", "There was an error updating your input!", "error");
                ThrobbleHelper.toggleThrobble(false);
            }
        }
    }
}

import React, { Component } from 'react'
import Cropper from 'cropperjs';
import "cropperjs/dist/cropper.min.css";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';


export default class PhotoCropper extends Component {

    static cropper = null;
    static parentObj = null;
    constructor(props) {
        super(props);
        PhotoCropper.parentObj = this;
        this.state = {
            showCropper: false,
            croppedData: null
        }

        this.CropperRef = React.createRef();
        this.onCrop = this.onCrop.bind(this);
    }

    componentDidMount() { }


    static toggleShowCropper(showCropper) {
        PhotoCropper.parentObj.setState({ showCropper });
    }


    OnOpen() {
        let img = document.getElementById("imCrop");
        let rotateRight = document.getElementById("rotateRight")
        let rotateLeft = document.getElementById("rotateLeft")
        let zoomIn = document.getElementById("zoomIn")
        let zoomOut = document.getElementById("zoomOut")
        PhotoCropper.cropper = new Cropper(img, {
            aspectRatio: 0,
            viewMode: 0,
            dragMode: 'none',
            minContainerHeight: 400,
            minContainerWidth: 315,

            ready() {
                zoomIn.addEventListener("click", () => {
                    this.cropper.zoom(0.1)
                })
                zoomOut.addEventListener("click", () => {
                    this.cropper.zoom(-0.1)
                })

                rotateRight.addEventListener("click", () => {
                    this.cropper.rotate(90);
                })
                rotateLeft.addEventListener("click", () => {
                    this.cropper.rotate(-90);
                })
                this.cropper.crop();
            }
        });
    }

    onCrop() {
        const imgElement = this.CropperRef.current;
        const crop = imgElement.cropper;
        let imageData = crop.getCroppedCanvas({
            width: 400,
            height: 400,
            fillColor: "#fff",
            imageSmoothingEnabled: true,
            imageSmoothingQuality: "high",
        }).toDataURL();

        if (this.props.OnPhotoCropped) {
            this.props.OnPhotoCropped(imageData);
            PhotoCropper.toggleShowCropper(false);
        }
    }

    render() {
        return (
            <Modal isOpen={this.state.showCropper} onOpened={this.OnOpen} fade={false} className='modal-cyan' zIndex={30000}>
                <ModalHeader className="shadow-sm text-center">
                    SELECT YOUR IMAGE
                </ModalHeader>
                <ModalBody className="border-0 shadow-lg">
                    <div className="row">
                        <div className='col-12 mb-3'>
                            <img id="imCrop" src={this.props.imgSrc} alt="" className='img-fluid' ref={this.CropperRef} />
                        </div>
                        <div className="col-3">

                            <button type='button' id="zoomIn" className='btn-iconless-action w-100'><i className='fas fa-plus'></i></button>
                        </div>
                        <div className="col-3">
                            <button type='button' id="zoomOut" className='btn-iconless-action w-100'><i className='fas fa-minus'></i></button>

                        </div>
                        <div className="col-3">
                            <button type='button' id="rotateRight" className='btn-iconless-action w-100'><i className='fas fa-rotate-right'></i></button>

                        </div>
                        <div className="col-3">
                            <button type='button' id="rotateLeft" className='btn-iconless-action w-100 btn-sm'><i className='fas fa-rotate-left'></i></button>

                        </div>
                        <div className="col-4 mx-auto my-3">
                            <button className='btn-alt cancel' onClick={() => PhotoCropper.toggleShowCropper(false)}>Cancel</button>
                        </div>
                        <div className="col-4 mx-auto my-3">
                            <button className='btn-action' onClick={this.onCrop}>Use</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

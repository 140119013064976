import React from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { MyContext } from '../../Provider'
const Row = ({ Title, arrow, onClick }) => {
    const { setTitle } = useContext(MyContext)
    if (!arrow) {
        return (
            <Link to="/detail" className='purpose-detail d-flex justify-content-between align-items-center' onClick={() => setTitle(Title)}>
                <h6 className="text-left">{Title.toUpperCase()}</h6>
                <i className={"gray fa fa-angle-right"}></i>
            </Link>
        )
    } else {
        return (<div className='purpose-detail d-flex justify-content-between align-items-center' onClick={onClick}>
            <h6 className="text-left">{Title.toUpperCase()}</h6>
            <i className={"gray fa fa-angle-" + arrow}></i>
        </div>
        )
    }

}

export default Row
import Cookies from "js-cookie";
export const getAppVersion = () => {
  return "5.2.3";
};

export const getAppVersionFromLocalStorage = () => {
  if (window.localStorage.getItem("AppVersion") !== null) {
    const data = JSON.parse(window.localStorage.getItem("AppVersion"));
    return data;
  }

  return null;
};

export const setAppVersion = (appVersion) => {
  try {
    window.localStorage.setItem("AppVersion", JSON.stringify(appVersion));
    return true;
  } catch (error) {
    return false;
  }
};

export const getDeviceId = () => {
  try {
    return Cookies.get("mkmdeviceid");
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const setDevice = (data) => {
  try {
    Cookies.set("mkmdeviceid", data, { expires: 9999 });
    return true;
  } catch (e) {
    return false;
  }
};

export const getFirebaseToken = () => {
  try {
    return Cookies.get("mkmdevicetoken");
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const setFirebaseToken = (token) => {
  try {
    if (token) {
      Cookies.set("mkmdevicetoken", token, { expires: 9999 });
      return true;
    } else
      return false;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getUser = () => {
  if (window.localStorage.getItem("CurrentUser") != null) {
    var data = JSON.parse(window.localStorage.getItem("CurrentUser"));
    return data;
  }
  else
    return null;
};

export const setUser = (User) => {
  try {
    window.localStorage.setItem("CurrentUser", JSON.stringify(User));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getUserId = () => {
  if (window.localStorage.getItem("CurrentUserId") != null) {
    return window.localStorage.getItem("CurrentUserId");
  }
  else
    return null;
};

export const setUserId = (UserId) => {

  try {
    window.localStorage.setItem("CurrentUserId", UserId);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getUserEmail = () => {
  if (window.localStorage.getItem("UserEmail") != null) {
    var data = JSON.parse(window.localStorage.getItem("UserEmail"));
    return data;
  }
  else
    return null;
};

export const setUserEmail = (User) => {

  try {
    window.localStorage.setItem("UserEmail", JSON.stringify(User));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getUserGroup = () => {
  if (window.localStorage.getItem("UserGroup")) {
    var data = JSON.parse(window.localStorage.getItem("UserGroup"));
    return data;
  }
  else
    return null;
};

export const setUserGroup = (User) => {

  try {
    window.localStorage.setItem("UserGroup", JSON.stringify(User));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getCreatedAt = () => {
  if (window.localStorage.getItem("CreatedAt")) {
    var data = JSON.parse(window.localStorage.getItem("CreatedAt"));
    return data;
  }
  else
    return null;
};

export const setCreatedAt = (User) => {

  try {
    window.localStorage.setItem("CreatedAt", JSON.stringify(User));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};


export const setEditId = (DataEditId) => {
  try {
    window.localStorage.setItem("DataEditId", JSON.stringify(DataEditId));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getEditId = () => {
  if (window.localStorage.getItem("DataEditId") != null) {
    var data = JSON.parse(window.localStorage.getItem("DataEditId"));
    return data;
  }
  else
    return null;
};

export const setEditData = (data) => {
  try {
    window.sessionStorage.setItem("EditData", JSON.stringify(data));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getEditData = () => {
  try {
    if (window.sessionStorage.getItem("EditData") != null) {
      var data = JSON.parse(window.sessionStorage.getItem("EditData"));
      return data;
    }
    else
      return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};


export const setNewAvatar = (data) => {
  try {
    window.sessionStorage.setItem("NewAvatar", JSON.stringify(data));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getNewAvatar = () => {
  try {
    if (window.sessionStorage.getItem("NewAvatar") != null) {
      var data = JSON.parse(window.sessionStorage.getItem("NewAvatar"));
      return data;
    }
    else
      return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const setSelectedGroup = (data) => {
  try {
    window.sessionStorage.setItem("SelectedGroup", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getSelectedGroup = () => {
  try {
    if (window.sessionStorage.getItem("SelectedGroup"))
      return window.sessionStorage.getItem("SelectedGroup");
    else
      return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const setSelectGroups = (data) => {
  try {
    window.sessionStorage.setItem("SelectGroups", JSON.stringify(data));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getSelectGroups = () => {
  try {
    if (window.sessionStorage.getItem("SelectGroups")) {
      var data = JSON.parse(window.sessionStorage.getItem("SelectGroups"));
      return data;
    } else
      return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const HasUpdatedWalkthrough = () => {
  try {
    const status = window.localStorage.getItem("WalkthroughStatus");
    return status == true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const setWalkthroughStatus = () => {
  try {
    window.localStorage.setItem("WalkthroughStatus", true);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getWalkthroughDetails = () => {
  try {
    const details = window.sessionStorage.getItem("Walkthrough");
    if (details)
      return JSON.parse(details);
    else
      return null;
  } catch (e) {
    console.error("Walkthrough Details:", e);
    return null;
  }
};

export const setWalkthroughDetails = (data) => {
  try {
    window.sessionStorage.setItem("Walkthrough", JSON.stringify(data));
    return true;
  } catch (e) {
    return false;
  }
};

export const getCurrentWalkthroughStep = () => {
  try {
    const CurrentWalkthroughStep = window.sessionStorage.getItem("CurrentWalkthroughStep");
    if (CurrentWalkthroughStep)
      return CurrentWalkthroughStep;
    else
      return "1";
  } catch (e) {
    console.error("Walkthrough Current Step Lookup ::", e);
    return "1";
  }
};

export const setCurrentWalkthroughStep = (step) => {
  try {
    window.sessionStorage.setItem("CurrentWalkthroughStep", step);
    return true;
  } catch (e) {
    console.error("Set Walkthrough Current Step ::", e);
    return false;
  }
};

export const getWalkthroughPoints = () => {
  try {
    const WalkthroughPoints = window.sessionStorage.getItem("WalkThroughPoints");
    if (WalkthroughPoints)
      return parseInt(WalkthroughPoints);
    else
      return 0;
  } catch (e) {
    console.error("Walkthrough Points Lookup ::", e);
    return 0;
  }
};

export const setWalkThroughPoints = (points) => {
  try {
    window.sessionStorage.setItem("WalkThroughPoints", points);
    return true;
  } catch (e) {
    console.error("Set Walkthrough point ::", e);
    return false;
  }
};

export const CanSavePoints = (step) => {
  const CurrentSteps = getStepsWithPoints();
  return CurrentSteps.findIndex(c => c === step) < 0;
};

export const getStepsWithPoints = () => {
  try {
    const StepsWithPoints = window.sessionStorage.getItem("StepsWithPoints");
    if (StepsWithPoints)
      return JSON.parse(StepsWithPoints);
    else
      return [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const setStepsWithPoints = (data) => {
  try {
    window.sessionStorage.setItem("StepsWithPoints", JSON.stringify(data));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const setTheme = (data) => {
  try {
    window.sessionStorage.setItem("CurrentTheme", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getTheme = () => {
  try {
    return window.sessionStorage.getItem("CurrentTheme");
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const setThemeId = (data) => {
  try {
    window.sessionStorage.setItem("CurrentThemeId", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getThemeId = () => {
  try {
    return window.sessionStorage.getItem("CurrentThemeId");
  } catch (e) {
    console.error(e);
    return "";
  }
};


export const setDeviceInfo = (data) => {
  try {
    window.sessionStorage.setItem("AppDeviceInfo", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const getDeviceInfo = () => {
  try {
    return window.sessionStorage.getItem("AppDeviceInfo");
  } catch (e) {
    console.error(e);
    return "";
  }
};




import { MenuItem, Select } from '@mui/material';
import React, { Component } from 'react'
import { alphabets } from '../../helpers/Alphabets';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';

export default class GlossaryOfTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chosenAlpha: "",
            terms: [],
            filteredTerms: [],
            glossarySource: []
        }

    }

    componentDidMount() {
        this.getTerms();
        this.getGlossarySources();
    }

    populateTerms = (e) => {
        const search = e?.target?.value;
        if (search) {
            this.setState({
                filteredTerms: this.state.terms.filter(term => {
                    return term?.refTerm?.toLowerCase().includes(search?.toLowerCase().trim().replaceAll(".", ""))
                })
            })
        } else if (e.length) {
            this.setState({
                filteredTerms: this.state.terms.filter(term => {
                    return term?.refTerm?.toLowerCase()[0] === e.toLowerCase()
                })
            })
        } else {
            this.setState({
                filteredTerms: this.state.terms
            })
        }

    }

    filterAcrByCompany = async (e) => {
        const comp = e.target.value;
        await this.getTerms(comp)
    }

    render() {
        return (
            <div data-role="content" className="mpt-0">
                <h1 id="refHeader">Glossary of terms</h1>
                <label className='d-flex align-items-center px-3'><span className="orange h1"> &#x2022;</span>Please select an term category</label>
                <div className='px-3'>
                    <select className="selTerms text-center d-flex justify-content-center mb-3" data-mini="true" style={{ "width": "95%", textAlignLast: "center", height: "30px", lineHeight: "30px" }} onChange={this.filterAcrByCompany}>
                        <option value="">Company</option>
                        {this.state.glossarySource.map((item,index)=>{
                            return <option value={item.abbr} key={index}>{item.Abbr}</option>
                        })}
                    </select>
                </div>
                <table style={{ "width": "90%" }} className='mx-3'>
                    <tbody>
                        <tr>
                            <td><input type="text" className=" text-center d-flex justify-content-center mb-3" placeholder="Enter term to search"
                                style={{ "width": "98%", textAlignLast: "center", height: "30px", lineHeight: "30px" }} onChange={this.populateTerms} /></td>
                        </tr>
                    </tbody>
                </table>
                <div className="d-flex justify-content-end mx-4">
                    <div className='alpha'>

                        <Select label="A-Z" className='bg-orange' value={this.state.chosenAlpha} displayEmpty onChange={(e) => {
                            this.setState({ chosenAlpha: e.target.value })
                            this.populateTerms(e.target.value)
                        }}>
                            <MenuItem value="">A-Z</MenuItem>
                            {alphabets.map((item,index)=> <MenuItem value={item} key={index}>{item}</MenuItem>)}
                        </Select>
                        <div className="fButtonsContainer">
                        </div>
                    </div>
                </div>
                {
                    this.state.filteredTerms.length === 0 ? <p className="text-center">No results found!</p> : this.state.filteredTerms.map((item, index) => {
                        return <div key={index}>
                            <div className='mx-3 my-2'>
                                <h3 className='teal'>{item.refDescription + "(" + item.refTerm + ")"}</h3>
                                <p>{item.refExplanation}</p>
                            </div>
                        </div>
                    })
                }

                <ul id="referenceContainerA" data-role="listview" data-autodividers="true"></ul>
            </div >

        )
    }
    async getTerms(comp) {
        var bearer = "Bearer " + getAuthToken();
        try {
            const response = await fetch(APIPath() + "/global/Term", {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },

            });
            if (response.ok) {
                let data = await response.json();
                if (comp?.length) {
                    data = data.filter(item => {
                        return item?.refCompany?.toLowerCase().includes(comp.toLowerCase().trim().replaceAll(".", "")) || item?.refSource?.toLowerCase().includes(comp.toLowerCase().trim().replaceAll(".", ""))
                    })
                }
                this.setState({ terms: data, filteredTerms: data })
            } else {
                console.log(response.status + ": " + response.statusText, response);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async getGlossarySources() {
        var bearer = "Bearer " + getAuthToken();
        try {
            const response = await fetch(APIPath() + "/global/GlossarySources", {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },

            });
            if (response.ok) {
                let data = await response.json();
                this.setState({ glossarySource: data })
            } else {
                console.log(response.status + ": " + response.statusText, response);
            }
        } catch (e) {
            console.error(e);
        }
    }
}

import React, { Component } from 'react'

export default class Glossaries extends Component {
    render() {
        return (
            <div className="content-padding">
                <div className='d-flex justify-content-center'>
                    <div data-role="dialog" id="glossaryLoad" className='shadow'>
                        <div data-role="header" data-tap-toggle="false" className='d-flex'>
                            <i className="fa fa-times-circle text-light" aria-hidden="true" style={{ fontSize: "25px" }} onClick={() => window.location = "/"}></i>
                            <h1 className='text-light w-75 mx-5'>Glossaries</h1>
                        </div>
                        <div role="main" className="ui-content">
                            <label className='d-flex align-items-center px-3'><span className="orange h1"> &#x2022;</span>Please select Glossary to Load</label>
                            <div className="d-flex justify-content-around">
                                <button type="button" className="loadAcronyms btn-grey w-50" data-transition="flow"
                                    width="90%" onClick={() => window.location.href = "/glossaryOfAcronyms"}>Acronyms</button>
                                <button type="button" className="loadTerms btn-grey w-50"
                                    data-transition="flow" onClick={() => window.location.href = "/glossaryOfTerms"}>Terms</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

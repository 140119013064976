import React, { Component } from "react";
import { MyContext } from "../../Provider";

export default class iosInstallGuide extends Component {
  render() {
    return (
        <div class="container-toolkit-article px-2">
        <h1>iOS Install Guide</h1>
        Installing Now.Culi&reg; on your iOS devices is a simple as following these steps:<br /><br />
        <b>STEP 1</b><br />
        Click the Share Icon (<i class="fa-solid fa-arrow-up-from-square"></i>) on the bottom of your screen<br /><br />
        <div class="text-center"><img src="../img/help-guides/ios-step1.jpg" class="img-fluid" alt="Step 1"  /></div>
        
        <br /><br />
    
        <b>STEP 2</b><br />
        Scroll down the share options and click the "Add to Home Screen" option<br /><br />
        <div class="text-center"><img src="../img/help-guides/ios-step2.jpg" class="img-fluid" alt="Step 2" /></div>
    
        <br /><br />
        <b>STEP 3</b><br />
        Click the "Add" button on the top right<br /><br />
        <div class="text-center"><img src="../img/help-guides/ios-step3.jpg" class="img-fluid" alt="Step 1" /></div>
        <br /><br />
    
        That's it! Your done, you can now access Now.Culi&reg; from your home screen.<br /><br />
        
    </div>
    );
  }
}
iosInstallGuide.contextType = MyContext;

import React, { Component } from "react";

import {  isMobileSafari,  isIOS,  isIOS13,  isIPhone13,} from "react-device-detect";

import {  cameraImg,  documentImg,  paperclipImg,  uploadvideoImg,  imageImg,  addvideoImg,} from "../../assets/MultiApi/images";
import { PostAttachmentsButton } from "./PostAttachmentsButton";
import { getSelectedGroup } from "../../helpers/lookup";
import PostResponseButton from "../UI/PostResponseButton";
import { Collapse } from "reactstrap";
import autosize from "autosize";
import { linter } from "../../helpers/global";

export class Attachments extends Component {
  constructor(props) {
    super(props);

    this.videoCaptureWidth = 320;
    this.videoCaptureHeight = 0;
    this.videoElement = null;
    this.canvas = null;
    this.photoElement = null;
    this.takePicBtns = null;
    this.snapPicBtn = null;
    this.audioElement = null;
    this.deviceCameras = [];
    this.mediaStream = null;
    this.mediaRecorder = null;

    this.state = {
      media: "",
      text: "",
      currentCamera: 0,
      isRecording: false,
      takingPicture: false,
      recordingVideo: false,
      recordingAudio: false,
      imageUpload: false,
      videoUpload: false,
      documentUpload: false,
      retry: false,
      componentType: this.props.componentType,
      showAttachments: false,
      isAppleDevice: false,
      charCounter: 750
    };
  }

  componentDidMount() {
    autosize(document.querySelector("textarea"));
    this.checkIsAppleDevice();
  }

  checkIsAppleDevice() {
    if (isMobileSafari || isIOS || isIOS13 || isIPhone13) {
      this.setState({
        isAppleDevice: true,
      });
    }
  }

  toggleUploadsState(imageUpload, videoUpload, documentUpload) {
    this.setState({
      imageUpload,
      videoUpload,
      documentUpload,
    });
  }

  handleUploads(event) {
    const reader = new FileReader();
    const upload = event.target.files[0];
    const setMediaState = (readerResult) => {
      this.setState({
        media: readerResult,
      });
    };
    const setUploadState = (imageUpload, videoUpload, documentUpload) => {
      this.toggleUploadsState(imageUpload, videoUpload, documentUpload);
    };
    this.toggleMediaCapturingState(false, false, false);

    reader.onloadend = function () {
      const result = reader.result;
      let mediaElement;
      let modifiedBase64;
      let uploadName;

      switch (upload.type.slice(0, upload.type.indexOf("/"))) {
        case "image":
          setUploadState(true, false, false);
          mediaElement = document.getElementById("imgAdd");
          mediaElement.src = result;
          setMediaState(result);
          break;

        case "video":
          setUploadState(false, true, false);
          mediaElement = document.getElementById("videoUpload");
          mediaElement.src = result;
          setMediaState(result);
          break;

        case "text":
          setUploadState(false, false, true);
          mediaElement = document.getElementById("fileName");
          uploadName = upload.name.replace(" ", "_");
          mediaElement.innerText = uploadName;
          modifiedBase64 =
            result.slice(0, `data:${upload.type};base64`.length) +
            "_" +
            uploadName +
            result.slice(`data:${upload.type};base64`.length);
          setMediaState(modifiedBase64);
          break;

        default:
          setUploadState(false, false, true);
          mediaElement = document.getElementById("fileName");
          uploadName = upload.name.replace(" ", "_");
          mediaElement.innerText = uploadName;
          modifiedBase64 =
            result.slice(0, `data:${upload.type};base64`.length) +
            "_" +
            uploadName +
            result.slice(`data:${upload.type};base64`.length);
          setMediaState(modifiedBase64);
          break;
      }
    };

    reader.readAsDataURL(upload);
  }

  toggleMediaCapturingState(takingPicture, recordingVideo, recordingAudio) {
    this.setState({
      takingPicture,
      recordingVideo,
      recordingAudio,
    });
  }

  toggleRecordingState(isRecording) {
    this.setState({
      isRecording,
    });
  }

  toggleRecordMedia() {
    this.mediaStream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  checkPermission(getVideo, getAudio) {
    this.toggleUploadsState(false, false, false);
    navigator.permissions.query({ name: "camera" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        if (getVideo && getAudio) {
          this.toggleMediaCapturingState(false, true, false);
        }
        if (getVideo && getAudio === false) {
          this.toggleMediaCapturingState(true, false, false);
        }
        if (getVideo === false && getAudio) {
          this.toggleMediaCapturingState(false, false, true);
        }
        this.activateCamera(getVideo, getAudio);
      }
    });
  }

  activateCamera(getVideo, getAudio) {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devicesInfo) => {

        devicesInfo.forEach((device) => {
          if (
            device.kind === "videoinput" &&
            !this.deviceCameras.includes(device.deviceId)
          ) {
            this.deviceCameras.push({ deviceId: device.deviceId, camera: device });
          }
        });
      })
      .catch((err) => {
        console.error("There was an error getting devices: ", err);
      })
      .then(() => {
        this.getMediaStream(getVideo, getAudio);
      });
  }

  switchCamera(e) {
    e.preventDefault();
    const { takingPicture, recordingVideo, recordingAudio } = this.state;
    let currentCamera = this.state.currentCamera;
    if (this.deviceCameras.length > 1) {
      switch (currentCamera) {
        case 0:
          currentCamera = 1;
          break;
        case 1:
          currentCamera = 0;
          break;
        default:
          currentCamera = 0;
          break;
      }

      this.setState({ currentCamera }, () => {
        this.toggleRecordMedia();
        if (takingPicture) this.getMediaStream(true, false);
        if (recordingVideo) this.getMediaStream(true, true);
        if (recordingAudio) this.getMediaStream(false, true);
      });


    }
  }

  cancelMedia(e) {
    e.preventDefault();
    this.setState({
      media: "",
    });
    this.toggleMediaCapturingState(false, false, false);
    this.toggleRecordMedia();
    this.toggleRecordingState(false);
  }

  async getMediaStream(getVideo, getAudio) {

    let videoSource = this.deviceCameras[this.state.currentCamera].deviceId;
    const constraints = {
      video: { deviceId: videoSource ? { exact: videoSource } : undefined },
      audio: getAudio,
    };

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => { console.log(stream); this.gotStream(stream, getVideo, getAudio) })
        .catch((error) => {
          console.error("An error occurred with media capture:", error);
        });
    }
  }

  setSrc(isStreaming, mediaElement, stream = null) {
    console.log(mediaElement)
    if ("srcObject" in mediaElement) {
      mediaElement.srcObject = isStreaming ? stream : null;
    } else {
      mediaElement.src = isStreaming
        ? window.URL.createObjectURL(stream)
        : null;
    }
  }

  gotStream(stream, getVideo, getAudio) {
    this.mediaStream = stream;
    this.videoElement = document.getElementById("videoCapture");
    this.audioElement = document.getElementById("audioCapture");
    this.canvas = document.getElementById("canvas");
    this.photoElement = document.getElementById("imgAdd");
    this.takePicBtns = document.getElementById("takePicBtns");
    this.snapPicBtn = document.getElementById("snapPicBtn");
    const setMediaState = (data) => {
      this.setState({
        media: data,
      });
    };
    document.getElementById("mediaCapture").classList.remove("d-none");

    // ************************************* Taking Picture *********************************************

    if (getVideo && getAudio === false) {
      this.setSrc(true, this.videoElement, stream);
      this.videoElement.controls = false;
      this.videoElement.play();

      this.videoElement.addEventListener(
        "canplay",
        (ev) => {
          this.videoCaptureHeight =
            this.videoElement.videoHeight /
            (this.videoElement.videoWidth / this.videoCaptureWidth);

          if (isNaN(this.videoCaptureHeight)) {
            this.videoCaptureHeight = this.videoCaptureWidth / (4 / 3);
          }

          this.videoElement.setAttribute("width", this.videoCaptureWidth);
          this.videoElement.setAttribute("height", this.videoCaptureHeight);
          this.canvas.setAttribute("width", this.videoCaptureWidth);
          this.canvas.setAttribute("height", this.videoCaptureHeight);
        },
        false
      );

      this.clearPhoto();
    }

    // ************************************* Recording Video *********************************************

    if (getVideo && getAudio) {
      this.setSrc(true, this.videoElement, stream);
      this.mediaRecorder = new MediaRecorder(stream);
      const reader = new FileReader();
      const dataArray = [];
      this.videoElement.muted = "muted";
      this.videoElement.controls = false;
      this.videoElement.play();

      this.mediaRecorder.onstart = function () { };

      this.mediaRecorder.ondataavailable = function (event) {
        dataArray.push(event.data);
      };

      reader.onloadend = function () {
        const data = reader.result;
        const videoElement = document.getElementById("videoCapture");

        videoElement.setAttribute("src", data);
        videoElement.load();
        setMediaState(data);
      };

      this.mediaRecorder.onstop = function () {
        const mediaData = new Blob(dataArray, { type: "video/mp4" });

        reader.readAsDataURL(mediaData);
      };
    }

    // ************************************* Recording Audio *********************************************

    if (getVideo === false && getAudio === true) {
      this.mediaRecorder = new MediaRecorder(stream);
      const reader = new FileReader();
      const dataArray = [];

      this.mediaRecorder.onstart = function () { };

      this.mediaRecorder.ondataavailable = function (event) {
        dataArray.push(event.data);
      };

      reader.onloadend = function () {
        const data = reader.result;
        const audioElement = document.getElementById("audioCapture");

        audioElement.setAttribute("src", data);
        audioElement.load();
        setMediaState(data);
      };

      this.mediaRecorder.onstop = function () {
        const mediaData = new Blob(dataArray, { type: "audio/mp3" });

        reader.readAsDataURL(mediaData);
      };
    }
  }

  retryMedia(evt) {
    evt.preventDefault();

    if (this.state.recordingVideo) {
      this.videoElement.src = "";
      this.setState({ media: "", retry: false });
      this.checkPermission(true, true);
    } else if (this.state.recordingAudio) {
      this.audioElement.src = "";
      this.setState({ media: "", retry: false });
      this.checkPermission(false, true);
    } else {
      this.photoElement.src = "";
      this.setState({ media: "" });
      this.checkPermission(true, false);
    }
  }

  startRecording(evt) {
    evt.preventDefault();
    this.toggleRecordingState(true);
    if (this.mediaRecorder.state === "inactive") {
      this.mediaRecorder.start();
    } else {
      console.warn("The media recorder is already active!");
    }
  }

  stopRecording(evt) {
    evt.preventDefault();
    this.toggleRecordingState(false);
    this.setState({
      retry: true,
    });

    if (this.mediaRecorder.state !== "inactive") {
      this.mediaRecorder.stop();
    } else {
      console.warn("The media recorder is currently inactive!");
      return;
    }

    if (this.state.recordingAudio) {
      this.audioElement.pause();
      this.audioElement.currentTime = 0;

      this.toggleRecordMedia();

      this.setSrc(false, this.audioElement);

      this.audioElement.load();
    } else {
      this.videoElement.pause();
      this.videoElement.currentTime = 0;
      this.videoElement.controls = true;
      this.videoElement.muted = false;

      this.toggleRecordMedia();

      this.setSrc(false, this.videoElement);

      this.videoElement.load();
    }
  }

  clearPhoto() {
    const context = this.canvas.getContext("2d");
    context.fillStyle = "#AAA";
    context.fillRect(0, 0, this.canvas.width, this.canvas.height);

    const data = this.canvas.toDataURL("image/png");
    this.photoElement.setAttribute("src", data);
  }

  takePhoto(evt) {
    evt.preventDefault();
    const context = this.canvas.getContext("2d");
    this.toggleUploadsState(true, false, false);
    if (this.videoCaptureWidth && this.videoCaptureHeight) {
      this.canvas.width = this.videoCaptureWidth;
      this.canvas.height = this.videoCaptureHeight;
      context.drawImage(
        this.videoElement,
        0,
        0,
        this.videoCaptureWidth,
        this.videoCaptureHeight
      );

      const data = this.canvas.toDataURL("image/png");
      document.getElementById("mediaCapture").classList.add("d-none");
      this.photoElement.setAttribute("src", data);
      this.setState({
        media: data,
      });
    } else {
      this.clearPhoto();
    }
  }

  handleTextChange(evt) {
    evt.preventDefault();
    const charCountValue = 750;
    const inputLength = evt.target.value.length;
    const charCounter = charCountValue - inputLength;
    linter(evt.target.value);
    this.setState({ text: evt.target.value, charCounter });
  }

  toggleAttachments() {
    this.setState({
      showAttachments: !this.state.showAttachments,
    });
  }

  render() {
    const SelectedGroup = getSelectedGroup();
    const {
      takingPicture,
      recordingVideo,
      recordingAudio,
      imageUpload,
      videoUpload,
      documentUpload,
      showAttachments,
      isRecording,
      retry,
      isAppleDevice,
    } = this.state;
    //const componentType = this.props.componentType === "question" ? this.props.componentType : this.props.componentType === "contribute" ? "contribution": "insight";

    return (
      <div className="plr-10">
        <label className="d-none">
          Add your{" "}
          {this.props.componentType === "contribute"
            ? "contribution"
            : this.props.componentType}{" "}
          {SelectedGroup && SelectedGroup !== "" && (
            <span> to {SelectedGroup} </span>
          )}
        </label>
        {/* <br />
        <br /> */}
        <div className="mediaElements">
          <div className="imgPreview" hidden={imageUpload === true ? false : true}          >
            <img id="imgAdd" src="" alt="To Upload" className="input-media-container" />
            <button className="retryBtn" onClick={(e) => this.retryMedia(e)} hidden={imageUpload === true && takingPicture === true ? false : true}>Retry</button>
          </div>

          <div className="fileNameContain docElement" hidden={documentUpload === true ? false : true}          >
            <p className="fileName" id="fileName"></p>
          </div>

          <video src="" controls id="videoUpload" className="input-media-container" crossOrigin="blob:" hidden={videoUpload === true ? false : true} ></video>

          <canvas id="canvas" className="d-none"></canvas>

          <div className="d-none" id="mediaCapture">
            <video src="" id="videoCapture" className="input-media-container" crossOrigin="blob:" preload="none" hidden={recordingVideo === true || takingPicture === true ? false : true} ></video>

            <audio src="" id="audioCapture" controls crossOrigin="blob:" hidden={recordingAudio === true ? false : true}            ></audio>

            <div className="takePicBtns" id="takePicBtns" hidden={takingPicture === true ? false : true}>
              <button id="cancelBtn" className="takePic" onClick={(evt) => this.cancelMedia(evt)}><i className="fas fa-times-circle fa-2x text-white"></i></button>
              <button id="snapPicBtn" className="takePic" onClick={(e) => this.takePhoto(e)}>
                <i className="fas fa-circle-camera fa-2x text-white"></i>
              </button>
              <button id="switchCameraBtn" className="takePic" onClick={(evt) => this.switchCamera(evt)}>
                <i className="fas fa-camera-rotate fa-2x text-white"></i>
              </button>
            </div>

            <button className="retryBtn" hidden={retry === true ? false : true} onClick={(e) => this.retryMedia(e)}>
              Retry
            </button>

            <div className="recordingBtns" id="recordingBtns" hidden={retry ? true : recordingVideo || recordingAudio ? false : true}>
              <button id="cancelBtn" className="recordingBtn" onClick={(evt) => this.cancelMedia(evt)}>
                <i className="fas fa-times-circle fa-2x text-white"></i>
              </button>
              {isRecording ? (
                <button id="stopRecordingBtn" className="recordingBtn" onClick={(evt) => this.stopRecording(evt)}>
                  <i className="fas fa-stop-circle fa-2x text-white"></i>
                </button>
              ) : (
                <button id="startRecordingBtn" className="recordingBtn" onClick={(evt) => this.startRecording(evt)}>
                  {recordingAudio ? (
                    <i className="fas fa-microphone fa-2x text-white"></i>
                  ) : (
                    <i className="fas fa-video-camera fa-2x text-white"></i>
                  )}
                </button>
              )}
              {recordingVideo && (
                <button id="switchCameraBtn" className="recordingBtn" onClick={(evt) => this.switchCamera(evt)}>
                  <i className="fas fa-camera-rotate fa-2x text-white"></i>
                </button>
              )}
            </div>
          </div>
        </div>

        <br />
        <Collapse isOpen={showAttachments} className="px-15 bg-white" id="collapseAttachments">
          <div className="table-attach">
            <div className="camera attachments" id="camera" onClick={() => this.checkPermission(true, false)}>
              <img src={cameraImg} alt="Camera" />
              <p>Take photo</p>
            </div>

            <div className="imgLibrary attachments">
              <input type="file" accept="image/*" id="imgLibrary" className="d-none" onChange={(event) => this.handleUploads(event)} />
              <label htmlFor="imgLibrary">
                <img src={imageImg} alt="Upload Camera" />
                <br />
                Upload photo
              </label>
            </div>

            <div className="uploadVideo attachments">
              <input type="file" accept="video/*" id="uploadVideo" className="d-none" onChange={(event) => this.handleUploads(event)} />
              <label htmlFor="uploadVideo">
                <img src={uploadvideoImg} alt="Camera" />
                <br />
                Upload video
              </label>
            </div>

            <div className="videoRecord attachments" id="videoRecord" onClick={() => this.checkPermission(true, true)}>
              <img src={addvideoImg} alt="Camera" />
              <p>Take video</p>
            </div>

            {/* <div
              className="voiceRecord attachments"
              id="voiceRecord"
              onClick={() => this.checkPermission(false, true)}
            >
              <img src={voiceImg} alt="Camera" />
              <p>Voice Note</p>
            </div> */}

            <div className="document attachments">
              <input type="file" id="uploadDocument" className="d-none" onChange={(event) => this.handleUploads(event)} />
              <label htmlFor="uploadDocument">
                <img src={documentImg} alt="Add Document" />
                <br />
                Upload Document
              </label>
            </div>
          </div>
        </Collapse>

        <br />

        <div className="w-100 vtop">
          <div className="addTextArea">
            {isAppleDevice ? (
              <div className="post-button-container">
                <div className="btn-circular-post">
                  <input type="file" id="appleAttach" className="d-none" onChange={(event) => this.handleUploads(event)} />
                  <label htmlFor="appleAttach" style={{ color: "black" }}>
                    <img src={paperclipImg} alt="PaperClip" />
                    {"\n"}
                    Attach
                  </label>
                </div>
              </div>
            ) : (
              <div className="post-button-container">
                <a className="btn-circular-post" href="#collapseAttachments" onClick={() => this.toggleAttachments()}>
                  <img src={paperclipImg} alt="PaperClip" />
                </a>
                <p>Attach</p>
              </div>
            )}
            <div className="textArea">
              <textarea id="qoverview" maxLength={750} placeholder={`Type your post here.`} onChange={(e) => this.handleTextChange(e)}></textarea>
              <p className="text_a_counter">
                {/* <span id="charCounter">750</span> Characters Left */}
                {this.state.charCounter} Characters Left
              </p>
            </div>
          </div>
        </div>
        {!this.props.returnState?.isResponse ? (
          <PostAttachmentsButton postOptionsState={this.props.returnState} attachmentsState={this.state} textInTextArea={this.state.text.length > 0}
          />
        ) : (
          <PostResponseButton postOptionsState={this.props.returnState} attachmentsState={this.state} textInTextArea={this.state.text.length > 0}
          />
        )}
        <br />
        { }
      </div>
    );
  }
}

export default Attachments;

import React, { Component } from "react";
import { getAuthToken } from "../../helpers/authentication";
import { APIPath } from "../../helpers/APIHelper";
import { Attachments } from "../Attachments/Attachments";
import { getUser, getTheme, setThemeId } from "../../helpers/lookup";

export class PostOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themes: [],
      shareWithDetails: [],
      theme: "",
      shareWith: "",
      field: "",
      isUrgent: false,
    };
    this.returnState = this.returnState.bind(this);
    //this.saveTheme = this.saveTheme.bind(this);
  }

  componentDidMount() {
    this.loadThemes();
  }

  handleCheck() {
    const isUrgent = this.state.isUrgent;

    this.setState({
      isUrgent: !isUrgent,
    });
  }

  returnState() {
    return this.state;
  }

  returnH1(componentType) {
    return "CREATE POST";
    // switch (componentType) {
    //   case "question":
    //     return "POSE A QUESTION";

    //   case "contribute":
    //     return "POST A CONTRIBUTION";

    //   case "insights":
    //     return "SHARE AN INSIGHT";

    //   default:
    //     break;
    // }
  }

  returnField(componentType) {
    return "Option to select who sees this post";
    // switch (componentType) {
    //   case "question":
    //     return "Option to select who sees this question";

    //   case "contribute":
    //     return "Optional knowledge field for this contribution";

    //   default:
    //     break;
    // }
  }

  async loadThemes() {

    var bearer = "Bearer " + getAuthToken();
    const userData = getUser();

    const shareWithData = [
      {
        Id: userData.OrganisationName,
        text: `group: ${userData.OrganisationName}`,
      },
      { Id: userData.UserCountry, text: `country: ${userData.UserCountry}` },
      { Id: userData.Region, text: `region: ${userData.Region}` },
      { Id: userData.Division, text: `division: ${userData.Division}` },
      { Id: userData.Team, text: `team: ${userData.Team}` },
    ];
    let mentees = userData.MentorMentees?.filter(
      (p) => p.UserType === "Mentor"
    );
    let mentors = userData.MentorMentees?.filter(
      (p) => p.UserType === "Mentee"
    );
    if (mentors.length > 0) {
      mentors.map((item) =>
        shareWithData.push({
          Id: item.PartnerCode,
          text: `mentor: ${item.PartnerName}`,
        })
      );
    }
    if (mentees.length > 0) {
      mentees.map((item) =>
        shareWithData.push({
          Id: item.PartnerCode,
          text: `mentee: ${item.PartnerName}`,
        })
      );
    }

    try {
      const response = await fetch(
        APIPath() + "/nowculi/Themes/ListForCompany",
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const themeName = decodeURI(getTheme());
        const data = await response.json();
        this.setState({ themes: data, shareWithDetails: shareWithData });
        this.setState({ theme: themeName});
        let obj = data.find(o => o.ThemeName === themeName);
        setThemeId(obj.Id);
        //console.log(obj);
        //this.saveTheme(decodeURI(getTheme()));
        //this.saveTheme(this.state.theme);
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }

  saveTheme(themeName)  {
    this.setState({ theme:  themeName});
    let obj = this.state.themes.find(o => o.ThemeName === themeName);
    setThemeId(obj.Id);
  }

  render() {
    const themes = this.state.themes;
    const shareWithDetails = this.state.shareWithDetails;

    return (
      <div>
        <form id="add_options">
          <div className="options-form">
            <h1 style={{ color: this.props.h1Color }}>
              {this.returnH1(this.props.componentType)}
            </h1>
            <div className="plr-10">
              <label>Knowledge theme for this post</label>
              <select id="themeContainer" value={this.state.theme} onChange={(e) => this.saveTheme(e.target.value )} >
                <option value="">Select Theme</option>
                {themes.length
                  ? themes.map((element) => (
                    <option key={element.Id} value={element.ThemeName}>
                      {element.ThemeName}
                    </option>
                  ))
                  : null}
              </select>
              <br />
              
              <br />
              <label>{this.returnField(this.props.componentType)}</label>
              {this.props.componentType === "question" ? (
                <select id="q1partnership" value={this.state.shareWith} onChange={(e) => this.setState({ shareWith: e.target.value })}>
                  <option value="">Share With</option>
                  {shareWithDetails.length &&
                    shareWithDetails.map(
                      (element, index) =>
                        element.Id && (
                          <option key={index} value={element.Id}>
                            {element.text}
                          </option>
                        )
                    )}
                </select>
              ) : (
                <select id="q1partnership" value={this.state.field} onChange={(e) => this.setState({ field: e.target.value })}                >
                  <option value="">Select knowledge field</option>
                  <option value="cthink">Critical Thinking</option>
                  <option value="bpracice">Best Practice</option>
                  <option value="leslearnt">Lessons Learnt</option>
                  <option value="knetwork">Knowledge Network</option>
                  <option value="none">None</option>
                </select>
              )}
              <br />
              <br />
              <div className="ui-grid-a">
                <div className="io-block-a" style={{ width: "100%" }}>
                  <label>
                    Optional choice for urgency of this post
                  </label>
                  <div className="ui-block-b" style={{ width: "35%" }}>
                    <div className="onoffswitch">
                      <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="isUrgent" tabIndex="0" checked={this.state.isUrgent} data-role="none" onChange={() => this.handleCheck()}
                      />
                      <label className="onoffswitch-label" htmlFor="isUrgent">
                        <span className="onoffswitch-inner"></span>
                        <span className="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />

          <Attachments componentType={this.props.componentType} returnState={this.returnState()} />
        </form>
      </div>
    );
  }
}

export default PostOptions;

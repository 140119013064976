export const getBase64FromImageUrl = (url, onComplete) => {
  var img = new Image();

  img.setAttribute('crossOrigin', 'anonymous');

  img.onload = function () {
    var canvas = document.createElement("canvas");
    canvas.width = this.width;
    canvas.height = this.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(this, 0, 0);

    var dataURL = canvas.toDataURL("image/png");

    if (onComplete != null)
      onComplete(dataURL);
  };

  img.src = url;
}

export const getBase64FromImage = (img, onComplete) => {

  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;

  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);

  var dataURL = canvas.toDataURL("image/png");

  if (onComplete != null)
    onComplete(dataURL);

}

export const getBase64FromFile = (file, onComplete) => {

  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    if (onComplete != null)
      onComplete(reader.result);
  };
  reader.onerror = (err) => console.error(err);
}

export async function reduce_image_file_size(base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) {
  let resized_base64 = await new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL()); // this will return base64 image results after resize
    }
  });
  return resized_base64;
}

export function calc_image_size(image) {
  let y = 1;
  if (image.endsWith('==')) {
    y = 2;
  }
  const x_size = (image.length * (3 / 4)) - y;
  return Math.round(x_size / 1024);
}

export async function process_image(file, min_image_size = 100) {
  if (file) {
    const old_size = calc_image_size(file);
    if (old_size > min_image_size) {
      const resized = await reduce_image_file_size(file);
      return resized;
    } else {
      console.log('image already small enough');
      return file;
    }

  } else {
    console.log('return err');
    return null;
  }
}
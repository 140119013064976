import React, { Component } from 'react';
import { GetAvatar, GetCurrentLevel, GetUserRanking } from '../../helpers/global';
import { setEditId } from '../../helpers/lookup';

export default class MenteeMentor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partners: []
        }

        this.viewProfile = this.viewProfile.bind(this);
    }

    viewProfile(userId) {
        setEditId(userId);
        window.location.href = "/viewprofile";
    }

    renderPartner(idx, partner) {
        return (
            <div className='row mb-3' key={idx} >
                <div className="col-3 px-2 text-center">
                    <div className="avatar-container  mx-2 small" onClick={() => this.viewProfile(partner.PartnerId)}>
                        <img id='myAvatar' alt="Avatar" src={GetAvatar(partner.PartnerAvatar)} />
                    </div>
                </div>
                <div className="col-9 test-start pt-2">
                    <div className="welcomeName fw-bold "> {partner.PartnerName}</div>
                    <div className='small'>Level {GetCurrentLevel(partner.PartnerScore)}</div>
                </div>
            </div>
        )
    }

   
    render() {

        let mentees = this.props.Partners.filter(p => p.UserType === "Mentor");
        let mentors = this.props.Partners.filter(p => p.UserType === "Mentee");

        console.log(mentees, mentors);
        return (
            <div hidden={this.props.hidden}>
                <div hidden={mentees.length === 0}>

                    <h2 className="orange-background">Mentee</h2>
                    <div className="container py-3">
                        {
                            mentees.map((item, index) =>
                                this.renderPartner(index, item)
                            )
                        }
                    </div>
                </div>
                <div hidden={mentors.length === 0}>
                    <h2 className="orange-background">Mentor</h2>
                    <div className="container py-3">
                        {
                            mentors.map((item, index) =>
                                this.renderPartner(index, item)
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

import React, { Component } from "react";
import { PostOptions } from "../Attachments/PostOptions";

export class PoseContribution extends Component {
  render() {
    return (
      <div className="addContributionContent">
        <PostOptions componentType="contribute" h1Color="#78cdd4"/>
      </div>
    );
  }
}

export default PoseContribution;

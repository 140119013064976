import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';
import { getDeviceId, getAppVersion, getDeviceInfo } from '../../helpers/lookup';
import autosize from 'autosize';
import swal from 'sweetalert';
//import { get } from 'jquery';


export default class AppFeedBack extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textareaCharacters: "",
            successModal: false,
            failedModal: false,
            deviceInfo: ""
        }
    }

    componentDidMount() {
        autosize(document.querySelector("textarea"));
        // this.setState({deviceInfo: JSON.stringify(navigator)});
        //  console.log(JSON.stringify(navigator));
        //  console.log(navigator.platform + " " + navigator.userAgent);
        //  console.log(getDeviceInfo());
        // console.log(this.state.deviceInfo);
    }
    
    render() {
        return (
            <div data-role="page" id="appFeedback">
                <div data-role="header" data-position="fixed" data-tap-toggle="false" data-fullscreen="false"
                    className="header-nav"></div>
                <div data-role="content" className="mpt-0">
                    <form id="addFeedback" className="text-left m-3">
                        <h1 className='px-2'>App Feedback</h1>
                        <label className='d-flex align-items-center'><span className="orange h1"> &#x2022;</span> Add your feedback</label>
                        <div className='d-flex justify-content-center'>
                            <textarea id="appFeedbacktext" maxLength={300} style={{ "width": "100%", height: "100px !important" }} onChange={(e) => this.setState({ textareaCharacters: e.target.value })}></textarea>
                        </div>
                        <div className='float-end'>
                            <p className="text_c_counter pt-2"><span id="appFBCounter">{300 - this.state.textareaCharacters.length}</span> Characters Left</p>
                            <div className="ui-grid-a">
                                <div className="d-flex justify-content-end">
                                    <div id="appFeedbackButt" className='appFeedback' onClick={() => this.postAppFeedback()}>Submit</div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div data-role="footer" data-tap-toggle="false" className="footer-nav" data-position="fixed"></div>
                {this.state.successModal &&
                    swal({
                        title: "Thank You!",
                        text: "Your feedback has been recorded!",
                        icon: "success",
                        button: {
                            text: "Ok"
                        }
                    }).then(() => {
                        this.setState({ successModal: false });
                        window.location.href = "/";
                    })
                }
                {this.state.failedModal && <div className='w-100 d-flex justify-content-center'>
                    <div className='shadow d-flex flex-column align-items-center justify-content-center justify-self-center w-75'>
                        <i className="fa fa-times-circle text-danger" aria-hidden="true" style={{ fontSize: "80px" }}></i>
                        <h1 style={{ fontSize: "30px" }}>Whoops!</h1>
                        <p className='thin text-center' style={{ fontSize: "18px" }}>Please enter your feedback in order to continue.</p>
                        <div className='d-flex justify-content-end w-100 p-3'>
                            <button className='float-right p-2' style={{ border: "1px solid" }} onClick={() => this.setState({ failedModal: false })}>ok</button>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }

    async postAppFeedback() {
        const userData = JSON.parse(localStorage.getItem("CurrentUser"))
        const data = {
            deviceId: getDeviceId(),
            dateAdded: new Date().toISOString(),
            group: userData?.OrganisationName,
            mkmUser: `${userData?.FirstName}%20${userData?.LastName}`,
            mkmfeedback: this.state.textareaCharacters,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            deleted: 0,
            appPlatform: navigator.platform + " - " + navigator.userAgent,
            appVersion: getAppVersion(),
            //userData?.AppVersion ? userData?.AppVersion : "2.1.0",
            kmiComments: null,
            kmiStatus: null,
        }

        if (data.mkmfeedback.length) {
            try {
                const dataQuery = Object.keys(data).map(item => `${item}=${data[item]}`).join("&")
                const bearer = "Bearer " + getAuthToken();
                const response = await fetch(APIPath() + `/nowculi/AppFeedback?${dataQuery}`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: bearer,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ data: data }),
                    }
                );

                if (response.ok) {
                    let res = await response.json();
                    console.log("Response from posting app Feedback :: ", res);
                    this.setState({ successModal: true })
                } else {
                    console.log(dataQuery)
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            this.setState({ failedModal: true })
        }
    }
}



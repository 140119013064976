import React, { Component } from 'react';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken, setAuthToken, setIsLoggedIn } from '../../helpers/authentication';
import { EncryptPassword } from '../../helpers/global';
import { getUserId, setUser } from '../../helpers/lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import swal from 'sweetalert';

export class UpdatePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            password: "",
            showPassword: false,
            hasLowercase: false,
            hasUppercase: false,
            hasNumber: false,
            hasMinimunLength: false
        };

        this.passwordRef = React.createRef("");
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.toggleShowPassword = this.toggleShowPassword.bind(this);
        this.SavePassword = this.SavePassword.bind(this);
    }

    componentDidMount() {
        let id = getUserId();
        this.setState({ id });
    }

    toggleShowPassword() {
        this.setState({ showPassword: !this.state.showPassword });
        this.passwordRef.current.focus();
    }

    onPasswordChange() {
        let value = this.passwordRef.current.value
        let regLower = /[a-z]/g;
        let regUpper = /[A-Z]/g;
        let regNumber = /\d/g;

        let hasLowercase = regLower.test(value);
        let hasUppercase = regUpper.test(value);
        let hasNumber = regNumber.test(value);
        let hasMinimunLength = value.length >= 8;

        if (hasMinimunLength) {
            this.passwordRef.current.classList.add("hasMin");
        } else {
            this.passwordRef.current.classList.remove("hasMin");
        }


        this.setState({ hasLowercase, hasUppercase, hasNumber, hasMinimunLength });
    }

    disableButton(isDisable, type) {
        if (type === "class" && isDisable)
            return "disabled";
        else
            return isDisable;

    }

    render() {

        let { showPassword, hasLowercase, hasUppercase, hasNumber, hasMinimunLength } = this.state;
        return (
            <div className='container password-container'>
                <div className="row ">
                    <div className="col-12">
                        <h1 className='text-center py-2 fw-bold'>Create Password</h1>
                    </div>

                    <div className="col-12 mb-3">
                        <label className='form-label'>
                            <i className="fas fa-circle label-icon me-2"></i>
                            Please create a new password
                        </label>
                        <div className="row px-4">
                            <div className="col-10">
                                <input id="tbPassword" type={showPassword ? "text" : "password"} className="form-control form-control-sm" ref={this.passwordRef} onChange={this.onPasswordChange} />
                            </div>
                            <div className="col-1">
                                <a className={'pt-2' + (this.state.showPassword && ' active')} onClick={this.toggleShowPassword}>
                                    <i className={'fas fa-eye'} ></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-3">
                        <button className={'btn-action ms-auto me-4'} disabled={!hasLowercase || !hasUppercase || !hasNumber || !hasMinimunLength} onClick={this.SavePassword}> Update <i className="fas fa-chevrons-right ms-2"></i></button>
                    </div>
                    <div className="col-12 mt-2 text-center">
                        <span className="fw-bold text-center mx-auto">Password must contain the following:</span>

                        <ul className="list-unstyled text-start mt-2 ps-3">
                            <li className={'mb-2 ' + (this.state.hasLowercase ? 'valid' : 'invalid')}>A <strong>lowercase</strong> letter</li>
                            <li className={'mb-2 ' + (this.state.hasUppercase ? 'valid' : 'invalid')}>A <strong>capital (uppercase)</strong> letter</li>
                            <li className={'mb-2 ' + (this.state.hasNumber ? 'valid' : 'invalid')}>A <strong>number</strong> </li>
                            <li className={'mb-2 ' + (this.state.hasMinimunLength ? 'valid' : 'invalid')}>Minimum <strong>8 Characters</strong></li>
                        </ul>
                    </div>
                </div>
                <ThrobbleHelper />
            </div>
        )
    }

    async SavePassword(evt) {
        evt.stopPropagation();
        ThrobbleHelper.toggleThrobble(true, "Updating Password...");
        var bearer = 'bearer ' + getAuthToken();
        try {
            let data = { Id: this.state.id, Password: EncryptPassword(this.passwordRef.current.value) };
            const response = await fetch(APIPath() + "/global/userprofile/updatepassword", {
                method: "PUT",
                headers: {
                    "Authorization": bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const user = await response.json();
                setAuthToken(user.LoginCookie);
                setUser(user);

                setTimeout(() => {
                    setIsLoggedIn(true);
                    window.location.href = "/";
                }, 2000);
            } else {
                ThrobbleHelper.toggleThrobble(false);
                swal("Password Update", "There was an error updating you Password. Please try again!", "error");
                console.log(response.status + ": " + response.statusText);
            }
        } catch (e) {
            console.error(e);
        }
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, NavbarToggler } from 'reactstrap';
import { ContributionList } from '../Contributions/Contributions';
import { Home } from '../Home/home';
import { InsightList } from '../Insights/Insights';
import { QuestionList } from '../Questions/Questions';
export class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hideBack: false,
      isSearchable: false,
      showSearchBlock: false,
      charLength: 0
    };
  }

  componentDidMount() {
    let currentPath = window.location.pathname;
    let hideBack = currentPath === "/";
    let isSearchable = currentPath === "/" || currentPath === "/questions" || currentPath === "/contributions" || currentPath === "/insights";
    this.setState({ hideBack, isSearchable });

    this.searchRef = React.createRef();
    this.searchErrorRef = React.createRef();
    this.toggleSearch = this.toggleSearch.bind(this);
    this.FilterContent = this.FilterContent.bind(this);
  }


  toggleSearch() {
    this.setState({ showSearchBlock: !this.state.showSearchBlock }, () => {
      if (this.state.showSearchBlock) {
        this.searchRef.current.focus();
      }
    });

  }

  render() {
    return (
      <>
        <header className="position-fixed top-0 start-0 w-100">
          <table className='w-100'>
            <tbody>
              <tr>
                <td><Link to={-1}><i className='fa fa-chevron-left text-white' hidden={this.state.hideBack || this.props.hideBack}></i></Link></td>
                <td>{this.props.Heading}</td>
                <td>
                  <NavbarToggler onClick={this.toggleSearch} hidden={!this.state.isSearchable}><i className='fas fa-magnifying-glass fw-bold'></i></NavbarToggler>
                </td>
              </tr>
            </tbody>
          </table>
          <Collapse isOpen={this.state.showSearchBlock} style={{ backgroundColor: "#f3f3f3" }} className="search-container  text-start">
            <label className="text-black fw-bolder fst-italic">Keyword Search</label>
            <input type={"text"} className="form-control fst-italic" placeholder='Search...' ref={this.searchRef} onChange={this.FilterContent} autoFocus/>
            <label className="fst-italic small text-muted" hidden={this.state.charLength >= 3}>Type {(3 - this.state.charLength)} more characters to filter</label>
            <div ref={this.searchErrorRef} hidden={true} className="mt-2">
              <p className='fst-italic text-danger'>No results found!</p>
            </div>
          </Collapse>
        </header>

      </>
    );
  }

  FilterContent() {
    let hasResults = true;
    let value = this.searchRef.current.value;
    if (Home.parentObj)
      hasResults = Home.SearchHome(value);
    if (QuestionList.parentObj)
      hasResults = QuestionList.SearchQuestions(value);
    else if (InsightList.parentObj)
      hasResults = InsightList.SearchInsights(value);
    else if (ContributionList.parentObj)
      hasResults = ContributionList.SearchContributes(value);
    this.searchErrorRef.current.hidden = hasResults;
    this.setState({ charLength: value.length });
  }
}

import React, { Component } from 'react';
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';

export class KnowledgeQuest extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {


        return (
            <>
                <div className="cv-container">
                    <h1>The Knowledge Quest</h1>
                    <UncontrolledAccordion stayOpen defaultOpen={[]}>
                        <AccordionItem className='mb-1'>
                            <AccordionHeader targetId='job-profile' className='fw-bold'>
                            Rating
                            </AccordionHeader>
                            <AccordionBody accordionId='job-profile'>
                            <p style={{ fontSize: "x-small" }}>Ratings are given via the universally understood 5 Star system, with 1 point per star and includes an additional 10 point option for an “Executive Rating” which is deemed to be an exceptional collaboration</p>
                        <div className="rating-container ">

                            <table className="ranking-list small w-100">
                                <thead>
                                    <tr>
                                        <th colSpan="2">Stars</th>
                                        <th>Rank</th>
                                        <th className="text-right">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td><img src="img/questions/1star.png" alt='' className="img-fluid" /></td>
                                        <td className="text-small">
                                            <b>Good</b><br />Collaboration
                                        </td>
                                        <td className="text-right">1 point</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td><img src="img/questions/2star.png" alt='' className="img-fluid" /></td>
                                        <td className="text-small">
                                            <b>Useful</b><br />Collaboration
                                        </td>
                                        <td className="text-right">2 point</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td><img src="img/questions/3star.png" alt='' className="img-fluid" /></td>
                                        <td className="text-small">
                                            <b>Great</b><br />Collaboration
                                        </td>
                                        <td className="text-right">3 point</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td><img src="img/questions/4star.png" alt='' className="img-fluid" /></td>
                                        <td className="text-small">
                                            <b>Significant</b><br />Collaboration
                                        </td>
                                        <td className="text-right">4 points</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td><img src="img/questions/5star.png" alt='' className="img-fluid" /></td>
                                        <td className="text-small">
                                            <b>Excellent</b><br />Collaboration
                                        </td>
                                        <td className="text-right">5 points</td>
                                    </tr>
                                    <tr>

                                        <td colSpan="2" className="text-small">
                                            <b>Executive rating</b>
                                            <img src="img/questions/diamond.png" alt='' className="img-fluid" /><br />

                                        </td>
                                        <td className="text-small">
                                            <b>Exceptional</b><br />Collaboration
                                        </td>
                                        <td className="text-right">10 points</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className='mb-1'>
                            <AccordionHeader targetId='experiential-profile' className='fw-bold'>
                            Status levels
                            </AccordionHeader>
                            <AccordionBody accordionId='experiential-profile' className=''>
                            <p style={{ fontSize: "x-small" }}>The key to effective knowledge exchange is the quality of collaboration. One of the best ways to nurture this is to recognise the “value of knowledge” that a peer and expert group places on the various collaborations of individuals.</p>
                        <p style={{ fontSize: "x-small" }}>The Knowledge Quest provides a simple mobile technique for “gamified progression” in order to achieve this and to track, recognise and reward the most valued collaborators.</p>
                        <div >
                            <img src="img/level-icons/all-levels.png" alt='' className="img-fluid center-block mb-3" />
                        </div>
                        <p style={{ fontSize: "x-small" }}>This provides an important motivator to participate in the process and to add value to the gathering and sharing of important contextual understanding and situationally relevant knowledge.</p>
                        <p style={{ fontSize: "x-small" }}>The Knowledge Status Levels of the Knowledge Quest are:</p>
                        <table className="level-table">
                            <tbody>
                            <tr className='mb-2'>
                                <td className='w-50 px-2 py-2 text-center'>
                                    <img src="img/level-icons/Level-1.png" alt='' className="img-fluid center-block mb-2" />
                                    
                                    <div className="text-small fw-bold">LEVEL 1:</div>
                                    <div className="fw-light pb-2">Erudite Explorer</div>
                                    <p style={{ fontSize: "x-small" }}>Initial levels of early and interesting knowledge contribution</p>
                                    <div className="fw-light" style={{ fontSize: "x-small" }}>(&ge;0 points)</div>

                                </td>
                                <td className='w-50 px-2 py-2 text-center'>
                                    <img src="img/level-icons/Level-2.png" alt='' className="img-fluid center-block mb-2" />
                                    
                                    <div className="text-small fw-bold">LEVEL 2:</div>
                                    <div className="fw-light pb-2">Aspirant Adventurer </div>
                                    <p style={{ fontSize: "x-small" }}>Improving levels of useful knowledge contribution</p>
                                    <div className="fw-light" style={{ fontSize: "x-small" }}>(&ge;1200 points)</div>
                                </td>
                            </tr>
                            <tr className='mb-2'>
                                <td className='w-50 px-2 py-2 text-center'>
                                    <img src="img/level-icons/Level-3.png" alt='' className="img-fluid center-block mb-2" />
                                    
                                    <div className="text-small fw-bold">LEVEL 3:</div>
                                    <div className="fw-light pb-2">Daring Discoverer </div>
                                    <p style={{ fontSize: "x-small" }}>Consistent levels of relevant knowledge contribution</p>
                                    <div className="fw-light" style={{ fontSize: "x-small" }}>(&ge;1800 points)</div>
                                </td>
                                <td className='w-50 px-2 py-2 text-center'>
                                    <img src="img/level-icons/Level-4.png" alt='' className="img-fluid center-block mb-2" />
                                    
                                    <div className="text-small fw-bold">LEVEL 4:</div>
                                    <div className="fw-light pb-2">Solid Sage </div>
                                    <p style={{ fontSize: "x-small" }}>Good levels of important knowledge contribution</p>
                                    <div className="fw-light" style={{ fontSize: "x-small" }}>(&ge;2800 points)</div>
                                </td>
                            </tr>
                            <tr className='mb-2'>
                                <td className='w-50 px-2 py-2 text-center'>
                                    <img src="img/level-icons/Level-5.png" alt='' className="img-fluid center-block mb-2" />
                                    
                                    <div className="text-small fw-bold">LEVEL 5:</div>
                                    <div className="fw-light pb-2">Knowledge Knight </div>
                                    <p style={{ fontSize: "x-small" }}>High levels of valued knowledge contribution</p>
                                    <div className="fw-light" style={{ fontSize: "x-small" }}>(&ge;4400 points)</div>
                                </td>
                                <td className='w-50 px-2 py-2 text-center'>
                                    <img src="img/level-icons/Level-6.png" alt='' className="img-fluid center-block mb-2" />
                                    
                                    <div className="text-small fw-bold">LEVEL 6:</div>
                                    <div className="fw-light pb-2">Mentoring Master </div>
                                    <p style={{ fontSize: "x-small" }}>Very high levels of significant knowledge contribution</p>
                                    <div className="fw-light" style={{ fontSize: "x-small" }}>(&ge;6900 points)</div>
                                </td>
                            </tr>
                            <tr className='mb-2'>
                                <td className='w-50 px-2 py-2 text-center'>
                                    <img src="img/level-icons/Level-7.png" alt='' className="img-fluid center-block mb-2" />
                                    
                                    <div className="text-small fw-bold">LEVEL 7:</div>
                                    <div className="fw-light pb-2">Worthy Wizard </div>
                                    <p style={{ fontSize: "x-small" }}>Exceptional levels of outstanding knowledge contribution</p>
                                    <div className="fw-light" style={{ fontSize: "x-small" }}>(&ge;10750 points)</div>
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                </div>
            </>
        )
    }
}

import { APIPath } from "./APIHelper";
import { getAuthToken, setAuthToken, setIsLoggedIn } from "./authentication";
import { ModifyMobileNumber } from "./global";
import { getDeviceId, getUserEmail, setCreatedAt, setSelectGroups, setUserEmail, setUserGroup, setUserId } from "./lookup";

export const CheckProfile = async (Email) => {
    try {
        const CurrentDeviceId = getDeviceId();
        const response = await fetch(APIPath() + "/global/login/GetUserByEmail?email=" + Email + "&deviceId=" + CurrentDeviceId);
        if (response.ok) {
            let data = await response.json();
            setUserEmail(data.Email);
            if (data.IsDeviceValid) {
                setUserId(data.Id);
                setUserGroup(data.OrganisationName);
                setCreatedAt(data.CreatedAt);
                setAuthToken(data.LoginCookie);
                if (process.env.NODE_ENV === "development")
                    window.location.href = "/plain/walkthrough";
                else
                    CheckGroupCount();
            } else {
                setTimeout(() => {
                    window.location.href = "/plain/userotp";
                }, 3000);
            }
            return false;
        } else {
            console.log(response.status + ": " + response.statusText);
            return true;
        }
    } catch (e) {
        console.error(e);
    }
}

export const CheckGroupCount = async () => {
    const bearer = "bearer " + getAuthToken();

    let email = getUserEmail();
    const response = await fetch(APIPath() + "/global/login/GetUserGroups?email=" + email, {
        method: "GET",
        headers: {
            "Authorization": bearer,
            "Content-Type": "application/json"
        }
    });

    if (response.ok) {
        let data = await response.json();
        if (data.length > 0) {
            if (data.length > 1) {
                setSelectGroups(data);
                window.location.href = "/plain/selectgroup";
            } else {
                data = data[0];
                setUserGroup(data.OrganisationName);
                setIsLoggedIn(true);
                window.location.href = "/";
            }
        } else
            return true;
    } else {
        return true;
    }
}


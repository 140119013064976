import React, { Component } from 'react';
import { getAuthToken } from '../../helpers/authentication';
import { APIPath } from '../../helpers/APIHelper';
import { InitiationCard } from '../UI/InitiationCard';

export class ContributionList extends Component {
  static parentObj = null;
  static AllPosts = [];

  constructor(props) {
    super(props);

    ContributionList.parentObj = this;
    ContributionList.AllPosts = [];
    this.state = { editData: [], filteredData: [], urgentData: [], filteredUrgentPosts: [], loading: true, themes: [], showFilter: false, theme: '', knowledge: '' };
    this.toggleFilter = this.toggleFilter.bind(this);
  }

  componentDidMount() {
    document.title = "NOW.CULI® :: Contributions";
    this.loadData();
  }

  static SearchContributes(keywords) {
    keywords = keywords.trim().toLowerCase();
    let filteredData = ContributionList.AllPosts.filter(c => !c.Urgent || c.Resolved);
    let filteredUrgentPosts = ContributionList.AllPosts.filter(c => c.Urgent && !c.Resolved);
    if (keywords.length > 2) {
      filteredData = filteredData.filter(
        (q) =>
          q.InitiationText.toLowerCase().indexOf(keywords) !== -1 ||
          q.Keywords.toLowerCase().indexOf(keywords) !== -1
      );
      filteredUrgentPosts = filteredUrgentPosts.filter((u) => u.InitiationText.toLowerCase().indexOf(keywords) > -1 || u.Keywords.toLowerCase().indexOf(keywords) > -1)
    } else {
      filteredData = ContributionList.parentObj.state.editData;
      filteredUrgentPosts = ContributionList.parentObj.state.urgentPosts;
    }
    console.log("Sidnindfinif2oif", filteredData, filteredUrgentPosts);
    ContributionList.parentObj.setState({ filteredData, filteredUrgentPosts }, () => console.log( ContributionList.parentObj.state));
    return filteredData.length > 0;
  }


  toggleFilter() {
    this.setState({ showFilter: !this.state.showFilter })
  }

  static renderDataTable(urgencyPosts, recencyPosts) {
    return (
      <div className="bg-gray">
        {urgencyPosts.length > 0 &&
          urgencyPosts.map((card, index) => (
            <InitiationCard key={card.Id} data={card} />
          ))
        }

        {recencyPosts.map((card, index) => (
          <InitiationCard key={card.Id} data={card} />
        ))}
      </div>
    );
  }

  render() {
    let data = this.state.filteredData;
    let urgentData = this.state.filteredUrgentPosts;
    if (this.state.theme !== '') {
      data = ContributionList.AllPosts.filter(item => item.Theme === this.state.theme && (!item.Urgent || item.Resolved));
      urgentData = ContributionList.AllPosts.filter(item => item.Theme === this.state.theme).filter(c => c.Urgent && !c.Resolved);
    }
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : ContributionList.renderDataTable(urgentData, data);
    const themes = this.state.themes;

    return (
      <>
        <h1 className='fw-normal text-contribute'>ALL CONTRIBUTIONS</h1>
        <div className="list-filter text-center" data-toggle="collapse" onClick={this.toggleFilter} role="button"
          aria-expanded="false" aria-controls="collapseContributionFilter">Content filters applied:
          <div className="filter-container">
            <span className="text-black">Theme:</span> <span id="filterCTheme" className="selectedFilter">{this.state.theme ? this.state.theme : " All"}</span>
            <span className="text-black"> By knowledge field:</span> <span id="filterCFields"
              className="selectedFilter">{this.state.knowledge ? this.state.knowledge : " All"}</span>
          </div>
          <div className="text-center text-orange" style={{ color: "#f1592a" }}><i>TAP TO CHANGE</i></div>
        </div>
        <div className="text-left px-3 bg-light pb-2" hidden={!this.state.showFilter}>
          <br />
          <b>Apply Content Filter</b><br />
          <div className="d-flex justify-content-between align-items-center">
            <div className="ui-block-a">
              <label className='px-3'>By theme</label>
            </div>
            <div className="ui-block-b">
              <select
                className='dd-select fw-bold'
                value={this.state.theme}
                onChange={(e) => this.setState({ theme: e.target.value })}
              >
                <option value="">{"ALL"}</option>
                {themes.length
                  ? themes.map((element) => (
                    <option key={element.Id} value={element.ThemeName}>
                      {element.ThemeName}
                    </option>
                  ))
                  : null}
              </select>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <div className="ui-block-a">
              <label className='px-3'>By knowledge field</label>
            </div>
            <div className="ui-block-b">
              <select className='dd-select fw-bold' onChange={(e) => this.setState({ knowledge: e.target.value })}>
                <option value="all">ALL</option>
                <option value="cthink">Critical Thinking</option>
                <option value="bpracice">Best Practice</option>
                <option value="leslearnt">Lessons Learnt</option>
                <option value="knetwork">Knowledge Network</option>
                <option value="none">None</option>
              </select>

            </div>
          </div>
        </div>
        <div className='bg-gray'>
          {contents}
        </div>
      </>
    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch(APIPath() + '/nowculi/initiations?Offset=0&Limit=50&Type=CONTRIBUTE', {
        method: 'GET',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, filteredData: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(
        APIPath() +
        `/nowculi/initiations/GetUrgentPosts/CONTRIBUTE`,
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        this.setState({
          urgentPosts: data,
          filteredUrgentPosts: data,
          loading: false
        });
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(
        APIPath() +
        `/nowculi/initiations/GetAllPosts/CONTRIBUTE`,
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        ContributionList.AllPosts = data;
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(
        APIPath() + "/nowculi/Themes/ListForCompany",
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        this.setState({ themes: data });
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }

}

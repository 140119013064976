
import React, { useState } from 'react'
import { Button, Input } from 'reactstrap'

export default function NumberInput(props) {

    const [inputValue, setInputValue] = useState(props.value);
    const [min] = useState((props.min !== undefined) ? props.min : Number.MIN_SAFE_INTEGER);
    const [max] = useState((props.max !== undefined) ? props.max : Number.MAX_SAFE_INTEGER);
    const [step] = useState((props.step !== undefined) ? props.step : 1);


    const CheckNumberIsValid = (number) => {
        if (typeof number !== "number") {
            number = parseInt(number);
        }
        if (number > max)
            number = max;
        else if (number < min)
            number = min;
        setInputValue(number);
        if (props.onChange)
            props.onChange(number);
    }

    const onValueChange = (evt) => {
        if (evt) {
            const value = evt.target.value;
            CheckNumberIsValid(value);
        }
    }

    const UpdateValue = (type) => {
        let total = inputValue;
        if (type === "up")
            total += step;
        else
            total -= step;
        CheckNumberIsValid(total);
    }

    return (
        <div className='number-input'>
            <Input type='number' value={inputValue} onChange={onValueChange}/>
            <button className='btn-step btn-up' onClick={() => UpdateValue("up")}><i className='fas fa-chevron-up'></i></button>
            <button className='btn-step btn-down' onClick={() => UpdateValue("down")}><i className='fas fa-chevron-down'></i></button>
        </div>)
}

import React, { Component } from "react";
import { getAuthToken } from "../../helpers/authentication";
import { APIPath } from "../../helpers/APIHelper";
import { InitiationCard } from "../UI/InitiationCard";
import { ProfileSummary } from "../Profile/ProfileSummary";
import { getUser, getUserId, setUser } from "../../helpers/lookup";
import InstallPrompt from "../InstallPrompt/InstallPrompt";
import { ConsoleView } from "react-device-detect";

export class Home extends Component {
  static parentObj = null;
  static AllPosts = [];
  constructor(props) {
    super(props);
    Home.parentObj = this;
    Home.AllPosts = [];
    this.state = { editData: [], filteredData: [], urgentPosts: [], filteredUrgentPosts: [], loading: true };
  }

  componentDidMount() {
    document.title = "NOW.CULI®";
    this.loadData();
  }

  static SearchHome(keywords) {
    keywords = keywords.trim().toLowerCase();
    let filteredData = Home.AllPosts.filter(c => !c.Urgent || c.Resolved);
    let filteredUrgentPosts = Home.AllPosts.filter(c => c.Urgent && !c.Resolved);
    if (keywords.length > 2) {
      filteredData = filteredData.filter(
        (q) =>
          q.InitiationText.toLowerCase().indexOf(keywords) !== -1 ||
          q.Keywords.toLowerCase().indexOf(keywords) !== -1
      );

      filteredUrgentPosts = filteredUrgentPosts.filter((u) => u.InitiationText.toLowerCase().indexOf(keywords) > -1 || u.Keywords.toLowerCase().indexOf(keywords) > -1)

    } else {
      filteredData = Home.parentObj.state.editData;
      filteredUrgentPosts = Home.parentObj.state.urgentPosts;
    }
    Home.parentObj.setState({ filteredData, filteredUrgentPosts });

    return filteredData.length > 0 || filteredUrgentPosts.length > 0;
  }

  static renderDataTable(urgencyPosts, recencyPosts) {
    return (
      <>
        <ProfileSummary />{
          (urgencyPosts.length > 0 || recencyPosts.length > 0) ?
            <div className="bg-gray">
              {urgencyPosts.length > 0 &&
                urgencyPosts.map((card, index) => (
                  <InitiationCard key={card.Id} data={card} TextLimit={350} />
                ))
              }

              {recencyPosts.map((card, index) => (
                <InitiationCard key={card.Id} data={card} TextLimit={350} />
              ))}
            </div>
            :
            <h3 className="text-center">No posts yet</h3>
        }
      </>
    );
  }

  render() {

    let contents = this.state.loading ? (
      <p className="text-center">
        <i className="fas fa-spinner fa-spin me-2"></i>Loading...
      </p>
    ) : (
      Home.renderDataTable(this.state.filteredUrgentPosts, this.state.filteredData)
    );

    return (
      <>
        {contents}
        <InstallPrompt />
      </>
    );
  }

  async loadData() {
    var bearer = "Bearer " + getAuthToken();
    let UserId = getUserId();
    //let count = 0;

    
    try {
      const response = await fetch(
        APIPath() + "/global/userprofile?id=" + UserId,
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const user = await response.json();
        setUser(user);
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

    try {
      //let orgiD = getUser().OrganisationId;
      const response = await fetch(
        APIPath() +
        `/nowculi/initiations?Offset=0&Limit=50&Type=All`,
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();

        const dataFilteredUsingShareWith = this.filterUsingShareWith(data);
        this.setState({
          editData: dataFilteredUsingShareWith,
          filteredData: dataFilteredUsingShareWith,
        });

      } else {
        this.setState({loading: false});
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {

      console.error(e);
    }

    try {
      const response = await fetch(
        APIPath() +
        `/nowculi/initiations/GetUrgentPosts/All`,
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        const urgentFilteredUsingShareWith = this.filterUsingShareWith(data);
        this.setState({
          urgentPosts: urgentFilteredUsingShareWith,
          filteredUrgentPosts: urgentFilteredUsingShareWith,
          loading: false
        });
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      this.setState({loading: false});
      console.error(e);
    }

    try {
      const response = await fetch(
        APIPath() +
        `/nowculi/initiations/GetAllPosts/All`,
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        Home.AllPosts = this.filterUsingShareWith(data);
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }


  }

  filterUsingShareWith(data) {
    const userData = getUser();
    let filteredDataArray = [];

    if (data && data.length) {
      filteredDataArray = data
        .map((obj) => {
          if (!obj.ShareWith) {
            return obj;
          }

          const allowedUserProperties = new Set([
            userData.Division,
            userData.OrganisationName,
            userData.Region,
            userData.Team,
            userData.UserCountry,
            ...userData.MentorMentees.map((partner) => partner.PartnerCode),
          ]);

          if (allowedUserProperties.has(obj.ShareWith)) {
            return obj;
          }

          return null;
        })
        .filter(Boolean);
    }
    return filteredDataArray;
  }
}

import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import { fieldRequired } from '../../helpers/validation';
import { getUserId, getWalkthroughDetails, setWalkthroughDetails } from '../../helpers/lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { getAuthToken } from '../../helpers/authentication';
import { APIPath } from '../../helpers/APIHelper';


export default class ScreenJobDescription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ProfBodyId: 0,
            JobTitle: getWalkthroughDetails().JobTitle,
            loading: true
        };

    }

    componentDidMount() {
        //this.loadData();
    }

    render() {
        return (
            <>
                <Modal className='walkthrough-container' isOpen={true} fullscreen fade backdrop="static">
                    <ModalHeader className='text-center d-inline'>{this.props.Header}</ModalHeader>
                    <ModalBody>
                        <h3 className='fs-2 fw-bolder'>JOB Title</h3>
                        <p>Please select your main Job Description or Discipline you work in</p>
                        <Input type='text' bsSize={"sm"} defaultValue={this.state.JobTitle} onChange={e => this.setState({ JobTitle: e.target.value })} />

                        <p className='text-muted small'>e.g. Civil Engineer, Programmer, Accountant, Project Manager etc.</p>
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-between'>
                        <Button color='secondary' outline onClick={() => this.props.onStepChange("prev")}><i className='far fa-chevron-left me-2'></i>Prev</Button>
                        <Button color='success' onClick={() => this.saveProfile()}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }

    async saveProfile() {
        if (this.state.JobTitle !== getWalkthroughDetails().JobTitle) {
            const bearer = "Bearer " + getAuthToken();
            ThrobbleHelper.toggleThrobble(true, "Saving Job Title");
            try {
                

                // const UserId = getUserId();
                // const data = { UserId, ProfileType: "job", ProfileValue: this.state.JobProfile };
                // const response = await fetch(APIPath() + "/global/walkthrough/UpdateCVProfile", {
                //     method: "PUT",
                //     headers: {
                //         Authorization: bearer,
                //         "Content-Type": "application/json"
                //     },
                //     body: JSON.stringify(data)
                // });

                const Id = getUserId();
                const data = { Id: Id, UpdateType: 10, UpdateValue: this.state.JobTitle };
                const response = await fetch(APIPath() + "/global/walkthrough/UpdateUserProfile", {
                    method: "PUT",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });



                if (response.ok) {
                    const result = await response.json();
                    if (result) {
                        let walkthroughData = getWalkthroughDetails();
                walkthroughData.JobTitle = this.state.JobTitle;
                setWalkthroughDetails(walkthroughData);
                    }

                    setTimeout(() => {
                        this.props.onStepChange("next", "7job");
                    }, 1500);
                }
            } catch (e) {

            }
        }
        else
        this.props.onStepChange("next", "7job");
    }
}

import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import { getAuthToken } from '../../helpers/authentication';
import InitialScreen from '../Login/InitialScreen';
import { Login } from '../Login/login';
import LogoBar from '../Nav/LogoBar';

export default class PreLogin extends Component {

    constructor(props) {
        super(props);
        this.state = { hasLogin: false, currentOS: "", loading: true }
    }

    componentDidMount() {

        let currentOS = navigator.userAgent.match(/(Android)|(IPhone)|(Windows)/gi)[0];
        let hasLogin = getAuthToken() && getAuthToken() != "";
        setTimeout(() => {
            this.setState({ currentOS, hasLogin, loading: false });
        }, 500)
    }

    renderLogin(hasLogin) {
        return (
            hasLogin ? <Login /> : <InitialScreen />
        )
    }

    render() {
        let content = this.state.loading ? <Spinner color='primary' className='fixed-bottom  mx-auto mb-3 ' /> : this.renderLogin(this.state.hasLogin);
        return (
            <div className="h-100">
                <LogoBar></LogoBar>
                <div className='landing-container h-100'>
                    {content}
                </div>
            </div>
        )
    }
}

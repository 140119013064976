import React, { Component } from 'react';
import { LoadingSpinner } from '../../helpers/global';

export default class Achievements extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalDiamonds: 0,
            totalWizards: 0,
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            totalDiamonds: this.props.TotalDiamonds,
            totalWizards: this.props.WizardsReached,
            loading: false
        })
    }

    renderAchievements(diamonds, wizards) {
        return (
            <div className='achievement-container mt-2'>
                <h2 className="orange-background">Past Achievements</h2>
                <table className='w-100 text-center mt-2'>
                    <tbody>
                        <tr>
                            <td width={"50%"}>
                                <img src="/img/questions/diamond-inactive.png" className='img-fluid pb-2 ' alt="Diamonds" style={{ maxWidth: "20%" }} /><br />
                                <b>Diamonds Awarded</b>
                            </td>
                            <td width={"50%"}>
                                <img src="/img/level-icons/inactive/level-7.png" className='img-fluid pb-2 ' alt="Wizards" style={{ maxWidth: "25%", paddingTop: "7px" }} /><br />
                                <b>Wizard Levels Reached</b>
                            </td>
                        </tr>
                        <tr>
                            <td className='colCount'>{diamonds}</td>
                            <td className='colCount'>{wizards}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        let content = this.state.loading ? LoadingSpinner() : this.renderAchievements(this.state.totalDiamonds, this.state.totalWizards);
        return (
            content
        )
    }
}

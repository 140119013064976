import React, { Component } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getWalkthroughDetails } from '../../helpers/lookup';
import { loader } from '../../helpers/loader';

export default class ScreenWelcome extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Firstname: "",
            loading: true
        }
    }

    componentDidMount() {
        const WalkthroughDetails = getWalkthroughDetails();

        const Firstname = WalkthroughDetails.Username.split(" ")[0];
        this.setState({ Firstname, loading: false });
    }

    onNext() {
        if (this.props.onStepChange)
            this.props.onStepChange("next");
    }

    renderWalkthroughStep() {
        return (
            <Modal className='walkthrough-container' isOpen={true} fullscreen fade backdrop="static" scrollable >
                <ModalHeader className='text-center d-inline'>{this.props.Header}</ModalHeader>
                <ModalBody className='text-justify'>
                    <p>
                        Hi {this.state.Firstname}, welcome to NOW.CULI, your very own personalised and safe social learning and career mentoring App.  (this name will change after migration into NOW.CULAR PWA). Before getting started, we need to make sure we have all your relevant information to assist with placing you in the correct collaboration groups and pairing options. Please take a minute or two to give us important information during the following walkthrough and familiarisation process. All your information is strictly protected in terms of POPIA and GDPR and other Personal Information Protection and Privacy legislation.
                    </p>
                    <p>You will start to "earn" a score as you complete all the 1st Time Registration and On-Boarding steps... so let's get going 😊</p>
                </ModalBody>
                <ModalFooter className='text-end'>
                    <Button color='success' outline onClick={this.onNext.bind(this)}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                </ModalFooter>
            </Modal>);
    }
    render() {

        const content = this.state.loading ? loader() : this.renderWalkthroughStep();
        return (
            content
        )
    }
}

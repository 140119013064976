export const APIHeaders = () => {
    return {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, OPTIONS",
        "Access-Control-Allow-Headers": "Authorization",
        'Authorization': "Basic bGFyYXZlbDojSW5jZTIwMjIh",
        'Content-Type': 'application/json'
    };
};

 
export const APIPath = () => {
  const NODE_ENV = process.env.NODE_ENV || "development";
  if (NODE_ENV === "development")
    return "https://localhost:7244"; //dev
  else
    return "https://kmishareapi.azurewebsites.net"; //prod
};

  
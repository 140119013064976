import React, { Component } from 'react'
import { Spinner, Toast } from 'reactstrap';

export default class ThrobbleHelper extends Component {
    static parentObj = null;
    constructor(props) {
        super(props);
        ThrobbleHelper.parentObj = this;
        this.state = {
            throbbleMessage: "",
            showThrobble: false
        }
    }

    static toggleThrobble(showThrobble, throbbleMessage) {
        ThrobbleHelper.parentObj.setState({ showThrobble, throbbleMessage });
    }

    render() {
        return (
            <div hidden={!this.state.showThrobble} className='throbble-wrapper'>
                <div className="throbble-inner">
                    <div className="spinner-block my-3">
                        <Spinner className='text-white'></Spinner>
                    </div>
                    <div className="message-block">
                        {this.state.throbbleMessage}
                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { getAuthToken } from "../../helpers/authentication";
import { APIPath } from "../../helpers/APIHelper";
import { paperplaneImg } from "../../assets/MultiApi/images";
import { getUser, getUserGroup } from "../../helpers/lookup";
import PushNotifications from "../../helpers/pushNotifications";
import ThrobbleHelper from "../../helpers/ThrobbleHelper";
import { getDeviceId } from "../../helpers/lookup";
import swal from "sweetalert";
import { linter } from "../../helpers/global";

export class PostAttachmentsButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUploaded: false,
      collaborateType: "",
      loading: false,
    };
    this.user = null;
  }
  componentDidMount() {
    this.user = getUser();
    //console.log(this.user, "dojd");
  }

  checkTextField(evt) {
    evt.preventDefault();

    const attachmentsComponentState = this.props.attachmentsState;
    const collabType =
      attachmentsComponentState.componentType === "question"
        ? `a ${attachmentsComponentState.componentType}`
        : attachmentsComponentState.componentType === "contribute"
          ? "a contribution"
          : "an insight";

    if (this.props.textInTextArea) {
      this.uploadMedia(evt);
    } else {
      swal("Posting", `Please type out ${collabType} before posting`, "error");
    }
  }

  async uploadMedia(e) {
    ThrobbleHelper.toggleThrobble(true, "Uploading, Please Wait....");
    this.setState({
      ...this.state,
      loading: true,
    });
    const attachmentsComponentState = this.props.attachmentsState;
    var bearer = "Bearer " + getAuthToken();
    let mediaObj;

    if (attachmentsComponentState.media.startsWith("data:image")) {
      mediaObj = JSON.stringify({
        fileId: this.user.Id,
        filename: `image_${this.user.Id
          }.${attachmentsComponentState.media.slice(
            attachmentsComponentState.media.indexOf("/") + 1,
            attachmentsComponentState.media.indexOf(";")
          )}`,
        base64File: attachmentsComponentState.media,
        mimeType: attachmentsComponentState.media.slice(
          attachmentsComponentState.media.indexOf(":") + 1,
          attachmentsComponentState.media.indexOf(",")
        ),
      });
    } else if (attachmentsComponentState.media.startsWith("data:video")) {
      mediaObj = JSON.stringify({
        fileId: this.user.Id,
        filename: `video_${this.user.Id
          }.${attachmentsComponentState.media.slice(
            attachmentsComponentState.media.indexOf("/") + 1,
            attachmentsComponentState.media.indexOf(";")
          )}`,
        base64File: attachmentsComponentState.media,
        mimeType: attachmentsComponentState.media.slice(
          attachmentsComponentState.media.indexOf(":") + 1,
          attachmentsComponentState.media.indexOf(",")
        ),
      });
    } else if (attachmentsComponentState.media.startsWith("data:audio")) {
      mediaObj = JSON.stringify({
        fileId: this.user.Id,
        filename: `audio_${this.user.Id
          }.${attachmentsComponentState.media.slice(
            attachmentsComponentState.media.indexOf("/") + 1,
            attachmentsComponentState.media.indexOf(";")
          )}`,
        base64File: attachmentsComponentState.media,
        mimeType: attachmentsComponentState.media.slice(
          attachmentsComponentState.media.indexOf(":") + 1,
          attachmentsComponentState.media.indexOf(",")
        ),
      });
    } else if (
      attachmentsComponentState.media.startsWith("data:text") ||
      attachmentsComponentState.media.startsWith("data:application")
    ) {
      let docName = attachmentsComponentState.media.slice(
        attachmentsComponentState.media.indexOf("_"),
        attachmentsComponentState.media.indexOf(",")
      );
      const modifiedBase64 = attachmentsComponentState.media.replace(
        docName,
        ""
      );
      docName = docName.replace("_", "");

      mediaObj = JSON.stringify({
        fileId: this.user.Id,
        filename: docName,
        base64File: modifiedBase64,
        mimeType: modifiedBase64.slice(
          modifiedBase64.indexOf(":") + 1,
          modifiedBase64.indexOf(";")
        ),
      });
    } else {
      this.uploadToDatabase();
      return;
    }

    try {
      const response = await fetch(`${APIPath()}/FileData`, {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: mediaObj,
      });

      if (response.ok) {
        let res = await response.json();
        this.uploadToDatabase(res.Url);
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleStateChange(isUploaded, collaborateType = "") {
    this.setState({
      isUploaded,
      collaborateType,
      loading: false,
    });
    ThrobbleHelper.toggleThrobble(false);
  }

  async uploadToDatabase(mediaUrlRef = "") {
    const postOptionsState = this.props.postOptionsState;
    const attachmentsComponentState = this.props.attachmentsState;
    //console.log(this.user);
    const postObject = {
      id: "",
      createdAt: new Date().toDateString(),
      updatedAt: new Date().toDateString(),
      deleted: false,
      mkm_userName: this.user.UserName,
      mkm_userId: this.user.Id,
      mkm_userType: this.user.UserType,
      mkm_userLevel: this.user.Level,
      mkm_collaborateType:
        attachmentsComponentState.componentType.toUpperCase(),
      mkm_reponseReference:
        attachmentsComponentState.componentType === "question"
          ? "QUESTION RESPONSE"
          : attachmentsComponentState.componentType === "contribute"
            ? "CONTRIBUTION RESPONSE"
            : "",
      smTag: "",
      mkm_dateAdded: "",
      mkm_theme: postOptionsState === null ? "" : postOptionsState.theme,
      mkm_group: getUserGroup(),
      shareWith: postOptionsState === null ? "" : postOptionsState.shareWith,
      mkm_input: linter(attachmentsComponentState.text),
      mkm_avatar: this.user.Avatar,
      mkmUserClass: "",
      mkm_inputDeviceId: getDeviceId(),
      mkm_knowledgeAssetType:
        postOptionsState === null ? "" : postOptionsState.field,
      avgRate: 0,
      execRateCount: 0,
      totalVotes: 0,
      inpImgUrlRef: mediaUrlRef,
      numResponses: 0,
      urgent: postOptionsState === null ? false : postOptionsState.isUrgent,
      uResolved: false,
      sentiment: 0,
      keywords: "",
      uResolveDate: "",
      org_ID: this.user.OrganisationId,
    };

    var bearer = "Bearer " + getAuthToken();
    try {
      const response = await fetch(APIPath() + "/nowculi/Initiations", {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postObject),
      });
      if (response.ok) {
        const collabType =
          attachmentsComponentState.componentType === "question"
            ? attachmentsComponentState.componentType
            : attachmentsComponentState.componentType === "contribute"
              ? "contribution"
              : "insight";

        this.handleStateChange(true, attachmentsComponentState.componentType);

        // PushNotifications.notifyGroup(
        //   this.user.OrganisationId,
        //   `${this.user.UserName} has posted a new ${collabType}`
        // );
      } else {
        console.log(response.status + ": " + response.statusText, response);
        ThrobbleHelper.toggleThrobble(false);
        swal("Posting", "Failed to post content! Please try again!", "error");
      }
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    let { isUploaded, collaborateType } = this.state;
    return (
      <div className="text-right">
        {isUploaded && (
          <Navigate to="/uploadsuccess" replace={true} state={{ collaborateType }} />
        )}
        <button id="attachmentsSubmit" className="btn-action-icon" onClick={(e) => this.checkTextField(e)}>
          <img src={paperplaneImg} alt="Paper Plane Top" />
          <br />
          Post
        </button>
        <ThrobbleHelper />
        {/* <PushNotifications /> */}
      </div>
    );
  }
}

export default PostAttachmentsButton;

import { Checkbox } from '@mui/material';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CanSavePoints } from '../../helpers/lookup';
import { fieldRequired } from '../../helpers/validation';

export default class ScreenThemes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Confirmed: !CanSavePoints("9a")
        }
    }

    onCheckboxChange(evt) {
        this.setState({ Confirmed: evt.target.checked }, () => fieldRequired(this.state.Confirmed, "errConfirm", "* Required"));
    }

    render() {
        return (
            <>
                <Modal className='walkthrough-container' isOpen={true} fullscreen fade backdrop="static">
                    <ModalHeader className='text-center d-inline'>{this.props.Header}</ModalHeader>
                    <ModalBody className='text-justify mt-3'>
                        <h4 className="fs-5 ">Screen: 1 of 3</h4>
                        
                            <p>Structured Social Learning is the ability to learn and share in a safe and managed environment where large groups of participants can share knowledge and ideas in selected smaller groupings of “one-to-few”  users, or in much larger open "many-to-many" collaboration groups. </p>

                            <p>NOW.CULI is designed to nurture an inquisitive and collaborative culture where users are encouraged to ask themselves: <br />
                            &bull; What do I need? <br />
                            &bull; What do I know? <br />
                            &bull; What can I share?</p>

                            <p>NOW.CULI is designed to help you achieve whatever your knowledge sharing objectives or needs are.</p>

                        <div className='col-12 text-center my-2'>
                            <img src='/images/Walkthrough/QUESTIONS@2x.png' alt={"Questions"} className='img-fluid ' />
                        </div>
                        <p>Pre-configured Knowledge Themes build powerful, dynamic and searchable Knowledge Repositories
                            which when combined with AI, build small language models of exponential value to participants.</p>
                        <div className='col-12 text-center my-2'>
                            <img src='/images/Walkthrough/SHARING&INSIGHTS.png' alt={"Shares and Insights"} className='img-fluid' />
                        </div>
                        <p>Participants "Score" through Peer-to-Peer and Peer-to-Professional "Ratings", which should be a measure of the value that the post offers to the group or any specific individuals. This is how we show collective appreciation for great collaborations questions, contributions or insights and it awards a higher score to participants that who post higher value collaborations as measured by the value that the tribe perceives.</p>

                        <p>The objective is to build and truly leverage the “wisdom of the tribe”.</p>

                        <div className="mt-3">
                            <Input type='checkbox' defaultChecked={this.state.Confirmed} onChange={this.onCheckboxChange.bind(this)} className='me-2' /> I've read this screen and I understand what Social Learning is
                            <div id="errConfirm" className='ErrorText'></div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="col-12 d-flex justify-content-between px-3">
                        <Button color='secondary' outline onClick={() => this.props.onStepChange("prev")} ><i className='fas fa-chevron-left me-2'></i> Prev</Button>
                        <Button color='success' onClick={this.onNextStep.bind(this)}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }

    onNextStep() {
        const isValid = fieldRequired(this.state.Confirmed, "errConfirm", "* Required");
        if (isValid) {
            this.props.onStepChange("next", "9a")
        }
    }
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useJsApiLoader, GoogleMap, Marker, Autocomplete } from "@react-google-maps/api"
// import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';
import "@reach/combobox/styles.css";
import { Button, Input } from 'reactstrap';

const libraries = ["places"];
export default function GoogleMapHelper(props) {
    const loadResult = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries
    });

    const isLoaded = loadResult.isLoaded;
    const propPosition = (props.position ? props.position : { lat: -33.8688, lng: 151.2195 });
    const center = { lat: -33.8688, lng: 151.2195 };
    const [address, setAddress] = useState("")
    const [zoomLvl, setZoomLvl] = useState(10);
    const [map, setMap] = useState(/** @type google.maps.Map  */(null))
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(true);
    const width = props.width ? props.width : "100%";
    const height = props.height ? props.height : "100vh";

    useEffect(() => {
        if (props.address) {
            setAddress(props.address);
            setZoomLvl(15);
            setSelected(propPosition);
        }
    }, [])


    const [autocomplete, setAutocomplete] = useState(/** @type google.maps.places.Autocomplete */(null))
    const inputRef = useRef((null));

    if (autocomplete) {
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (place.address_components && place.geometry) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                const position = { lat, lng };
                map.setCenter(position);
                setSelected(position);
                setZoomLvl(15);
                if (props.onLocationSelected)
                    props.onLocationSelected(place.address_components, place.geometry);
            }
        })
    }

    const renderMap = () => {
        return (
            <>
                <Autocomplete onLoad={args => setAutocomplete(args)}  >
                    <Input type='text' defaultValue={address} placeholder='Enter a Location' ref={inputRef} />
                </Autocomplete>

                <div className='mt-2' style={{ width, height }}>
                    <GoogleMap onLoad={(map) => setMap(map)} zoom={zoomLvl} center={selected ? selected : center} mapContainerStyle={{ width: "100%", height: "100%" }} options={{
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        zoomControl: false,
                        draggableCursor: false,
                        draggingCursor: false
                    }}>
                        {selected && <Marker position={selected}></Marker>}
                    </GoogleMap>
                </div>
            </>
        )
    }

    const ResetValues = () => {
        setSelected(null);
        setAddress(null);
        setZoomLvl(10);
    }

    return (
        <>
            {isLoaded && renderMap()}
        </>
    )
}

import React, { Component } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from 'react-router-dom';
import { GetAvatar, GetCurrentLevel, ShortenLink, linter } from '../../helpers/global';
import { getUser, setEditId } from '../../helpers/lookup';
//import * as Date from '../../helpers/date-prototypes'

export class ResponseCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.toggleEdit = this.toggleEdit.bind(this);
        this.viewProfile = this.viewProfile.bind(this);
    }

    formatText(text) {
        var idx = text.indexOf('(@attachment)');
        if (idx >= 0) {
            text = text.substring(0, idx - 1);
        }

        var tagPattern = /<a\s+[^>]*>.*?<\/a>/gi;
        var hasLink = tagPattern.test(text);
        if (hasLink) {
            text = ShortenLink(text);
        } else
            text = linter(text);

        return (
            <div dangerouslySetInnerHTML={{ __html: text.replaceAll("\n", "<br />") }} />
        );
        // var div = document.createElement("div");
        // div.innerHTML = text;
        // return div.innerText;
    }

    viewProfile(userId) {
        setEditId(userId);
        window.location.href = "/viewprofile";
    }


    //renderVideo(videoUrl)
    renderAttachment(attachmentUrl) {

        if (attachmentUrl !== "") {
            if (attachmentUrl.endsWith('.jpg') || attachmentUrl.endsWith('.jpeg') || attachmentUrl.endsWith('.gif') || attachmentUrl.endsWith('.png')) {
                return (<LazyLoadImage src={GetAvatar(attachmentUrl)} className='img-fluid mb-2' alt="" />);
            }
            else if (attachmentUrl.endsWith('.mp4') || attachmentUrl.endsWith('.mpeg') || attachmentUrl.endsWith('.mpg') || attachmentUrl.endsWith('.mov') || attachmentUrl.endsWith('.mkv') || attachmentUrl.endsWith('.quicktime')) {
                return <div dangerouslySetInnerHTML={{ __html: '<div class="ratio ratio-16x9 mb-2"><video controls preload="none"><source src="' + GetAvatar(attachmentUrl) + '" type="video/mp4" ></video></div>' }} />
            } else if (attachmentUrl.endsWith('.mp3')) {
                return <div dangerouslySetInnerHTML={{ __html: '<div class="ratio ratio-16x9 mb-2"><audio controls preload="none"><source src="' + GetAvatar(attachmentUrl) + '" type="audio/mp3" ></audio></div>' }} />
            } else if (attachmentUrl.endsWith('.txt') || attachmentUrl.endsWith('.pdf') || attachmentUrl.endsWith('.docx') || attachmentUrl.endsWith('.doc') || attachmentUrl.endsWith('.xls') || attachmentUrl.endsWith('.pptx')) {
                return <div dangerouslySetInnerHTML={{ __html: '<div class="ratio docLink mb-2"><a href="' + GetAvatar(attachmentUrl.toLowerCase()) + '">' + attachmentUrl.slice(attachmentUrl.lastIndexOf("/") + 1) + '</a></div>' }} />
            }
        }
    }


    renderStars(rating) {
        let result = '';
        rating = Math.round(rating);
        for (var i = 0; i < rating; i++) {
            result += '<i class="fa-solid fa-star active-star"></i>';
        }
        for (i = rating; i < 5; i++) {
            result += '<i class="fa-solid fa-star"></i>';
        }
        return result;
    }

    showRating() {
    }

    toggleEdit(id) {
        if (this.props.onEditButton !== null) {
            this.props.onEditButton(id);
        }
    }

    renderFooterLink(hasRated, id, isEditing) {
        const userName = this.props.data.KnowAs !== "" ? (this.props.data.KnownAs + " " + this.props.data.LastName) : this.props.data.RespondUser;
        console.log(this.props.data.RespondUser);
        console.log(getUser().UserName);
        if (hasRated) {
            return "";
        }
        else {
            return (userName === getUser().UserName ?
                <div className='d-flex justify-content-end'>
                    <button className='btn-small-action' onClick={e => this.toggleEdit(id)}>{isEditing ? <>Editing <i className='fas fa-check ms-2'></i></> : <>Edit <i className='fas fa-chevron-right ms-2'></i> </>}</button>
                </div> :
                <div className='text-center mt-3'>
                    <Link to={"/responseRating/" + id} className='view-link'>RATE RESPONSE <i className="fa-solid fa-angles-right"></i></Link>
                </div>
            )
        }
    }

    renderUserDetails = (JobTitle, UserCountry, ProfBodyame) =>{
        if (JobTitle !== null && JobTitle !== "") {
            return (
                <div>
                    <span className='pe-2'>{JobTitle}</span>
                    {UserCountry}
                    <div className='small text-muted'>{ProfBodyame}</div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {UserCountry}
                    <div className='small text-muted'>{ProfBodyame}</div>
                </div>
            )
        }
    }

    render() {
        console.log(this.props.data);
        const style = this.props.cardType;
        const userName = this.props.data.KnownAs !== "" ? (this.props.data.KnownAs + " " + this.props.data.LastName) : this.props.data.RespondUser;
        let avatarImg = this.props.data.Avatar;
        const textMsg = this.formatText(this.props.data.Response);

        return (
            <div className={'responses ' + style}>
                <div className='title-block'>
                    <i className="fa-solid fa-2x fa-caret-right title-arrow"></i>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='avatar-container' onClick={() => this.viewProfile(this.props.data.UserId)}>
                                        <img src={GetAvatar(avatarImg)} className='avatar' alt={userName} />
                                    </div>
                                </td>
                                <td>
                                    <strong>{userName}</strong><br />
                                    {this.renderUserDetails(this.props.data.UserJobTitle, this.props.data.UserCountry, this.props.data.UserProfBodyAbbr)}
                                    {/* <span className='text-muted'>{GetCurrentLevel(this.props.data.Score)}</span> */}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                <div className='my-2'>{textMsg}</div>

                {this.renderAttachment(this.props.data.RespImgUrlRef.toLowerCase())}
                <div className='info'>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    Posted on <span>{this.props.data.DisplayDate}</span><br />
                                </td>
                                <td>
                                    <div dangerouslySetInnerHTML={{ __html: this.renderStars(this.props.data.AvgRate) }} />
                                    Avg. rating
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
                {/* <span className='small text-muted'>{this.props.data.Id}</span> */}
                {this.renderFooterLink(this.props.data.HasRated, this.props.data.Id, this.props.editing)}

            </div>
        )
    }
}


/*
Avatar: "https://m4smkmstor.blob.core.windows.net/userimages/b05e8401-ea11-4ff5-ae11-5c986258cafe.jpeg"
AvgRate: 2.75
CreatedAt: "2017-11-06T14:22:27.715+00:00"
DisplayDate: "Mon Nov 06 2017 at 14:22"
ExecScoreCount: 0
Id: "7a410b8e-22fe-4603-8ff8-f463e079a62e"
InputUser: "Des DE Fortier"
ResolveDate: null
Resolved: false
RespImgUrlRef: ""
RespondUser: "Des DE Fortier"
Response: "All areas to be tested thoroughly"
Theme: ""
Toolkit: "SELECT TOOL"
ToolkitResponseSelect: "SELECT TOOL"
TotalVotes: "0"
UpdatedAt: "2023-01-11T13:51:08.393+00:00"
Urgent: false
UserLevel: "6. Mentoring Master"
UserType: "Executive"
*/
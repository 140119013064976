import React, { Component } from 'react';
import { getAuthToken } from '../../helpers/authentication';
import { APIPath } from '../../helpers/APIHelper';
import { InitiationCard } from '../UI/InitiationCard';


export class InsightList extends Component {

  static parentObj = null;
  static AllPosts = [];
  constructor(props) {
    super(props);

    InsightList.parentObj = this;
    InsightList.AllPosts = [];
    this.state = { editData: [], filteredData: [], loading: true };
  }

  componentDidMount() {
    document.title = "NOW.CULI® :: Insights";
    this.loadData();
  }

  static SearchInsights(keywords) {
    keywords = keywords.trim().toLowerCase();
    let filteredData = InsightList.AllPosts;
    if (keywords.length > 2) {
      filteredData = filteredData.filter(q => q.InitiationText.toLowerCase().indexOf(keywords) !== -1 || q.Keywords.toLowerCase().indexOf(keywords) !== -1);
    } else {
      filteredData = InsightList.parentObj.state.editData;
    }
    InsightList.parentObj.setState({ filteredData });
    return filteredData.length > 0;
  }

  static renderDataTable(data) {
    return (
      data.map((card) =>
        <InitiationCard key={card.Id} data={card} detailView={false} />
      )
    );
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : InsightList.renderDataTable(this.state.filteredData);


    return (
      <>
        <h1 className='fw-normal' style={{ color: "#0e76bc" }}>INSIGHTS</h1>
        <div className='bg-gray'>
          {contents}
        </div>
      </>
    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch(APIPath() + '/nowculi/initiations?Offset=0&Limit=50&Type=INSIGHTS', {
        method: 'GET',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, filteredData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }


    try {
      const response = await fetch(
        APIPath() +
        `/nowculi/initiations/GetAllPosts/INSIGHTS`,
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        InsightList.AllPosts = data;
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }

}

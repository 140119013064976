import React, { Component } from 'react'

export default class LogoBar extends Component {
    render() {
        return (
            <div className="logo-bar">
                <img src='/img/login/now-culi-logo.png' className='img-fluid' alt="Logo" />
            </div>
        )
    }
}

import React, { Component } from 'react';
import ProfileInfo from './profile-info';
import Achievements from './Achievements';
import MenteeMentor from './MenteeMentor';
import { getUser, getEditId, setEditId } from '../../helpers/lookup';
import { getAuthToken } from '../../helpers/authentication';
import { APIPath } from '../../helpers/APIHelper';

export class FullProfile extends Component {

    constructor(props) {
        super(props);

        this.user = getUser();

        this.state = {
            partners: []
        }
    }

    componentDidMount() {
        setEditId(this.user.Id);
        this.loadData();
    }

    async loadData() {
        const userEditId = getEditId();
        const bearer = "bearer " + getAuthToken();
        const response = await fetch(APIPath() + "/global/userprofile?id=" + userEditId, {
            method: 'GET',
            headers: {
                "Authorization": bearer,
                "Content-Type": "application/json"
            }
        });

        if (response.ok) {
            const user = await response.json();
            //console.log(user);

            this.setState({ partners: user.MentorMentees });
        }
    }

    render() {
        const { partners } = this.state;
        return (
            <div className=''>
                <h1 className='text-center py-2 fw-bold'>MY PROFILE</h1>
                <ProfileInfo viewing={false} />
                <Achievements TotalDiamonds={this.user.TotalDiamonds} WizardsReached={this.user.WizardsReached} />
                <MenteeMentor Partners={partners} hidden={partners.length === 0} />
            </div>
        )
    }
}

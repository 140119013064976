import React, { Component } from 'react';
import { HasUpdatedWalkthrough, getAppVersion, getUserEmail, setUserEmail, setUserId } from '../../helpers/lookup';
import { getAuthToken, setAuthToken, setIsLoggedIn } from '../../helpers/authentication';
import LogoBar from '../Nav/LogoBar';
import fadeStep1 from '../../helpers/fade-helper';
import { APIPath } from '../../helpers/APIHelper';
import { fieldRequired } from '../../helpers/validation';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { EncryptPassword } from '../../helpers/global';
import { CheckGroupCount } from '../../helpers/login-helper';
import swal from 'sweetalert';

export class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showWelcome: true,
      hidePassword: false,
      Firstname: "",
      Username: "",
      Password: "",
      Attempted: 0,
      Complete: 0,
      deviceId: "",
      AppVersion: getAppVersion()
    };

    this.cellSpacing = [5, 5];
    this.loginUser = this.loginUser.bind(this);
  }

  componentDidMount() {
    document.title = "NOW.CULI™";
    fadeStep1(true);
    ThrobbleHelper.toggleThrobble(true, "Working...");
    this.loadData();
  }

  render() {
    return (
      <div className='h-100'>
        <div className="welcome-block pt-2 ps-3">
          <p className='fw-normal'>What can you share today {this.state.Firstname} ?</p>
        </div>
        <form className="">
          <div className="mb-3">
            <input type="email" defaultValue={this.state.Username} onChange={e => this.setState({ Username: e.target.value })} className="form-control" placeholder='Username/Email' />
            <span id='errUsername' className='ErrorText'></span>
          </div>
          <div className="mb-3" hidden={this.state.hidePassword}>
            <input type="password" value={this.state.Password} onChange={e => this.setState({ Password: e.target.value })} className="form-control" placeholder='Password' />
            <span id='errPassword' className='ErrorText'></span>
          </div>
          <div className="text-end" hidden={this.state.hidePassword}>
            <button type='button' className='btn-login' onClick={this.loginUser}>Enter<i className="fas fa-chevron-right ms-2"></i></button>
          </div>
        </form>

        <div className="mt-3">
          <div className="animation-box">
            <div id="first-line">What do I <b>Need</b>?</div>
            <div id="second-line">What do I <b>Know</b>?</div>
            <div id="third-line">What can I <b>Share</b>?</div>
          </div>
        </div>

        <div className="mt-5 text-center text-white pb-4 pe-3">
          Version: {this.state.AppVersion}
        </div>
        <ThrobbleHelper />
      </div>
    );
  }

  async loadData() {

    let bearer = "bearer " + getAuthToken();
    try {
      const response = await fetch(APIPath() + "/global/login", {
        method: "GET",
        headers: {
          "Authorization": bearer,
          "Content-Type": "application/json"
        }
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        this.setState({ Username: getUserEmail(), hidePassword: true });
        ThrobbleHelper.toggleThrobble(true, "Logging in...");

        // if (process.env.NODE_ENV === "development")
        //   window.location.href = "/plain/walkthrough";

        if (data) {
          CheckGroupCount().then(() => this.setState({ hidePassword: false }))
        } else {
          if (data.WalhThroughStatus === 100) {
            /*setUserGroup(data.OrganisationName);*/
            setIsLoggedIn(true);
            window.location.href = "/";

          }
          else
            window.location.href = "/plain/walkthrough";


        }
      } else {
        this.setState({ hidePassword: false });
      }
      ThrobbleHelper.toggleThrobble(false);
    } catch (e) {
      ThrobbleHelper.toggleThrobble(false);
      this.setState({ hidePassword: false });
      console.error("Load by auth token error", e);
    }
  }

  async loginUser(evt) {
    let isValid = true;
    evt.stopPropagation();

    isValid &= fieldRequired(this.state.Username, "errUsername", "* Required");
    isValid &= fieldRequired(this.state.Password, "errPassword", "* Required");

    if (isValid) {
      try {
        let bearer = "bearer " + getAuthToken();
        ThrobbleHelper.toggleThrobble(true, "Logging in...");

        let data = {
          Email: this.state.Username,
          // Password: EncryptPassword(this.state.Password),
          Password: this.state.Password,
          AppVersion: this.state.AppVersion
        }

        const response = await fetch(APIPath() + "/global/login", {
          method: "PUT",
          headers: {
            "Authorization": bearer,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        });

        if (response.ok) {
          let result = await response.json();
          setUserId(result.Item1);
          setUserEmail(result.Item2)
          setAuthToken(result.Item3);

          if (process.env.NODE_ENV === "development")
            window.location.href = "/plain/walkthrough";

          if (result.Item4)
            CheckGroupCount();
          else
            window.location.href = "/plain/walkthrough";
        } else {
          ThrobbleHelper.toggleThrobble(false);
          this.setState({ Password: "" });
          swal("Login", "Username or Password is incorrect! Please contact your Admin if you don't remember your login details", "error");
        }
      } catch (e) {
        console.error(e);
        ThrobbleHelper.toggleThrobble(false);
        this.setState({ Password: "" });
        swal("Login", "Username or Password is incorrect! Please contact your Admin if you don't remember your login details", "error");
      }
    }
  }
}
import React, { Component } from 'react';

import { getAuthToken } from '../../helpers/authentication';
//import { CheckGroupCount } from '../../helpers/login-helper';
import { loader } from '../../helpers/loader';
import { APIPath } from '../../helpers/APIHelper';
import { getDeviceId, getUserId, getStepsWithPoints, getWalkthroughDetails, setCurrentWalkthroughStep, setStepsWithPoints, setWalkthroughDetails, setWalkthroughStatus } from '../../helpers/lookup';
import { RenderSwirl } from '../../helpers/SwirlHelper';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import swal from 'sweetalert';
import { UpdateWalkthroughPoints } from '../../helpers/global';

//Screens
import ScreenWelcome from './ScreenWelcome';
import ScreenPhoto from './ScreenPhoto';
import ScreenYearsExperience from './ScreenYearsExperience';
import ScreenProfessionalBody from './ScreenProfessionalBody';
import ScreenJobDescription from './ScreenJobDescription';
import ScreenInstitutions from './ScreenInstitutions';
import ScreenPlaceOfWork from './ScreenPlaceOfWork';
import ScreenJobProfile from './ScreenJobProfile';
import ScreenProfessionalProfile from './ScreenProfessionalProfile';
import ScreenExperientialProfile from './ScreenExperientialProfile';
import ScreenPersonalProfile from './ScreenPersonalProfile';
import ScreenTrainingScreens from './ScreenTrainingScreens';
import ScreenThemes from './ScreenThemes';
import ScreenPosts from './ScreenPosts';
import ScreenMenu from './ScreenMenu';


export default class Walkthrough extends Component {

    constructor(props) {
        super(props);
        this.state = {
            CurrentStep: "1",
            loading: true
        };
        this.changeStep = this.changeStep.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async changeStep(Type, SavedStep) {

        let step = parseInt(this.state.CurrentStep);
        if (Type.toLowerCase() === "prev")
            step--;
        else
            step++;

        // if ((step === 3 && getWalkthroughDetails().Avatar !== "") || step === 12 || step === 14 || step === 15)
        //     await this.SaveWalkthroughPoint(step);
        if ((step === 3 && getWalkthroughDetails().Avatar !== "") || step === 11 || step === 13 || step === 14)
            await this.SaveWalkthroughPoint(step);

//        if (step > 15) {
            if (step > 14) {
            
            await this.SaveWalkthroughPoint(step).then(() => {
                this.setWalkthroughComplete();    
            });
        }
        else {
            ThrobbleHelper.toggleThrobble(false);
            this.setState({ CurrentStep: step.toString() });
            setCurrentWalkthroughStep(step.toString());
            this.renderWalkthrough(step.toString());
        }
    }

    renderWalkthrough(step) {
        const header = <>NOW.CULI&reg;</>;
        switch (step) {
            case "2":
                return (<ScreenPhoto Header={header} onStepChange={this.changeStep} />);
            case "3":
                return (<ScreenYearsExperience Header={header} onStepChange={this.changeStep} />);
            case "4":
                return (<ScreenProfessionalBody Header={header} onStepChange={this.changeStep} />);
            case "5":
                return (<ScreenJobDescription Header={header} onStepChange={this.changeStep} />);
            // case "6":
            //     return (<ScreenInstitutions Header={header} onStepChange={this.changeStep} />);
            // case "7":
            //     return (<ScreenPlaceOfWork Header={header} onStepChange={this.changeStep} />);
            // case "8":
            //     return (<ScreenJobProfile Header={header} onStepChange={this.changeStep} />);
            // case "9":
            //     return (<ScreenProfessionalProfile Header={header} onStepChange={this.changeStep} />);
            // case "10":
            //     return (<ScreenExperientialProfile Header={header} onStepChange={this.changeStep} />);
            // case "11":
            //     return (<ScreenPersonalProfile Header={header} onStepChange={this.changeStep} />);
            // case "12":
            //     return (<ScreenTrainingScreens Header={header} onStepChange={this.changeStep} />);
            // case "13":
            //     return (<ScreenThemes Header={header} onStepChange={this.changeStep} />);
            // case "14":
            //     return (<ScreenPosts Header={header} onStepChange={this.changeStep} />);
            // case "15":
            //     return (<ScreenMenu Header={header} onStepChange={this.changeStep} />);



            case "6":
                return (<ScreenPlaceOfWork Header={header} onStepChange={this.changeStep} />);
            case "7":
                return (<ScreenJobProfile Header={header} onStepChange={this.changeStep} />);
            case "8":
                return (<ScreenProfessionalProfile Header={header} onStepChange={this.changeStep} />);
            case "9":
                return (<ScreenExperientialProfile Header={header} onStepChange={this.changeStep} />);
            case "10":
                return (<ScreenPersonalProfile Header={header} onStepChange={this.changeStep} />);
            case "11":
                return (<ScreenTrainingScreens Header={header} onStepChange={this.changeStep} />);
            case "12":
                return (<ScreenThemes Header={header} onStepChange={this.changeStep} />);
            case "13":
                return (<ScreenPosts Header={header} onStepChange={this.changeStep} />);
            case "14":
                return (<ScreenMenu Header={header} onStepChange={this.changeStep} />);

            default: //default to screen 1 - weclome screen
                return (<ScreenWelcome Header={header} onStepChange={this.changeStep} />);

        }
    }

    render() {
        const content = this.state.loading ? loader() : this.renderWalkthrough(this.state.CurrentStep);
        return (
            <div className='walkthrough-container'>
                {content}
                <ThrobbleHelper />
            </div>);
    }

    async loadData() {
        const bearer = "Bearer " + getAuthToken();
        const UserID = getUserId();
        const DeviceId = getDeviceId();
        try {
            const response = await fetch(APIPath() + "/global/walkthrough/" + UserID + "/" + DeviceId, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                const data = await response.json();
                if (data.UserId) {
                    setWalkthroughDetails(data);
                    //console.table(data);
                    //console.log(data);
                }

                this.setState({ loading: false });
                //if (data.WalkThroughStatus == 100)

            }
        } catch (e) {
            console.error("Walkthrough ::", e);
        }
    }

    async setWalkthroughComplete() {
        console.log("Walkthrough Complete");
        const bearer = "Bearer " + getAuthToken();
        try {
            const response = await fetch(APIPath() + "/global/walkthrough/walkthroughcomplete", {
                method: "PUT",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                document.location.href = "/";
                //CheckGroupCount();
            }
        } catch (e) {
            console.error("Walkthrough ::", e);
        }
    }

    async SaveWalkthroughPoint(step) {
        // console.log(step)
        // console.log(getStepsWithPoints())
        if (!getStepsWithPoints().includes(step)) {

            try {
                const bearer = "Bearer " + getAuthToken();
                const UserID = getUserId();

                const response = await fetch(APIPath() + "/global/walkthrough/AddPoints/" + UserID, {
                    method: "PUT",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json"
                    }
                });

                if (response.ok) {
                    UpdateWalkthroughPoints(step);
                    ThrobbleHelper.toggleThrobble(false);
                    setWalkthroughStatus();
                    await RenderSwirl("WALKTHROUGH", "Well done - that earned you 50 points", "success", () => {
                    });
                } else {
                    console.log(response.status, "::", response.statusText);
                    ThrobbleHelper.toggleThrobble(false);
                    swal("WALKTHROUGH", "There was an error updating your points. Please try again!");
                }
            } catch (e) {
                console.error("Walkthrough Saving Points ::", e);
                ThrobbleHelper.toggleThrobble(false);
                swal("WALKTHROUGH", "There was an error updating your points. Please try again!");
            }
        }
    }


}

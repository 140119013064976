import React, { Component } from 'react';
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';

export class Collaboration extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {


        return (
            <>
                <div className="collab-container">
                    <h1>Collaborating &amp; Scoring</h1>
                    <UncontrolledAccordion stayOpen defaultOpen={[]}>
                        <AccordionItem className='mb-1'>
                            <AccordionHeader targetId='job-profile' className='fw-bold question'>
                                Posing a Question
                            </AccordionHeader>
                            <AccordionBody accordionId='job-profile'>
                                <p>This option is Knowledge Theme based and is only posed to a selected Distribution List.</p>
                                <p>It is either a request for focused knowledge and insight, or a guided “thought provoker” for gathering intelligent responses and knowledge harvesting.</p>
                                <p>The collaborating group can only view the Questions Posed which have been posed to their selected Distribution Group (Whole group, region, team, partnership only) and can then either Rate them or Respond to them. Valued Responses can then also be Rated by the distribution group.</p>
                                <p>Questions and associated responses are also reflected in the analytic dashboards of the Knowledge Forge™ which provides a rich environment for further collaborative development and knowledge enhancement.</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className='mb-1'>
                            <AccordionHeader targetId='professional-profile' className='fw-bold contribution'>
                                Posting a Contribution
                            </AccordionHeader>
                            <AccordionBody accordionId='professional-profile'>
                                <p>This option is Knowledge Theme based and is posted to the whole collaboration group.</p>
                                <p>
                                    It is designed to develop a valuable dynamic Knowledge Asset and is therefore posted in a structured format using a proven Knowledge Asset development methodology:<br />
                                    •	Critical Thinking,<br />
                                    •	Best Practice,<br />
                                    •	Lessons Learned,<br />
                                    •	Knowledge Networks<br />
                                </p>
                                <p>This model creates rich associative learning and new neural connections within the collaborating group.</p>
                                <p>Contributions can be rated and / or responded to by all collaborators for value and quality of contribution.</p>
                                <p>Contributions and associated responses are also reflected in the analytic dashboards of the Knowledge Forge™ which provides a rich environment for further collaborative development and knowledge enhancement.</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className='mb-1 insight'>
                            <AccordionHeader targetId='experiential-profile' className='fw-bold insight'>
                                Sharing an Insight
                            </AccordionHeader>
                            <AccordionBody accordionId='experiential-profile' className=''>
                                <p>This option is a Blog-like, non-Theme Based knowledge sharing method to offer knowledge nuggets which are not rule or tool based, and are simply random and ad hoc pieces of contextualised information, which will add value to the general knowledge harvesting and sharing process.</p>
                                <p>Insights can only be rated for value and quality of contribution and there is no response option.</p>
                                <p>Insights are also reflected in the analytic dashboards of the Knowledge Forge™ which provides a rich environment for further collaborative development and knowledge enhancement.</p>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem className='mb-1'>
                            <AccordionHeader targetId='personal-profile' className='fw-bold score'>
                                How scoring works
                            </AccordionHeader>
                            <AccordionBody accordionId='personal-profile'>
                                <p>
                                    Collaborations are scored through a simple formula which takes into account the “value” or Rating given (1 to 5 by Mentors or Mentees – or 10 by an Executive) multiplied by the Weighting given to the Responder as follows:<br />
                                    •	All Mentees carry a weighting of 10<br />
                                    •	All Mentors carry a weighting of 20<br />
                                    •	All Executives carry a weighting of 30<br />
                                </p>
                                <p>Each Knowledge Status Level has a threshold score, shown next to the description, which once reached by an individual, automatically promotes them to the next Knowledge Status Level. This level is carried through to indicate the Responders Knowledge Status at all times.</p>
                                <p>
                                    There are 3 Collaboration options:

                                </p>
                                <div className='d-flex w-100'>

                                    <div className="text-center">
                                        <img src="img/global-icons/icon-question.png" alt='' /><br />
                                        Posing a Question
                                    </div>
                                    <div className="text-center">
                                        <img src="img/global-icons/icon-contributions.png" alt='' /><br />
                                        Posting a Contribution
                                    </div>
                                    <div className="text-center">
                                        <img src="img/global-icons/icon-insights.png" alt='' /><br />
                                        Sharing an Insight
                                    </div>

                                </div>
                                <br />
                                <p>
                                    There is no score attached to the “Initiating” part of any Collaboration. Scores are only accumulated once other collaborators start “Responding” to each collaboration by Rating and / or Responding to them. This provides the opportunity for the collaborating group to rate both Initiations and Responses as the value of the contextual knowledge is shared and developed.<br /><br />
                                    <b>Example:</b><br />
                                    If a rating of 3 via the star system is given by a Mentor (weighting = 20) gives, the Score is 3 x 20 = 60<br />
                                </p>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                </div>

            </>
        )
    }
}

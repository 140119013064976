import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { APIPath } from "./APIHelper";
import { getAuthToken } from "./authentication";
import { getFirebaseToken, setFirebaseToken } from "./lookup";

export const FirebaseApp = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyCfyvBknZNPp1fvvaErBqrGldBhV0rOkXM",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "nowculi-6de87.firebaseapp.com",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "nowculi-6de87",
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "nowculi-6de87.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "21412647382",
    appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:21412647382:web:3861ce09624f0aa518d477",
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "G-DQTQL63LPJ"
});

const Messaging = (async () => {
    try {
        const isBrowserSupported = await isSupported();
        if (isBrowserSupported)
            return getMessaging(FirebaseApp);
        console.log("Firebase not supported this browser");
        return null;
    } catch (e) {
        console.log(e);
        return null;
    }
})();

export const GetFirebaseToken = async () => {
    const messageRes = await Messaging;
    if (messageRes) {
        let FirebaseToken = getFirebaseToken();
        if (!FirebaseToken || FirebaseToken === "" || FirebaseToken === "undefined")
            await getToken(messageRes, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY }).then((CurrentToken) => {
                SaveToken(CurrentToken).then((result) => { setFirebaseToken(result) });
            });
    }
}

const SaveToken = async (NewToken) => {
    try {
        let bearer = "bearer " + getAuthToken();
        const response = await fetch(APIPath() + "/global/login/SaveToken/" + NewToken, {
            method: "POST",
            headers: {
                Authorization: bearer,
                "Content-Type": "application/json",
            },
        });

        if (response.ok) {
            let result = await response.json();
            return result;
        } else {
            console.log(response)
        }
        // else
        // SaveToken(NewToken);
    } catch (e) {
        console.error(e);
        // SaveToken(NewToken);
    }
}

export const OnMessageListener = () =>
    new Promise((resolve) => {
        (async () => {
            const messageRes = await Messaging;
            if (messageRes) {
                onMessage(messageRes, (payload) => {
                    resolve(payload);
                });
            }
        })()
    });

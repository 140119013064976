import React, { Component } from 'react';

import MENTOR from '../../assets/tools/m4smain_0001_6-roles.png';
import LEARNING from '../../assets/tools/m4smain_0002_6-sources.png';
import CAPABILITY from '../../assets/tools/m4smain_0003_ex-cap-model.png';
import STORY from '../../assets/tools/m4smain_0004_int-storytelling.png';
import MAPPING from '../../assets/tools/m4smain_0005_knowledge-mapping.png';
import ANALYSIS from '../../assets/tools/m4smain_0007_retro.png';
import SCARF from '../../assets/tools/m4smain_0008_scarf.png';
import KASM from '../../assets/tools/m4smain_0006_kasm.png';
import { Link } from 'react-router-dom';
import { MyContext } from '../../Provider';

export class CollaborationTools extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const setData = (toolDescription) => {
            return {
                compRef: "2c17e716-a246-4687-9b21-a423f2b4fd75",
                createdAt: "2020-03-27T08:02:13.4428775+00:00",
                deleted: 0,
                id: 1,
                toolDescription,
                toolHref: "#m4s-6SOLd",
                toolId: "6SOL",
                updatedAt: "2020-03-27T08:02:13.4428775+00:00",
                version: "AAAAAAAD8g4="
            }
        }

        return (
            <>
                <div className="plr-10 ">
                    <h1>Collaboration Toolkit&trade; Techniques</h1>
                    <p>The Collaboration Toolkit&trade; provides a combination of well-proven collaboration and knowledge sharing tools and techniques to enhance human-to-human learning through more effective communication, cooperation and collaboration between partnerships
                        and small groups, as well as large widely dispersed learning organisations and communities.
                    </p>
                </div>

                <Link to="/collaborationdetails" data-href='#m4s-6rotmd' className="container-nav d-flex mb-1" onClick={() => console.log(this.context?.setCollaboration(setData("6 Roles of a mentor")))}>
                    <div>
                        <h3 className='teal'>6 Roles of a mentor</h3>
                        <p>A simple model to try different mentoring conversation formats &amp; types</p>
                    </div>
                    <div className='d-flex flex-column justify-content-between w-25'>
                        <a className="nav-link" href="#m4s-6rotmd" data-transition="flip">
                            <img src={MENTOR} className="img-technique" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange me-1"></i> More
                        </div>
                    </div>
                    <div className="cb"></div>
                </Link>

                <Link to="/collaborationdetails" data-href='#m4s-6SOLd' className="container-nav d-flex mb-1" onClick={() => console.log(this.context?.setCollaboration(setData("6 Sources of Learning")))}>
                    <div>
                        <h3 className='teal'>6 Sources of learning</h3>
                        <p>A simple model to ensure varied, integrated, and multi-source learning opportunities</p>
                    </div>
                    <div className='d-flex flex-column justify-content-between  w-25'>
                        <a className="nav-link" href="#m4s-6SOLd" data-transition="flip">
                            <img src={LEARNING} className="img-technique" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange me-1"></i> More
                        </div>
                    </div>
                    <div className="cb"></div>
                </Link>

                <Link to="/collaborationdetails" data-href='#m4s-6rotmd' className="container-nav d-flex mb-1" onClick={() => console.log(this.context?.setCollaboration(setData("Experiential capability modeling")))}>
                    <div>
                        <h3 className='teal'>Experiential capability modeling</h3>
                        <p>A simple model to integrate enhanced competency, capacity and capability development</p>
                    </div>
                    <div className='d-flex flex-column justify-content-between  w-25'>
                        <a className="nav-link" href="#m4s-ecmd" data-transition="flip">
                            <img src={CAPABILITY} className="img-technique" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange me-1"></i> More
                        </div>
                    </div>
                    <div className="cb"></div>
                </Link>

                <Link to="/collaborationdetails" data-href='#m4s-6rotmd' className="container-nav d-flex mb-1" onClick={() => console.log(this.context?.setCollaboration(setData("Intentional story telling")))}>
                    <div>
                        <h3 className='teal'>Intentional story telling</h3>
                        <p>A simple model to share situational relevance and deep contextual understanding of important events</p>
                    </div>
                    <div className='d-flex flex-column justify-content-between  w-25'>
                        <a className="nav-link" href="#m4s-isd" data-transition="flip">
                            <img src={STORY} className="img-technique" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange me-1"></i> More
                        </div>
                    </div>
                    <div className="cb"></div>
                </Link>

                <Link to="/collaborationdetails" data-href='#m4s-6rotmd' className="container-nav d-flex mb-1" onClick={() => console.log(this.context?.setCollaboration(setData("Knowledge Mapping")))}>
                    <div>
                        <h3 className='teal'>Knowledge mapping</h3>
                        <p>A simple model to develop a more in-depth and cross-functional knowledge of an organisation</p>
                    </div>
                    <div className='d-flex flex-column justify-content-between  w-25'>
                        <a className="nav-link" href="#m4s-cfkmd" data-transition="flip">
                            <img src={MAPPING} className="img-technique" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange me-1"></i> More
                        </div>
                    </div>
                    <div className="cb"></div>
                </Link>

                <Link to="/collaborationdetails" className="container-nav d-flex mb-1" onClick={() => console.log(this.context?.setCollaboration(setData("Retrospective Analysis")))}>
                    <div>
                        <h3 className='teal'>Retrospective analysis</h3>
                        <p>A simple model to effectively analyse the experiences and lessons learned from important events</p>
                    </div>
                    <div className='d-flex flex-column justify-content-between  w-25'>
                        <a className="nav-link" href="#m4s-rad">
                            <img src={ANALYSIS} className="img-technique" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange me-1"></i> More
                        </div>
                    </div>
                    <div className="cb"></div>
                </Link>

                <Link to="/collaborationdetails" className="container-nav d-flex mb-1" onClick={() => console.log(this.context?.setCollaboration(setData("SCARF Model")))}>
                    {/* <table class="w-100">
                        <tbody>
                            <tr>
                                <td class="w-75"></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table> */}
                    <div>
                        <h3 className='teal'>SCARF model</h3>
                        <p>A brain-based model to develop emotional maturity and thereby enhance communication and collaboration </p>
                    </div>
                    <div className='d-flex flex-column justify-content-between  w-25'>
                        <a className="nav-link" href="#m4s-smd">
                            <img src={SCARF} className="img-technique" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange me-1"></i> More
                        </div>
                    </div>
                    <div className="cb"></div>
                </Link>

                <Link to="/collaborationdetails" className="container-nav d-flex mb-1" onClick={() => console.log(this.context?.setCollaboration(setData("Knowledge asset structuring model")))}>
                    {/* <table class="w-100 border-1">
                        <tbody>
                            <tr>
                                <td>
                                    
                                </td>
                                <td>
                                    <br />
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                    <div>
                        <h3 className='teal'>Knowledge asset structuring model</h3>
                        <p>A simple model to develop dynamic and scalable knowledge reference assets</p>
                  </div>
                    <div className='d-flex flex-column justify-content-between  w-25'>
                        <a className="nav-link" href="#m4s-kam">
                            <img src={KASM} className="img-technique" alt='' />
                        </a>
                        <div className='d-flex text-end'>
                            <i className="fa fa-chevron-right orange me-1"></i> More
                        </div>
                    </div>
                    <div className="cb"></div>
                </Link>
            </>
        )
    }
}

CollaborationTools.contextType = MyContext;

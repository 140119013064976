import React, { Component } from "react";
import { PostOptions } from "../Attachments/PostOptions";

export class PoseCollaboration extends Component {
  render() {
    return (
      <div id="addQuestionContent">
        <PostOptions componentType="question" h1Color="#f1592a"/>
      </div>
    );
  }
}

export default PoseCollaboration;

import { Toast } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import { GetFirebaseToken, OnMessageListener } from './FirebaseHelper';
import { isSupported } from "firebase/messaging";
import Logo from '../assets/MultiApi/NOW_CULI.png';
// import { Toast } from 'reactstrap';

const FirebaseNotifications = () => {
    const [isPermitted, setPermitted] = useState(false);
    const [showNotif, setShowNotif] = useState(false);
    const [notification, setNotification] = useState({ title: "", body: "" });
    const isNotificationsSupported = () => Notification in window && ServiceWorker in navigator && PushManager in window;

    useEffect(() => {
        try {
            if ("Notification" in window) {
                if (Notification.permission === "granted")
                    setPermitted(true);
                else if (Notification.permission === "default") {
                    Notification.requestPermission((result) => {
                        if (result === "granted")
                            setPermitted(true);
                    })
                }
            }

            if (isPermitted ) {
                GetFirebaseToken();
                OnMessageListener().then(payload => {
                    setShowNotif(true);
                    setNotification({ title: payload.data.Title, body: payload.data.Body });
                });
            }
        } catch (err) {
            console.error("Notifications Error:", err)
        }
    }, [isPermitted])

    return (
        <div style={{ position: "sticky", top: 0, width: "100%", zIndex: 10000000 }}>
            <Toast show={showNotif} onClose={() => setShowNotif(false)} delay={5000} autohide style={{ position: "absolute", top: "10px", left: 0, right: 0, width: "100%", zIndex: 10000000 }}>
                <Toast.Header className='w-100' closeButton={<button className='btn-close float-end' onClick={() => console.log(setShowNotif(false))}></button>}>
                    <div className="w-100">
                        <img src="https://nowculi-pwa.azurewebsites.net/img/login/now-culi-logo.png" className='rounded me-2' style={{ maxWidth: "40px" }} />
                        {notification.title}</div>
                </Toast.Header>
                <Toast.Body>
                    {notification.body}
                </Toast.Body>
            </Toast>
        </div>
    )
}

export default FirebaseNotifications;
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from 'react-router-dom';
import { GetAvatar, GetCurrentLevel, ShortenLink, linter } from '../../helpers/global';
import { getUserId, setEditId } from '../../helpers/lookup';
import EditPost from './EditPost';
import diamond from "../../assets/questions/diamond.png";

export class InitiationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    formatText(text) {
        var idx = text.indexOf('(@attachment)');
        if (idx >= 0) {
            text = text.substring(0, idx - 1);
        }
        var tagPattern = /<a\s+[^>]*>.*?<\/a>/gi;
        var hasLink = tagPattern.test(text);
        if (hasLink) {
            text = ShortenLink(text);
        } else
            text = linter(text);

        return (
            <div dangerouslySetInnerHTML={{ __html: text }} />
        );
    }


    renderAttachment(attachmentUrl) {
        if (attachmentUrl !== null && attachmentUrl !== "none") {
            if (attachmentUrl.endsWith('.jpg') || attachmentUrl.endsWith('.jpeg') || attachmentUrl.endsWith('.gif') || attachmentUrl.endsWith('.png') || attachmentUrl.endsWith('.webp') || attachmentUrl.endsWith('.jfif')) {
                return (<LazyLoadImage src={GetAvatar(attachmentUrl)} className='img-fluid mb-2' alt="" />);
            }
            else if (attachmentUrl.endsWith('.mp4') || attachmentUrl.endsWith('.mpeg') || attachmentUrl.endsWith('.mpg') || attachmentUrl.endsWith('.mov') || attachmentUrl.endsWith('.mkv') || attachmentUrl.endsWith('.quicktime')) {

                return <div dangerouslySetInnerHTML={{ __html: '<div class="ratio ratio-16x9 mb-2"><video controls preload="none"><source src="' + GetAvatar(attachmentUrl) + '" type="video/mp4" ></video></div>' }} />
            } else if (attachmentUrl.endsWith('.mp3')) {
                return <div dangerouslySetInnerHTML={{ __html: '<div class="ratio voiceNote mb-2"><audio controls preload="none"><source src="' + GetAvatar(attachmentUrl) + '" type="audio/mp3" ></audio></div>' }} />
            } else if (attachmentUrl.endsWith('.txt') || attachmentUrl.endsWith('.pdf') || attachmentUrl.endsWith('.docx') || attachmentUrl.endsWith('.doc') || attachmentUrl.endsWith('.xls') || attachmentUrl.endsWith('.pptx')) {
                return <div dangerouslySetInnerHTML={{ __html: '<div class="ratio docLink mb-2"><a target="_blank" href="' + GetAvatar(attachmentUrl.toLowerCase()) + '">' + attachmentUrl.slice(attachmentUrl.lastIndexOf("/") + 1) + '</a></div>' }} />
            }
        }
        else
            return "";

    }

    renderPostDetail(isDetailView, theme, distribution) {
        if (isDetailView)
            return (<>Theme: <span>{theme}</span><br /><br />Distribution: <span>{distribution}</span><br /></>);
        else
            return "";
    }

    renderFooterLink(collaborationType, collaborationId, detailView) {
        if (detailView) {
            return (this.props.data.UserId === getUserId() ?
                <div className="d-flex justify-content-end container-edit mt-3">
                    <button className='btn-small-action' onClick={() => EditPost.toggleEditPost()}>EDIT YOUR POST <i className='fas fa-chevron-right ms-2'></i></button>
                </div>
                :
                !this.props.data.HasUserRated &&
                <div id="question-ratebutton" className="text-center mt-3">
                    <Link to={"/rating/" + collaborationId} className='view-link'>RATE THIS POST <i className="fa-solid fa-angles-right"></i></Link>
                </div>);
        }
        else {
            return (
                <div className='text-center mt-3'>
                    <a href={"/collaborationdetail/" + collaborationId} className='view-link'>VIEW CONVERSATION <i className="fa-solid fa-angles-right"></i></a>
                </div>
            )

        }
    }

    renderStars(rating) {
        let result = '';
        rating = Math.round(rating);
        for (var i = 0; i < rating; i++) {
            result += '<i class="fa-solid fa-star active-star"></i>';
        }
        for (i = rating; i < 5; i++) {
            result += '<i class="fa-solid fa-star"></i>';
        }
        return result;

    }

    renderDiamonds(rating) {
        return (
            <div>
                <img src={diamond} /> {rating > 1 ? <span className='ms-1 fw-semibold '>{rating}</span> : <></>}
            </div>
        )
    }

    viewProfile(UserId) {
        setEditId(UserId);
        window.location.href = "/viewprofile";
    }


    RenderVotes(TotalVotes) {
        switch (TotalVotes) {
            case 0:
                return;
            case 1:
                return (<>from {this.props.data.TotalVotes} vote</>);
            default:
                return (<>from {this.props.data.TotalVotes} votes</>);
        }
    }

    renderUserDetails = (JobTitle, UserCountry, ProfBodyame) =>{
        if (JobTitle !== null && JobTitle !== "") {
            return (
                <div>
                    <span className='pe-2'>{JobTitle}</span>
                    {UserCountry}
                    <div className='small text-muted'>{ProfBodyame}</div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {UserCountry}
                    <div className='small text-muted'>{ProfBodyame}</div>
                </div>
            )
        }
    }

    render() {
        const style = "question";
        const userName = this.props.data.UserName;
        let avatarImg = "../img/noAvatar1.png";

        if (this.props.data.UseAvatar) {
            avatarImg = GetAvatar(this.props.data.Avatar);
        };
        //console.log(this.props.data);

        const post = this.formatText(this.props.data.InitiationText);
        return (
            <>
                <div className={'initiations ' + style}>
                    <div className='title-block' onClick={() => this.viewProfile(this.props.data.UserId)}>
                        <i className="fa-solid fa-2x fa-caret-right title-arrow"></i>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='avatar-container'>
                                            <img src={avatarImg} className='avatar' alt={userName} />
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <strong>{userName}</strong><br />
                                        {this.renderUserDetails(this.props.data.UserJobTitle, this.props.data.UserCountry, this.props.data.UserProfBodyAbbr)}
                                        {/* <span className='text-muted'>{GetCurrentLevel(this.props.data.Score)}</span> */}
                                    </td>
                                    <td className={'align-middle text-end pt-2' + (!this.props.data.Urgent && ' d-none')} >
                                        <div className='badge text-bg-danger w-100 mt-1' hidden={!this.props.data.Urgent}>URGENT</div>
                                        <br />
                                        <div className='badge text-bg-success w-100' hidden={!this.props.data.Resolved}>RESOLVED</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='my-2 whiteSpace'>{post}</div>
                    {this.renderAttachment(this.props.data.inpImgUrlRef)}
                    <div className='info'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        Posted on <span>{this.props.data.DisplayDate}</span><br /><br />
                                        {style !== "insight" && style !== "insights" && <div>Number Responses: <span>{this.props.data.NumResponses}</span><br /><br /></div>}
                                        {this.renderPostDetail(this.props.detailView, this.props.data.Theme, this.props.data.ShareWith)}
                                    </td>
                                    <td>
                                        {this.props.data.ExecRateCount > 0 && this.renderDiamonds(this.props.data.ExecRateCount)}
                                        <div dangerouslySetInnerHTML={{ __html: this.renderStars(this.props.data.AvgRate) }} />
                                        Avg. rating {this.RenderVotes(this.props.data.TotalVotes)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.renderFooterLink(style, this.props.data.Id, this.props.detailView)}
                </div>
                {this.props.detailView && <EditPost {...this.props.data} />}

            </>
        )
    }
}
import React, { Component } from 'react';

import { getAuthToken } from '../../helpers/authentication';
import { APIPath } from '../../helpers/APIHelper';
import { InitiationCard } from '../UI/InitiationCard';
import { ResponseCard } from '../UI/ResponseCard';
import { Link } from 'react-router-dom';
import Attachments from '../Attachments/Attachments';
import { fieldRequired } from '../../helpers/validation';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import swal from 'sweetalert';
import { delint, linter } from '../../helpers/global';
import { Dropdown, DropdownItem, DropdownToggle } from 'reactstrap';

export class QuestionDetail extends Component {

  static parentObj = null;
  static textRef = null;
  constructor(props) {
    super(props);
    QuestionDetail.parentObj = this;
    this.textRef = React.createRef();
    this.sortBlockRef = React.createRef();

    this.state = { initiation: [], responses: [], loading: true, showModal: false, isResponse: true, themes: [], theme: "", showEditResponse: false, editResponseId: "", editResponseText: "", charCount: 0, responseSorting: "date-down" };
    this.returnState = this.returnState.bind(this);
    this.updateResponse = this.updateResponse.bind(this);
  }

  componentDidMount() {
    document.title = "NOW.CULI® :: Questions";
    this.loadData();
  }

  returnState() {
    return this.state;
  }

  static toggleEditResponse(id) {

    let { showEditResponse, responses } = QuestionDetail.parentObj.state;
    let response = responses.find(r => r.Id === id)
    QuestionDetail.parentObj.setState({ showEditResponse: !showEditResponse, editResponseId: id, editResponseText: delint(response.Response), charCount: response.Response.length }, () => QuestionDetail.parentObj.textRef.current.focus());
  }

  static renderDataTable(data, responseType, responseSorting) {

    // data = data.sort((a, b) => {
    //   switch (responseSorting) {
    //     case "date-up":
    //       return new Date(a.CreatedAt) - new Date(b.CreatedAt);
    //     default:
    //       return new Date(b.CreatedAt) - new Date(a.CreatedAt);;
    //   }
    // })

    return (
      data.map((card) =>
        <ResponseCard key={card.Id} data={card} cardType={responseType} onEditButton={QuestionDetail.toggleEditResponse} editing={QuestionDetail.parentObj.state.showEditResponse} />
      )
    );
  }

  renderResponses() {
    if (this.state.responses.length > 0) {
      return (
        <>
          <h2 className='light p-3' style={{ backgroundColor: "#eee" }}>Responses</h2>
          <div className='' ref={this.sortBlockRef} tabIndex={0}></div>
          {/* <label className='fw-bold text-uppercase'>Sort By:</label>
            <select className='form-control form-control-sm' value={this.state.responseSorting} onChange={e => this.setState({ responseSorting: e.target.value })}>
              <option value={"date-down"}>Recent Reponses</option>
              <option value={"date-up"} >Oldest Reponses</option>
            </select> */}

          {QuestionDetail.renderDataTable(this.state.responses, this.state.initiation.CollaborateType.toLowerCase(), this.state.responseSorting)}
        </>
      );
    }
    else {
      return (
        <>
          <h2 className='light p-3 mb-0' style={{ backgroundColor: "#eee" }}>Responses</h2>
          <div className='responses question py-0 mb-0'>
            <div className='bg-white text-center py-4'>
              <p><i>There are currently no responses!<br /> Be the first by posting below.</i></p>
            </div>
          </div>
        </>
      )
    }

  }

  render() {
    let contents = this.state.loading ? (<p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p>) :
      (<>
        <InitiationCard key={this.state.initiation.Id} data={this.state.initiation} detailView={true} />
        {this.renderResponses()}
      </>
      )
    return (
      <>
        <h1 className='fw-normal text-question'>QUESTION DETAIL</h1>
        {contents}
        <div className="response-container responses question title-block title-arrow" style={{ backgroundColor: "#eee" }}>
          <div className='title-block'>
            <i className="fa-solid fa-2x fa-caret-right title-arrow"></i>
            <h3 className="qrHeader text-center py-2" style={{ borderBottom: "2px #78cdd4 dashed" }}>{this.state.showEditResponse ? <>Edit your Response</> : <>Add your Response</>}</h3>
            <div className="addResponse-wrapper" hidden={this.state.showEditResponse}>
              <p className="toolGuide text-start" style={{ fontSize: "x-small" }}>
                Focus your attention and optimise collaboration by sharing a commonly understood mental model,
                using techniques from the Mentors Toolkit.<br /> TOOLS ARE OPTIONAL, for more info click <Link
                  to="/collaborationtools">TOOL INFO</Link>
              </p>
              <form id="addQGResponse">
                <div className="">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="ui-block-a">
                      <label className='px-3'>Response tools</label>
                    </div>
                    <div className="ui-block-b w-50">
                      <select className='dd-select fw-bold' onChange={(e) => this.setState({ tools: e.target.value })} id="mkmRGTools" data-mini="true">
                        <option value="none">Select</option>
                        <option value="none">None</option>
                        <option value="6roam">Roles of a Mentor</option>
                        <option value="6sol">Sources of Learning</option>
                        <option value="epm">Experiential Capability</option>
                        <option value="ist">Intentional Story Telling</option>
                        <option value="km">Knowledge Mapping</option>
                        <option value="ra">Retrospective Analysis</option>
                        <option value="scarf">SCARF</option>
                        <option value="knat">Knowledge Assets</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="ui-block-a">
                      <label className='px-3'>Tool techniques</label>
                    </div>
                    <div className="ui-block-b w-50">
                      <select className='dd-select fw-bold' onChange={(e) => this.setState({ technique: e.target.value })} id="toolRGTeq" data-mini="true">
                        <option value="none">Select</option>
                        <option value="">None</option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <input type="hidden" id="questId" value="" />
                  <input type="hidden" id="questRUser" value="" />
                  <input type="hidden" id="qrQTheme" value="" />
                  <Attachments
                    componentType={"question"}
                    returnState={this.returnState()}
                  />
                </div>
              </form>
            </div>
            <div className="text-start py-2" hidden={!this.state.showEditResponse}>
              <label className='form-label mb-3'>
                <i className='fas fa-circle ms-2 label-icon'></i> Edit your response
              </label>
              <textarea className='form-control' ref={this.textRef} defaultValue={this.state.editResponseText} onChange={e => this.setState({ editResponseText: e.target.value, charCount: e.target.value.length })} maxLength={350} style={{ minHeight: "80px" }} ></textarea>
              <div id="errResp" className='ErrorText'></div>
              <div className='small text-end'> {(350 - this.state.charCount)} characters left</div>
              <div className="d-flex justify-content-end mt-2">
                <button className='btn-small-action' onClick={this.updateResponse} >Update <i className='fas fa-chevron-right ms-2'></i></button>
              </div>
            </div>
          </div>
        </div>
        <ThrobbleHelper />
      </>
    );
  }


  async loadResponses(initId) {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch(APIPath() + '/nowculi/Responses?Offset=0&Limit=50&initiationId=' + initId, {
        method: 'GET',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ responses: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }
  }

  async loadData() {
    let idx = window.location.href.lastIndexOf("/");
    let initId = window.location.href.substring(idx + 1);
    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch(APIPath() + '/nowculi/initiations/getsingle/' + initId, {
        method: 'GET',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ initiation: data, inputUser: data.UserName, parentId: data.Id });
        this.loadResponses(initId);
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(
        APIPath() + "/nowculi/Themes/ListForCompany",
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        this.setState({ themes: data });
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

  }

  async updateResponse(evt) {
    evt.stopPropagation();

    let isValid = false;

    isValid = fieldRequired(this.state.editResponseText, "errResp", "* Required");
    if (isValid) {
      let bearer = "Bearer " + getAuthToken();
      let data = {
        id: this.state.editResponseId,
        mkm_response: linter(this.state.editResponseText)
      }
      ThrobbleHelper.toggleThrobble(true, "Updating response...");

      const response = await fetch(APIPath() + "/nowculi/initiations/Response",
        {
          method: "POST",
          headers: {
            "Authorization": bearer,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        });

      if (response.ok) {
        // window.location.reload();
        this.loadData();
        this.setState({ showEditResponse: false }, () => this.sortBlockRef.current.focus());
        ThrobbleHelper.toggleThrobble(false);
      } else {
        swal("Response Update", "There was an error updating your response", "error");
        ThrobbleHelper.toggleThrobble(false);
      }
    }
  }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { MyContext } from '../../Provider'

export default class TrainVidView extends Component {
    render() {
        if (this.context?.trainingVideo === "First Time Registration") {
            return (
                <div>
                    <h1>{this.context.trainingVideo}</h1>
                    <p className="plr-10">This short video explains how to obtain the mobile App and how to download,
                        register and log in for the first time.</p>
                    <div>
                        <iframe
                            src="https://mmastorage.blob.core.windows.net/uservideos/965b81d0-e050-49e3-b8c7-428d4ebb3fe6.mp4"
                            id="tvid_1" className="w-100" allowfullscreen height="300px"></iframe>
                    </div>
                    <div className="btn-back d-flex">
                        <i className="fa fa-angle-left"></i>
                        &nbsp;
                        <Link to={-1} data-rel="back" id="closeVideo" className='text-light'>CLOSE</Link>

                    </div>
                </div>
            )
        } else if (this.context?.trainingVideo === "The Home Screen") {
            return (
                <div>
                    <h1>{this.context.trainingVideo}</h1>
                    <p className="plr-10">This short video explains how to navigate around the Home screen and where all the main
                        App features and functionality are found.</p>
                    <div>
                        <iframe src="https://mmastorage.blob.core.windows.net/uservideos/875932ca-5488-4f41-8925-52b22aac038c.mp4" id="tvid_2" className="video w-100" allowfullscreen></iframe>
                    </div>
                    <div className="btn-back d-flex">
                        <i className="fa fa-angle-left"></i>
                        &nbsp;
                        <Link to={-1} data-rel="back" id="closeVideo" className='text-light'>CLOSE</Link>

                    </div>
                </div>
            )
        } else if (this.context?.trainingVideo === "Understanding My Profile") {
            return (
                <div>
                    <h1>{this.context.trainingVideo}</h1>
                    <p className="plr-10">This short video explains the content and layout of the My Profile screen and the
                        important reasons for completing your profile in as much detail as possible.</p>
                    <div>
                        <iframe src="https://mmastorage.blob.core.windows.net/uservideos/e47274a9-075c-4853-8d19-420077515679.mp4" id="tvid_2" className="video w-100" allowfullscreen></iframe>
                    </div>
                    <div className="btn-back d-flex">
                        <i className="fa fa-angle-left"></i>
                        &nbsp;
                        <Link to={-1} data-rel="back" id="closeVideo" className='text-light'>CLOSE</Link>

                    </div>
                </div>
            )
        } else if (this.context?.trainingVideo === "Creating my Profile") {
            return (
                <div>
                    <h1>{this.context.trainingVideo}</h1>
                    <p className="plr-10">This short video explains how to go about creating and / or updating your My Profile
                        screen so that your peers and / or professionals see the information you would like to share with them.</p>
                    <div>
                        <iframe src="https://mmastorage.blob.core.windows.net/uservideos/cd03e3dc-2e67-4c6e-b62e-9b434a8bc743.mp4" id="tvid_2" className="video w-100" allowfullscreen></iframe>
                    </div>
                    <div className="btn-back d-flex">
                        <i className="fa fa-angle-left"></i>
                        &nbsp;
                        <Link to={-1} data-rel="back" id="closeVideo" className='text-light'>CLOSE</Link>

                    </div>
                </div>
            )
        } else if (this.context?.trainingVideo === "The Menu and Quick Links") {
            return (
                <div>
                    <h1>{this.context.trainingVideo}</h1>
                    <p className="plr-10">This short video explains the menu structure and how this helps with rapid App navigation
                        and where the quick links are.</p>
                    <div>
                        <iframe src="https://mmastorage.blob.core.windows.net/uservideos/86519edb-a63b-40ec-b699-84c9f0769a5d.mp4" id="tvid_2" className="video w-100" allowfullscreen></iframe>
                    </div>
                    <div className="btn-back d-flex">
                        <i className="fa fa-angle-left"></i>
                        &nbsp;
                        <Link to={-1} data-rel="back" id="closeVideo" className='text-light'>CLOSE</Link>

                    </div>
                </div>
            )
        } else if (this.context?.trainingVideo === "Program And Purpose") {
            return (
                <div>
                    <h1>{this.context.trainingVideo}</h1>
                    <p className="plr-10">This short video explains how the Program and Purpose information screens work and how
                        they guide the direction and expectations for the program.</p>
                    <div>
                        <iframe src="https://mmastorage.blob.core.windows.net/uservideos/3a5979eb-8c47-41ba-9d4a-c5aab9e68da3.mp4" id="tvid_2" className="video w-100" allowfullscreen></iframe>
                    </div>
                    <div className="btn-back d-flex">
                        <i className="fa fa-angle-left"></i>
                        &nbsp;
                        <Link to={-1} data-rel="back" id="closeVideo" className='text-light'>CLOSE</Link>

                    </div>
                </div>
            )
        } else if (this.context?.trainingVideo === "Collaboration Tools") {
            return (
                <div>
                    <h1>{this.context.trainingVideo}</h1>
                    <p className="plr-10">This short video explains how the 8 Collaboration Tools work and how they should be used
                        to trigger context-rich collaborations.</p>
                    <div>
                        <iframe src="https://mmastorage.blob.core.windows.net/uservideos/e3a33773-c4c8-4ee8-a0e6-6e5cfd99c8f7.mp4" id="tvid_2" className="video w-100" allowfullscreen></iframe>
                    </div>
                    <div className="btn-back d-flex">
                        <i className="fa fa-angle-left"></i>
                        &nbsp;
                        <Link to={-1} data-rel="back" id="closeVideo" className='text-light'>CLOSE</Link>

                    </div>
                </div>
            )
        } else if (this.context?.trainingVideo === "Posing A Question") {
            return (
                <div>
                    <h1>{this.context.trainingVideo}</h1>
                    <p className="plr-10">This short video explains the importance and types of well-structured Questions that can
                        trigger powerful knowledge exchanges between the participants in the group.</p>
                    <div>
                        <iframe src="https://mmastorage.blob.core.windows.net/uservideos/21f33ce8-63ec-4c12-8ae9-e07019d44f10.mp4" id="tvid_2" className="video w-100" allowfullscreen></iframe>
                    </div>
                    <div className="btn-back d-flex">
                        <i className="fa fa-angle-left"></i>
                        &nbsp;
                        <Link to={-1} data-rel="back" id="closeVideo" className='text-light'>CLOSE</Link>

                    </div>
                </div>
            )
        } else if (this.context?.trainingVideo === "Sharing And Insight") {
            return (
                <div>
                    <h1>{this.context.trainingVideo}</h1>
                    <p className="plr-10">This short video explains the importance of sharing insights and inspiration and how it
                        triggers powerful feelings of connectedness and belonging within the group.</p>
                    <div>
                        <iframe src="https://mmastorage.blob.core.windows.net/uservideos/521444c5-d742-44d4-ac07-a0cafd0b69af.mp4" id="tvid_2" className="video w-100" allowfullscreen></iframe>
                    </div>
                    <div className="btn-back d-flex">
                        <i className="fa fa-angle-left"></i>
                        &nbsp;
                        <Link to={-1} data-rel="back" id="closeVideo" className='text-light'>CLOSE</Link>

                    </div>
                </div>
            )
        }
    }
}

TrainVidView.contextType = MyContext
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CanSavePoints, getWalkthroughPoints } from '../../helpers/lookup';
import { fieldRequired } from '../../helpers/validation';

export default class ScreenPosts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Confirmed: !CanSavePoints("9b")
        };
    }

    onCheckboxChange(evt) {
        this.setState({ Confirmed: evt.target.checked }, () => fieldRequired(this.state.Confirmed, "errConfirm", "* Required"));
    }

    render() {
        return (
            <>
                <Modal className='walkthrough-container' isOpen={true} fullscreen fade backdrop="static">
                    <ModalHeader className='text-center d-inline'>{this.props.Header}</ModalHeader>
                    <ModalBody className='text-justify mt-3'>
                        <h4 className="fs-5">Screen: 2 of 3</h4>
                        {/* <p>View is the Posting a Question/Contribution/Insight screen: </p> */}
                        
                        <p>You can select a Knowledge Theme to either post a new collaboration or alternatively, to view other user’s posts and respond to them.</p>
                        <img src='/images/Walkthrough/post.png' alt={"Posting"} className='img-fluid mb-3' />
                        <p>Adding new posts as well as Rating and Responding to other’s posts will also earn you points on the Knowledge Quest.</p>

                        <p>Whenever possible, try to use logical or common tags and/or keywords so that others can find your posts easily in future searches by simply searching simple or obvious terms or words.</p>
                        <div className="mt-3">
                            <Input type='checkbox' className='me-2' defaultChecked={this.state.Confirmed} onChange={this.onCheckboxChange.bind(this)} /> I've read this screen and I understand how to optimise my group
                            focused or targeted collaborations to achieve maximum value for both myself and my tribe
                            <div id="errConfirm" className='ErrorText'></div>

                        </div>
                    </ModalBody>
                    <ModalFooter className="col-12 d-flex justify-content-between px-3">
                        <Button color='secondary' outline onClick={() => this.props.onStepChange("prev")} ><i className='fas fa-chevron-left me-2'></i> Prev</Button>
                        <Button color='success' onClick={this.onNextStep.bind(this)}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }

    onNextStep() {
        const isValid = fieldRequired(this.state.Confirmed, "errConfirm", "* Required");
        if (isValid) {
            this.props.onStepChange("next", "9b")
        }
    }
}

export const fieldRequired = (data, errorContainer, errorMessage) => {
  if (!data || data === '' || data === 0 || data === null || data === undefined) {
    const el = document.getElementById(errorContainer);
    el.setAttribute("tabindex", "1");
    el.focus();
    el.innerText = errorMessage;
    return false;
  }
  else {
    document.getElementById(errorContainer).innerText = "";
    return true;
  }
};


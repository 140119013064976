import React, { Component } from "react";
import { Attachments } from "../Attachments/Attachments";

export class PoseInsight extends Component {
  constructor(props) {
    super(props);

    this.returnState = this.returnState.bind(this);
  }

  returnState() {
    return null;
  }

  render() {
    return (
      <div className="addInsightContent">
        <h1>SHARE AN INSIGHT</h1>
        <Attachments componentType="insights" returnState={this.returnState()} />
      </div>
    );
  }
}

export default PoseInsight;

import React, { Component } from "react";

export class InstallPrompt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deferredPrompt: null,
      promptHidden: true,
      isAndroid: false,
      isiOS: false,
      isPC: false,
    };

    this.promptUser = this.promptUser.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleBeforeInstallPrompt = this.handleBeforeInstallPrompt.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeinstallprompt", this.handleBeforeInstallPrompt);
  }

  componentWillUnmount() {
    window.removeEventListener( "beforeinstallprompt", this.handleBeforeInstallPrompt);
  }

  handleBeforeInstallPrompt = (event) => {
    const userAgent = navigator.userAgent;
    const isAndroid = /Android/i.test(userAgent);
    const isiOS = /iPhone|iPad|iPod/i.test(userAgent);
    const isPC = !isiOS && !isAndroid;

    event.preventDefault();
    this.setState({
      deferredPrompt: event,
      isAndroid,
      isiOS,
      isPC,
    });

    const promptDismissed = localStorage.getItem('promptDismissed');
    if (!promptDismissed) {
      this.setState({
        deferredPrompt: event,
        promptHidden: !promptDismissed ? false : true,
        isAndroid,
        isiOS,
        isPC,
      });
    }
  };

  promptUser() {
    if (this.state.deferredPrompt) {
      this.state.deferredPrompt.prompt();

      this.state.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "dismissed") {
          localStorage.setItem("promptDismissed", "true");
        }

        
        this.setState({
          deferredPrompt: null,
          promptHidden: true,
        });
      });
    }
  }

  handleButtonClick = () => {
    localStorage.setItem('promptDismissed', 'true');
    this.setState({ promptHidden: true });
  };

  render() {
    const { isAndroid, isiOS, isPC, promptHidden } = this.state;

    return (
      <>
        {" "}
        {!isAndroid || !isPC ? (
          <div className="row installPrompt" hidden={promptHidden}>
            <div className="installPromptInner">
              <div className="col-md-12 mt-4 promptMessage">
                Install NOW.CULI® on your device?
              </div>
              <div className="col-md-12 mt-4 d-flex justify-content-evenly">
                <button
                  className="installBtn"
                  onClick={() => this.handleButtonClick()}
                >
                  No
                </button>
                <button
                  className="installBtn"
                  onClick={() => this.promptUser()}
                >
                  Install
                </button>
              </div>
            </div>
          </div>
        ) : (
           (
            <div className="row installPrompt" hidden={promptHidden}>
              <div className="installPromptInner">
                <div className="col-md-12 mt-4 promptMessage">
                  Install NOW.CULI® on your device?
                </div>
                <div className="col-md-12 mt-4 d-flex justify-content-evenly">
                  <button
                    className="installBtn"
                    onClick={() => this.handleButtonClick()}
                  >
                    No
                  </button>
                  <button
                    className="installBtn"
                    onClick={window.location.href = "/iosinstall"}
                  >
                    Install
                  </button>
                </div>
              </div>
            </div>
          )
        )}
      </>
    );
  }
}

export default InstallPrompt;

import React, { Component } from 'react';

import REGISTRATION from '../../assets/tools/Ttools_REGISTRATION.png';
import HOME from '../../assets/tools/Ttools_HOME_SCREEN.png';
import MYPROFILE from '../../assets/tools/Ttools_MYPROFILE.png';
import PROFILE from '../../assets/tools/Ttools_CREATING_PROFILE.png';
import LINKS from '../../assets/tools/Ttools_MENU_LINKS.png';
import PURPOSE from '../../assets/tools/Ttools_PROGRAM_PURPOSE.png';
import COLLAB from '../../assets/tools/TTools_COLLAB.png';
import QUESTIONS from '../../assets/tools/Ttools_QUESTIONS.png';
import CONTRIBUTIONS from '../../assets/tools/Ttools_CONTRIBUTIONS.png';
import INSIGHTS from '../../assets/tools/Ttools_INSIGHTS.png';
import { Link } from 'react-router-dom';
import { MyContext } from "../../Provider"


export class TrainingTools extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {


        return (
            <>
                <div className="plr-10">
                    <h1>App functionality and Knowledge Mentoring methodology training</h1>
                    <p>The following App functionality and Knowledge Mentoring methodology training is provided in short 90 second micro-learning
                        training vignettes and are designed to assist you with self-paced and own-time learning. This will accelerate your ability
                        to navigate quickly as well as optimise your engagement and the value of your learning experience through the effective use
                        and application of the powerful Knowledge Mentoring methodology.
                    </p>
                </div>

                <div className="container-nav-training d-flex mb-1">
                    <div>
                        <h3 className='teal'>First Time Registration</h3>
                        <p>This short video explains how to obtain the mobile App and how to download, register and log in for the first time.</p>
                    </div>
                    <Link to="/trainVidView" className='d-flex flex-column justify-content-evenly' onClick={() => { this.context.setTrainingVideo("First Time Registration") }}>
                        <a id="vid1" href="#" type="button" className="loadTrainVid nav-link" data-transition="flip">
                            <img alt='' src={REGISTRATION} className="img-responsive" />
                        </a>
                        <div className='d-flex'> <i className="fa fa-chevron-right orange px-1"></i> Play</div>
                    </Link>
                    <div className="cb"></div>
                </div>

                <div className="container-nav-training d-flex mb-1">
                    <div>
                        <h3 className='teal'>The Home Screen</h3>
                        <p>This short video explains how to navigate around the Home screen and where all the main App features and functionality are found.</p>
                    </div>
                    <Link to="/trainVidView" className='d-flex flex-column justify-content-evenly' onClick={() => { this.context.setTrainingVideo("The Home Screen") }}>
                        <a id="vid2" href="#" type="button" className="loadTrainVid nav-link" data-transition="flip">
                            <img alt='' src={HOME} className="img-responsive" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange px-1"></i> Play
                        </div>
                    </Link>
                    <div className="cb"></div>
                </div>

                <div className="container-nav-training d-flex mb-1">
                    <div>
                        <h3 className='teal'>Understanding My Profile</h3>
                        <p>This short video explains the content and layout of the My Profile screen and the important reasons for completing your profile in as much detail as possible</p>
                    </div>
                    <Link to="/trainVidView" className='d-flex flex-column justify-content-evenly' onClick={() => { this.context.setTrainingVideo("Understanding My Profile") }}>
                        <a id="vid3" href="#" type="button" className="loadTrainVid nav-link" data-transition="flip">
                            <img alt='' src={MYPROFILE} className="img-responsive" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange px-1"></i> Play
                        </div>
                    </Link>
                    <div className="cb"></div>
                </div>

                <div className="container-nav-training d-flex mb-1">
                    <div>
                        <h3 className='teal'>Creating my Profile</h3>
                        <p>This short video explains how to go about creating and / or updating your My Profile screen so that your peers and / or professionals see the information you would like to share with them.</p>
                    </div>
                    <Link to="/trainVidView" className='d-flex flex-column justify-content-evenly' onClick={() => { this.context.setTrainingVideo("Creating my Profile") }}>
                        <a id="vid4" href="#" type="button" className="loadTrainVid nav-link" data-transition="flip">
                            <img alt='' src={PROFILE} className="img-responsive" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange px-1"></i> Play
                        </div>
                    </Link>
                    <div className="cb"></div>
                </div>
                <Link to="/trainVidView" className="container-nav-training d-flex mb-1" onClick={() => { this.context.setTrainingVideo("The Menu and Quick Links") }} >
                    <div>
                        <h3 className='teal'>The Menu and Quick Links </h3>
                        <p>This short video explains the menu structure and how this helps with rapid App navigation and where the quick links are.</p>
                    </div>
                    <div className='d-flex flex-column justify-content-evenly'>
                        <a id="vid5" href="#" type="button" className="loadTrainVid nav-link" data-transition="flip">
                            <img alt='' src={LINKS} className="img-responsive" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange px-1"></i> Play
                        </div>
                    </div>
                    <div className="cb"></div>
                </Link>

                <Link to="/trainVidView" className="container-nav-training d-flex mb-1" onClick={() => { this.context.setTrainingVideo("Program And Purpose")}}>
                    <div>
                        <h3 className='teal'>Program And Purpose</h3>
                        <p>This short video explains how the Program and Purpose information screens work and how they guide the direction and expectations for the program.</p>
                    </div>
                    <div className='d-flex flex-column justify-content-evenly'>
                        <a id="vid6" href="#" type="button" className="loadTrainVid nav-link" data-transition="flip">
                            <img alt='' src={PURPOSE} className="img-responsive" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange px-1"></i> Play
                        </div>
                    </div>
                    <div className="cb"></div>
                </Link>

                <div className="container-nav-training d-flex mb-1">
                    <div>
                        <h3 className='teal'>Collaboration Tools</h3>
                        <p>This short video explains how the 8 Collaboration Tools work and how they should be used to trigger context-rich collaborations.</p>
                    </div>
                    <Link  to="/trainVidView" className='d-flex flex-column justify-content-evenly' onClick={() => { this.context.setTrainingVideo("Collaboration Tools")}}>
                        <a id="vid7" href="#" type="button" className="loadTrainVid nav-link" data-transition="flip">
                            <img alt='' src={COLLAB} className="img-responsive" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange px-1"></i> Play
                        </div>
                    </Link>
                    <div className="cb"></div>
                </div>

                <div className="container-nav-training d-flex mb-1">
                    <div>
                        <h3 className='teal'>Posing A Question</h3>
                        <p>This short video explains the importance and types of well-structured Questions that can trigger powerful knowledge exchanges between the participants in the group.</p>
                    </div>
                    <Link to="/trainVidView" className='d-flex flex-column justify-content-evenly' onClick={() => { this.context.setTrainingVideo("Posing A Question")}}>
                        <a id="vid8" href="#" type="button" className="loadTrainVid nav-link" data-transition="flip">
                            <img alt='' src={QUESTIONS} className="img-responsive" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange px-1"></i> Play
                        </div>
                    </Link>
                    <div className="cb"></div>
                </div>

                <div className="container-nav-training d-flex mb-1 ">
                    <div>
                        <h3 className='teal'>Posting A Contribution</h3>
                        <p>This short video explains the importance and types of well-structured Contributions that can trigger powerful knowledge exchanges between the participants in the group.</p>
                    </div>
                    <Link to="/trainVidView" className='d-flex flex-column justify-content-evenly' onClick={() => { this.context.setTrainingVideo("Posing A Contribution")}}>
                        <a id="vid9" href="#" type="button" className="loadTrainVid nav-link" data-transition="flip">
                            <img alt='' src={CONTRIBUTIONS} className="img-responsive" />
                        </a>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange px-1"></i> Play
                        </div>
                    </Link>
                    <div className="cb"></div>
                </div>

                <div className="container-nav-training d-flex mb-1">
                    <div>
                        <h3 className='teal'>Sharing And Insight</h3>
                        <p>This short video explains the importance of sharing insights and  inspiration and how it triggers powerful feelings of connectedness and belonging within the group.</p>
                    </div>
                    <Link to="/trainVidView" className='d-flex flex-column' onClick={() => { this.context.setTrainingVideo("Sharing And Insight")}}>
                        <div className='text-center'>
                        <a id="vid10" href="#" type="button" className="loadTrainVid nav-link" data-transition="flip">
                            <img alt='' src={INSIGHTS} className="img-responsive float-right" />
                        </a>
                        </div>
                        <div className='d-flex'>
                            <i className="fa fa-chevron-right orange px-1"></i> Play
                        </div>
                    </Link>
                    <div className="cb"></div>
                </div>
            </>
        )
    }
}

TrainingTools.contextType = MyContext;
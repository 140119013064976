import React, { Component } from "react";
import { getAuthToken } from "../../helpers/authentication";
import { APIPath } from "../../helpers/APIHelper";
import { ThemeCard } from "../UI/ThemeCard";
import { ProfileSummary } from "../Profile/ProfileSummary";
import { getUser, getUserId, setUser } from "../../helpers/lookup";
import InstallPrompt from "../InstallPrompt/InstallPrompt";
import { ConsoleView } from "react-device-detect";
import { LoadingSpinner } from '../../helpers/global';

export class Home extends Component {
  static parentObj = null;
  static AllPosts = [];
  constructor(props) {
    super(props);
    Home.parentObj = this;
    Home.AllPosts = [];
    this.state = { editData: [], filteredData: [], themeSummary: [], loading: true };
  }

  componentDidMount() {
    document.title = "NOW.CULI®";
    this.loadData();
  }

  static SearchHome(keywords) {
    keywords = keywords.trim().toLowerCase();
    let filteredData = Home.AllPosts.filter(c => !c.Urgent || c.Resolved);
    let filteredUrgentPosts = Home.AllPosts.filter(c => c.Urgent && !c.Resolved);
    if (keywords.length > 2) {
      filteredData = filteredData.filter(
        (q) =>
          q.InitiationText.toLowerCase().indexOf(keywords) !== -1 ||
          q.Keywords.toLowerCase().indexOf(keywords) !== -1
      );

      filteredUrgentPosts = filteredUrgentPosts.filter((u) => u.InitiationText.toLowerCase().indexOf(keywords) > -1 || u.Keywords.toLowerCase().indexOf(keywords) > -1);

    } else {
      filteredData = Home.parentObj.state.editData;
      filteredUrgentPosts = Home.parentObj.state.urgentPosts;
    }
    Home.parentObj.setState({ filteredData, filteredUrgentPosts });

    return filteredData.length > 0 || filteredUrgentPosts.length > 0;
  }



  render() {

    let contents = this.state.loading ? LoadingSpinner() : (
      <>
        <ProfileSummary />
        {
          
            <div className="bg-gray">
              
              {this.state.themeSummary.map((card, index) => (
                <ThemeCard key={index} data={card} />
              ))}
            </div>
            
        }
      </>
    );

    return (
      <>
        {contents}
        <InstallPrompt />
      </>
    );
  }

  async loadData() {
    var bearer = "Bearer " + getAuthToken();
    let UserId = getUserId();
    
    try {
      const response = await fetch(
        APIPath() + "/global/userprofile?id=" + UserId,
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const user = await response.json();
        setUser(user);
         if (user.WalkThroughStatus === 0)
          window.location.href = "/plain/walkthrough";
      } else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

    try {
      //let orgiD = getUser().OrganisationId;
      const response = await fetch(
        APIPath() +
        `/nowculi/Initiations/ListAllSummary`,
        {
          method: "GET",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        this.setState({
          themeSummary: data,
          loading: false
        });

      } else {
        this.setState({loading: false});
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {

      console.error(e);
    }

  


  }

}

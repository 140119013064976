import React, { Component } from "react";
import * as signalR from "@microsoft/signalr";
import { getUser } from "./lookup";
import { APIPath } from "./APIHelper";

export default class PushNotifications extends Component {
  static parentObj = null;
  constructor(props) {
    super(props);
    PushNotifications.parentObj = this;
    this.state = {
      connection: null,
      token: "",
    };

    this.joinGroup = this.joinGroup.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
  }

  componentDidMount() {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${APIPath()}/pushNotifications`)
      .withAutomaticReconnect()
      .build();

    this.setState({
      connection: newConnection,
    });

    newConnection
      .start()
      .then(() => {
        const user = getUser();
        const notify = (message) => {
          this.handleNotification(message);
        };
        console.log("Started SignalR");

        if (user !== null) {
          if ("Notification" in window) {
            if (Notification.permission !== "granted") {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  console.log("Permission granted");
                  this.joinGroup(user.OrganisationId);
                }
              });
            } else {
              console.log("Permission already granted");
            }
          }
        }

        this.state.connection.on("ReceiveGroupMessage", function (message) {
          notify(message);
        });

        this.state.connection.onclose(() => {
          console.log("SignalR connection has been closed");
        });
      })
      .catch((err) => {
        console.error(err.toString());
      });
  }

  joinGroup(groupName) {
    this.state.connection.invoke("JoinGroup", groupName).catch(function (err) {
      console.error(err.toString());
    });
  }

  static notifyGroup(groupName, message) {
    PushNotifications.parentObj.state.connection
      .invoke("SendGroupNotification", groupName, message)
      .catch(function (err) {
        console.error(err.toString());
      });
  }

  handleNotification(message) {

    // const messaging = getMessaging(firebaseInit);

    // if (Notification.permission === "granted") {
    //   new Notification("NOW.CULI®", {
    //     body: message,
    //     icon: "../assets/MultiApi/NOW_CULI.png",
    //   });
    // } else {
    //   Notification.requestPermission().then((permission) => {
    //     if (permission === "granted") this.handleNotification(message);
    //   });
    // }
  }

  render() {
    return <div></div>;
  }
}

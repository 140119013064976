export const getAuthToken = () => {
  if (window.localStorage.getItem("AuthToken") != null) {
    var data = JSON.parse(window.localStorage.getItem("AuthToken"));
    //todo: check expiry
    return data.authToken;
  }
  else
    return null;
};

export const setAuthToken = (token, expiry) => {
  const data = { authToken: token, authExpires: expiry }
  try {
    window.localStorage.setItem("AuthToken", JSON.stringify(data));
    return true;
  } catch (e) {
    console.error(e)
    return false;
  }
};

export const removeAuthToken = () => {
  if (window.localStorage.getItem("AuthToken") != null) {
    window.localStorage.removeItem("AuthToken");
    //todo: check expiry
    return true;
  }
  else
    return false;

};

export const removeLoginDetails = () => {
  window.localStorage.removeItem("AuthToken");
  window.localStorage.removeItem("CurrentUser");
  window.localStorage.removeItem("CurrentUserId");
  window.localStorage.removeItem("CreatedAt");
  window.localStorage.removeItem("UserEmail");
  window.localStorage.removeItem("UserGroup");
  window.localStorage.removeItem("UserMobile");
  window.sessionStorage.removeItem("IsLoggedIn");
}

export const getIsLoggedIn = () => {
  if (window.sessionStorage.getItem("IsLoggedIn") !== null && window.sessionStorage.getItem("IsLoggedIn") !== undefined) {
    let value = window.sessionStorage.getItem("IsLoggedIn");
    return value === "true" || value === true;
  }
  else
    return false;
}

export const setIsLoggedIn = (value) => {
  try {
    window.sessionStorage.setItem("IsLoggedIn", value);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const GetUserMobile = () => {
  if (window.localStorage.getItem("UserMobile"))
    return JSON.parse(window.localStorage.getItem("UserMobile"));
  else
    return "";
};

export const SetUserMobile = (data) => {
  try {
    window.localStorage.setItem("UserMobile", data);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}


export const GetOTP = () => {
  if (window.sessionStorage.getItem("UserOTP")) {
    return window.sessionStorage.getItem("UserOTP");
  }
  else
    return "";
}

export const SetOTP = (data) => {
  try {
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export const getUserDetails = () => {
  if (window.localStorage.getItem("CurrentUser") !== null || (window.localStorage.getItem("CurrentUser") !== undefined))
    return JSON.parse(window.localStorage.getItem("CurrentUser"));
  else
    return null;
};

export const setUserDetails = (props) => {
  try {
    window.localStorage.setItem("CurrentUser", JSON.stringify(props));
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }

};


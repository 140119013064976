import React, { Component } from 'react';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';
import noAvatar from '../../assets/noAvatar1.png';
import { getUser, setEditId } from '../../helpers/lookup';
import { GetAvatar, GetCurrentLevel, GetUserRanking, Ordinate, LoadingSpinner } from '../../helpers/global';

export class Leaderboard extends Component {
    constructor(props) {

        super(props);
        this.state = { selection: "All", editData: [], filteredUsers: [], loading: true };
        this.quickSearch = this.quickSearch.bind(this);
    }


    componentDidMount() {
        document.title = "NOW.CULI®";
        this.loadData();
    }

    renderRanks(rank) {
        if (rank === 1) {
            return "st";
        } else if (rank === 2) {
            return "nd";
        } else if (rank === 3) {
            return "rd";
        } else {
            return "th";
        }
    }


    applyLevelFilter = (value) => {
        let data = this.state.editData;

        if (value === "One") {
            //let topFiveLevelOne = data?.filter(item => this.GetByLevel(item.Score, 1));
            this.setState({ filteredUsers: data?.filter(item => item.Profile_UTL <= 1) });
        } else if (value === "Two") {
            // let topFiveLevelTwo = data?.filter(item => this.GetByLevel(item.Score, 2));
            // this.setState({ filteredUsers: topFiveLevelTwo, loading: false });
            this.setState({ filteredUsers: data?.filter(item => item.Profile_UTL === 2) });
        } else if (value === "Three") {
            //let topFiveLevelThree = data?.filter(item => this.GetByLevel(item.Score, 3));
            this.setState({ filteredUsers: data?.filter(item => item.Profile_UTL === 3) });
        } else {
            //let topFive = data?.slice(0, 5);
            this.setState({ filteredUsers: data });
        }
    }

    async handleLevelChange(value) {
        this.setState({ selection: await value, loading: true });
        this.applyLevelFilter(value);
        this.setState({ loading: false });
    }

    GetByLevel(Score, Level) {
        const ScoreRank = GetUserRanking(Score);
        return ScoreRank === Level;
    }

    viewProfile(UserId) {
        setEditId(UserId);
        window.location.href = "/viewprofile";
    }

    quickSearch(e) {
        const searchVal = e.target.value.toLowerCase();

        this.applyLevelFilter(this.state.selection)
        console.log(this.state.selection);
        if (searchVal !== "") {
            this.setState({
                filteredUsers: this.state.filteredUsers.filter(item =>
                    item.UserName.toLowerCase().includes(searchVal) ||
                    item.JobTitle.toLowerCase().includes(searchVal) ||
                    item.UserCountry.toLowerCase().includes(searchVal)
                )
            });
        }
    }

    renderUserDetails = (JobTitle, UserCountry, ProfBodyame) =>{
        if (JobTitle !== null && JobTitle !== "") {
            return (
                <div>
                    <span className='pe-2'>{JobTitle}</span>
                    {UserCountry}
                    <div className='small text-muted'>{ProfBodyame}</div>
                </div>
            )
        }
        else {
            return (
                <div>
                    {UserCountry}
                    <div className='small text-muted'>{ProfBodyame}</div>
                </div>
            )
        }
    }

    renderLeaderboard = () => {
        return (
            <table className='ranking-list'>
                <thead>
                    <tr>
                        <th style={{ width: '50px' }}>Rank</th>
                        <th colSpan='2'>&nbsp;&nbsp;Name</th>
                        <th className='text-right'>Score</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.editData && !this.state.loading && this.state.filteredUsers.map((item, index) =>
                        <tr key={index}>

                            <td>{Ordinate(index + 1)}</td>
                            <td style={{ width: '90px' }}><div className='avatar-container small' onClick={() => this.viewProfile(item.Id)}><img className='avatar' id='' src={item.Avatar ? GetAvatar(item?.Avatar) : noAvatar} alt='profile avatar' /></div></td>

                            <td>
                                <span className='fw-bold'>{item.UserName}</span><br />
                                {/* <span className='pe-2'>{item.JobTitle}</span> */}
                                {this.renderUserDetails(item.JobTitle, item.UserCountry, item.ProfBodyName)}
                                {/* {item.UserCountry}
                                <div className='small text-muted d-none'>{GetCurrentLevel(item.Score)}</div>
                                <div className='small text-muted'>{item.ProfBodyName}</div> */}

                            </td>
                            <td className='text-right'>{item.Score}</td>
                        </tr>)}
                </tbody>
            </table>
        )
    }

    render() {
        let content = this.state.loading ? LoadingSpinner() : this.renderLeaderboard();
        return (
            <>
                <h2 className='full-width-title mb-2'>RANKING SUMMARY</h2>
                <div data-role="content" className='px-2'>
                    <div className="flex-box4 text-center mb-2">
                        <div id="utlAll" onClick={(e) => { this.handleLevelChange(e.target.innerText); }} style={{ background: this.state.selection === "All" && "silver" }} className="boxi">All</div>
                        <div id="utl1" onClick={(e) => { this.handleLevelChange(e.target.innerText); }} style={{ background: this.state.selection === "One" && "silver" }} className="viewRankings boxi">One</div>
                        <div id="utl2" onClick={(e) => { this.handleLevelChange(e.target.innerText); }} style={{ background: this.state.selection === "Two" && "silver" }} className="viewRankings boxi">Two</div>
                        <div id="utl3" onClick={(e) => { this.handleLevelChange(e.target.innerText); }} style={{ background: this.state.selection === "Three" && "silver" }} className="viewRankings boxi">Three</div>
                    </div>
                    <p className="view-rank-instruction text-center">Select User Level to view Rankings per levels.</p>
                    <p className="text-left mpt-0">These are the top 5 contributors and responders in the current cycle, who have
                        achieved the highest scores to date. Remember you can get points by responding to and rating items, as
                        well as receiving ratings on your own contributions.</p>
                    <h4 id="userRankHead" style={{ margin: "1px", fontSize: "1.2em", textAlign: "center" }}>{this.state.selection.toUpperCase()}</h4>
                    <div className='row my-3'>
                        <div className='col-md-6 offset-md-6'>
                            {/* <input type="text" id="search" className="form-control" placeholder="Search" onChange={(e) => this.setState({ filteredUsers: this.state.editData.filter(item => item.UserName.toLowerCase().includes(e.target.value.toLowerCase())) })} /> */}
                            <input type="text" id="search" className="form-control" placeholder="Search" onChange={this.quickSearch} />
                        </div>
                    </div>
                    <div id="rankHolder" className='mb-3'>
                        {content}
                    </div>
                </div>
            </>
        )
    }

    async loadData() {
        var bearer = 'Bearer ' + getAuthToken();
        let user = getUser();
        try {
            const response = await fetch(APIPath() + "/global/UserProfile/LoadAllUsers?id=" + user.OrganisationId, {
                method: 'GET',
                headers: {
                    "Authorization": bearer,
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
                const users = await response.json();
                this.setState({ editData: users, filteredUsers: users, loading: false });
                console.log(users);

            } else {
                console.log(response.status + ": " + response.statusText);
            }
        } catch (e) {
            console.error(e);
        }
    }
}

import React, { Component } from 'react'
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';
import { getEditId } from '../../helpers/lookup';
import Achievements from './Achievements';
import MenteeMentor from './MenteeMentor';
import ProfileInfo from './profile-info';
import { LoadingSpinner } from '../../helpers/global';

export default class ViewProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            partners: [],
            loading: true
        }
    }

    componentDidMount() {
        this.loadData();
    }

    renderViewProfile(data) {
        return (
            <div className=''>
                <h1 className='text-center py-2 fw-bold'>VIEW PROFILE</h1>
                <ProfileInfo User={data.user} viewing={true} />
                <Achievements TotalDiamonds={data.user.TotalDiamonds} WizardsReached={data.user.WizardsReached} />
                <MenteeMentor Partners={data.partners} hidden={data.partners.length === 0} />
            </div>);
    }

    render() {

        let content = this.state.loading ? LoadingSpinner() : this.renderViewProfile(this.state);
        return (
            content
        );
    }

    async loadData() {
        let UserId = getEditId();
        let bearer = "bearer " + getAuthToken();
        const response = await fetch(APIPath() + "/global/userprofile?id=" + UserId, {
            method: 'GET',
            headers: {
                "Authorization": bearer,
                "Content-Type": "application/json"
            }
        });

        if (response.ok) {
            let user = await response.json();
            this.setState({ user, partners: user.MentorMentees, loading: false });
        }
    }
}

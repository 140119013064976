import React from "react";
import { Component } from "react";

import { Layout } from "./components/Layouts/Layout";
import PreLogin from "./components/Layouts/PreLogin";
import NoLayout from "./components/Layouts/NoLayout";
//import { Home } from "./components/Home/home";
import { Home } from "./components/Home/newhome";
import { CompanyPurpose } from "./components/InfoPages/CompanyPurpose";
import { CollaborationTools } from "./components/InfoPages/CollaborationTools";
import { TrainingTools } from "./components/InfoPages/TrainingTools";
import { Collaboration } from "./components/InfoPages/Collaboration";
import { KnowledgeQuest } from "./components/InfoPages/KnowledgeQuest";
import { UpdatePassword } from "./components/Profile/UpdatePassword";
import { Leaderboard } from "./components/Leaderboard/Leaderboard";
import { FullProfile } from "./components/Profile/FullProfile";
import { CollaborationsList } from "./components/Collaborations/Collaborations";
import { CollaborationDetail } from "./components/Collaborations/CollaborationDetail";
import { PoseCollaboration } from "./components/Collaborations/PoseCollaboration";

import { QuestionList } from "./components/Questions/Questions";
import { QuestionDetail } from "./components/Questions/QuestionDetail";
import { PoseQuestion } from "./components/Questions/PoseQuestion";
import { ContributionList } from "./components/Contributions/Contributions";
import { ContributeDetail } from "./components/Contributions/ContributeDetail";
import { PoseContribution } from "./components/Contributions/PoseContribution";
import { InsightList } from "./components/Insights/Insights";
import { PoseInsight } from "./components/Insights/PoseInsight";
import WrappedUploadSuccess from "./components/Attachments/UploadSuccess";

import { setDeviceInfo } from "./helpers/lookup";
import { getIsLoggedIn } from "./helpers/authentication";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import "./css/nowculi.scss";
import Detail from "./components/InfoPages/Detail";
import { Rating } from "./components/Ratings/Rating";
import OutcomeDetails from "./components/InfoPages/OutcomeDetails";
import CollaborationToolsDetails from "./components/InfoPages/CollaborationToolsDetails";
import TrainVidView from "./components/InfoPages/TrainVidView";
import IosInnstall from "./components/InfoPages/IosInstallGuide";
import AppFeedBack from "./components/AppFeedback/AppFeedBack";
import Glossaries from "./components/Glossaries/Glossaries";
import GlossaryOfAcronyms from "./components/Glossaries/GlossaryOfAcronyms";
import GlossaryOfTerms from "./components/Glossaries/GlossaryOfTerms";
import OptLogin from "./components/Login/OtpLogin";
import { SelectGroup } from "./components/Login/SelectGroup";
import { Route, Routes } from "react-router-dom";
import ViewProfile from "./components/Profile/ViewProfile";
import { ResponseRating } from "./components/Ratings/ResponseRating";
import { InitializeFirebase } from "./helpers/FirebaseHelper";
import FirebaseNotifications from "./helpers/firebase-notifications";
//import StepOne from "./components/Walkthrough/StepOne";
import Walkthrough from "./components/Walkthrough/Walkthough";
import PageTracker from './helpers/PageTracker';


export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      defferedPrompt: null
    };

    this.contentRef = React.createRef();
  }
  componentDidMount() {
    ///console.log(navigator.app);
    setDeviceInfo(JSON.stringify(navigator));
    if (this.exitsOnBack()) this.handleBackEvents();
  }

  componentDidUpdate(prevProps) {
    //console.log("page updated");
    // if (this.props.location !== prevProps.location) {
    //   console.log('Page changed to', this.props.location.pathname);
    // }
  }

  handleBackEvents() {
    window.history.pushState({}, '');

    window.addEventListener('popstate', () => {
      //TODO: Optionally show a "Press back again to exit" tooltip
      setTimeout(() => {
        window.history.pushState({}, '');
        //TODO: Optionally hide tooltip
      }, 2000);
    });
  }
  isStandalone() {
    return !!navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
  }

  // Depends on bowser but wouldn't be hard to use a
  // different approach to identifying that we're running on Android
  exitsOnBack() {
    //console.log(navigator);
    const userAgent = navigator.userAgent;
    const isAndroid = /Android/i.test(userAgent);
    return this.isStandalone() && isAndroid;
    //    return this.isStandalone() && navigator.app.platform === 'Android';
    //browserInfo.os.name === 'Android';
  }


  handleTouch() { }

  render() {
    const { defferedPrompt } = this.state;
    return (
      <>
        {getIsLoggedIn() ? (
          <>
            <FirebaseNotifications />

            <div ref={this.contentRef} onTouchStart={this.handleTouch}>
              <PageTracker />
              <Routes>
                <Route exact path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path="/companypurpose" element={<CompanyPurpose />} />
                  <Route path="/collaborationtools" element={<CollaborationTools />} />
                  <Route path="/trainingtools" element={<TrainingTools />} />
                  <Route path="/collaboration" element={<Collaboration />} />
                  <Route path="/knowledgequest" element={<KnowledgeQuest />} />
                  <Route path="/updatepassword" element={<UpdatePassword />} />
                  <Route path="/leaderboard" element={<Leaderboard />} />
                  <Route path="/fullprofile" element={<FullProfile />} />
                  <Route path="/viewprofile" element={<ViewProfile />} />
                  <Route path="/detail" element={<Detail />} />
                  <Route path="/outcomes" element={<OutcomeDetails />} />
                  <Route path="/collaborationdetails" element={<CollaborationToolsDetails />} />

                  <Route path="/collaborations/:theme" element={<CollaborationsList />} />
                  <Route path="/collaborationdetail/:id" element={<CollaborationDetail />} />
                  <Route path="/posecollaboration" element={<PoseCollaboration />} />


                  <Route path="/questions" element={<QuestionList />} />
                  <Route path="/questiondetail/:id" element={<QuestionDetail />} />
                  <Route path="/posequestion" element={<PoseQuestion />} />
                  <Route path="/contributions" element={<ContributionList />} />
                  <Route path="/contributedetail/:id" element={<ContributeDetail />} />
                  <Route path="/posecontribution" element={<PoseContribution />} />
                  <Route path="/insights" element={<InsightList />} />
                  <Route path="/rating/:id" element={<Rating />} />
                  <Route path="/responseRating/:id" element={<ResponseRating />} />
                  <Route path="/poseinsight" element={<PoseInsight />} />
                  <Route path="/uploadsuccess" element={<WrappedUploadSuccess />} />
                  <Route path="/trainVidView" element={<TrainVidView />} />
                  <Route path="/appFeedback" element={<AppFeedBack />} />
                  <Route path="/glossaryOfAcronyms" element={<GlossaryOfAcronyms />} />
                  <Route path="/glossaryOfTerms" element={<GlossaryOfTerms />} />
                  <Route path="/iosinstall" element={<IosInnstall />} />
                </Route>
                <Route path="/plain" element={<NoLayout />}>
                  <Route path="/plain/glossaryLoad" element={<Glossaries />} />
                  <Route path="/plain/walkthrough" element={<Walkthrough />}></Route>    
                </Route>
              </Routes>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/" element={<PreLogin />} />
            <Route path="/plain" element={<NoLayout />}>
              <Route path="/plain/walkthrough" element={<Walkthrough />}></Route>
              <Route path="/plain/updatepassword" element={<UpdatePassword />}              />
              <Route path="/plain/selectgroup" element={<SelectGroup />} />
              <Route path="/plain/userotp" element={<OptLogin />} />
            </Route>
          </Routes>
        )}
      </>
    );
  }
}

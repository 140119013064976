import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Header } from '../Nav/header';
import { Footer } from '../Nav/footer';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { Outlet, Route } from 'react-router-dom';
import {PullToRefresh, PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";

export class Layout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showHeader: true,
      showFooter: true
    }
  }
  componentDidMount() {}

  onRefresh() {
    window.location.reload();
  }

  render() {
    return (
        <PullToRefresh
        pullDownContent={<PullDownContent />}
        releaseContent={<ReleaseContent />}
        refreshContent={<RefreshContent />}
        pullDownThreshold={100}
        onRefresh={this.onRefresh}
        triggerHeight={400}
        backgroundColor='white'
        startInvisible={false}
        className="content-container"
        >
       <Header Heading="NOW.CULI&reg;" />
        <div className='content-padding'>
          <Outlet/>
        </div>
       <Footer />
       </PullToRefresh>
    );
  }
}

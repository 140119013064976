import React, { Component } from 'react'
import { Button, Input } from 'reactstrap'

export default class ProfileEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            profileText: this.props.value
        }

        this.onValueChange = this.onValueChange.bind(this);
    }

    onValueChange(evt) {
        const profileText = evt.target.value;
        this.setState({ profileText }, () => {
            if (this.props.onChange)
                this.props.onChange(profileText);
        });
    }

    render() {
        return (
            <div>
                <h4 className='text-center fs-5'>{this.props.ProfileHeader}</h4>
                <p className=''>{this.props.ProfileSubtext}</p>
                <div className='my-3'>
                    <Input type='textarea' rows={8} defaultValue={this.state.profileText} onChange={this.onValueChange} />
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import swal from 'sweetalert';
import { APIPath } from '../../helpers/APIHelper';
import { setAuthToken } from '../../helpers/authentication';
import { HasUpdatedWalkthrough, getUserEmail, setCreatedAt, setDevice, setUserGroup, setUserId } from '../../helpers/lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { SendOTP } from '../../helpers/TwilioHelper';
import { Header } from '../Nav/header';
import { CheckGroupCount } from '../../helpers/login-helper';

export default class OtpLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            generatedOTP: "",
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            otp5: "",
            loading: true
        }

        this.otp1Ref = React.createRef();
        this.otp2Ref = React.createRef();
        this.otp3Ref = React.createRef();
        this.otp4Ref = React.createRef();
        this.otp5Ref = React.createRef();

        this.onInputChange = this.onInputChange.bind(this);
        this.renderOTPInputs = this.renderOTPInputs.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        ThrobbleHelper.toggleThrobble(true, "Getting new OTP...");
        await this.UpdateOTP();
    }

    async UpdateOTP() {
        try {
            let email = getUserEmail();
            //todo: cater for user without org_ID
            const response = await fetch(APIPath() + "/global/login/UpdateUserOTP?email=" + email);
            if (response.ok) {
                this.setState({ loading: false });
                ThrobbleHelper.toggleThrobble(false);
                this.otp1Ref.current.focus();
            } else {
                swal("OTP", "Error generating OTP! Please try again!", "error");
                ThrobbleHelper.toggleThrobble(false);
            }
        } catch (e) {
            console.error(e);
        }
    }

    handleBackspaceEvent(evt, inputNo) {
        if (evt.keyCode === 8) {
            switch (inputNo) {
                case 2:
                    if (this.otp2Ref.current.value === "") {
                        this.otp1Ref.current.focus();
                    }
                    break;
                case 3:
                    if (this.otp3Ref.current.value === "") {
                        this.otp2Ref.current.focus();
                    }
                    break;
                case 4:
                    if (this.otp4Ref.current.value === "") {
                        this.otp3Ref.current.focus();
                    }
                    break;
                case 5:
                    if (this.otp5Ref.current.value === "") {
                        this.otp4Ref.current.focus();
                    }
                    break;
                default:
                    break;
            }
        }
    }



    onInputChange(inputNo, value) {
        switch (inputNo) {
            case 1:
                this.setState({ otp1: value });
                if (value.length > 0)
                    this.otp2Ref.current.focus();
                break;
            case 2:
                this.setState({ otp2: value });
                if (value.length > 0)
                    this.otp3Ref.current.focus();
                break;
            case 3:
                this.setState({ otp3: value });
                if (value.length > 0)
                    this.otp4Ref.current.focus();
                break;
            case 4:
                this.setState({ otp4: value });
                if (value.length > 0)
                    this.otp5Ref.current.focus();
                break;
            default:
                this.setState({ otp5: value }, () => {
                    if (value.length > 0)
                        this.validateOTP()
                });
                break;
        }
    }

    renderOTPInputs() {
        return (
            <div>
                <Header Heading="ENTER OTP" hideBack={true} />
                <div className="content-padding">
                    <div className="px-3 mt-4">
                        <p>Please enter the unique OTP messaged to you.</p>
                    </div>
                    <div className="row vw-100 px-3">
                        <div className="col text-center" style={{ maxWidth: "20%" }} >
                            <input type={"number"} className="form-control text-center" ref={this.otp1Ref} maxLength={1} onChange={e => this.onInputChange(1, e.target.value)} />
                        </div>
                        <div className="col text-center" style={{ maxWidth: "20%" }}  >
                            <input type={"number"} className="form-control text-center" ref={this.otp2Ref} maxLength={1} onKeyDown={e => this.handleBackspaceEvent(e, 2)} onChange={e => this.onInputChange(2, e.target.value)} />
                        </div>
                        <div className="col text-center" style={{ maxWidth: "20%" }}  >
                            <input type={"number"} className="form-control text-center" ref={this.otp3Ref} maxLength={1} onKeyDown={e => this.handleBackspaceEvent(e, 3)} onChange={e => this.onInputChange(3, e.target.value)} />
                        </div>
                        <div className="col text-center" style={{ maxWidth: "20%" }}  >
                            <input type={"number"} className="form-control text-center" ref={this.otp4Ref} maxLength={1} onKeyDown={e => this.handleBackspaceEvent(e, 4)} onChange={e => this.onInputChange(4, e.target.value)} />
                        </div>
                        <div className="col text-center" style={{ maxWidth: "20%" }}  >
                            <input type={"number"} className="form-control text-center" ref={this.otp5Ref} maxLength={1} onKeyDown={e => this.handleBackspaceEvent(e, 5)} onChange={e => this.onInputChange(5, e.target.value)} />
                        </div>
                    </div>
                </div>
                <ThrobbleHelper />
            </div>
        )
    }

    render() {
        let content = this.renderOTPInputs();
        return (content);
    }

    async validateOTP() {
        const { otp1, otp2, otp3, otp4, otp5 } = this.state;
        const EnteredOTP = otp1.concat(otp2).concat(otp3).concat(otp4).concat(otp5);

        if (EnteredOTP.length === 5) {
            ThrobbleHelper.toggleThrobble(true, "Checking system...");
            let email = getUserEmail();
            const response = await fetch(APIPath() + `/global/login/matchOTP?email=${email}&otp=${EnteredOTP}`);

            if (response.ok) {
                const data = await response.json();
                setUserId(data.Id)
                setUserGroup(data.OrganisationName);
                setAuthToken(data.LoginCookie);
                setCreatedAt(data.CreatedAt);
                setDevice(data.NewDeviceId);

                // if (process.env.NODE_ENV === "development")
                //     window.location.href = "/plain/walkthrough";


                if (data.HasDevice || data.CompletedWalthrough)
                    CheckGroupCount();
                else
                    window.location.href = "/plain/walkthrough";
            } else {
                ThrobbleHelper.toggleThrobble(false);
                alert("OTP is incorrect Try again");
            }


        }
        else {
            if (otp1 === "")
                this.otp1Ref.current.focus();
            else if (otp2 === "")
                this.otp2Ref.current.focus();
            else if (otp3 === "")
                this.otp3Ref.current.focus();
            else if (otp4 === "")
                this.otp4Ref.current.focus();
        }
    }
}

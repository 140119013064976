import { MenuItem, Select } from '@mui/material';
import React, { Component } from 'react'
import { alphabets } from '../../helpers/Alphabets';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';

export default class GlossaryOfAcronyms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chosenAlpha: "",
            acronyms: [],
            filteredAcronyms: [],
            glossarySource: [],
            filterAcro: ""
        }

    }

    componentDidMount() {
        this.getAcronyms();
        this.getGlossarySources();
    }

    populateAcronyms = (e) => {
        const search = e?.target?.value;
        if (search) {
            this.setState({
                filteredAcronyms: this.state.acronyms.filter(acronym => {
                    return acronym?.refAcronym?.toLowerCase().includes(search?.toLowerCase().trim().replaceAll(".", ""))
                }),chosenAlpha:""
            })
        } else if (e.length) {
            this.setState({
                filteredAcronyms: this.state.acronyms.filter(acronym => {
                    return acronym?.refAcronym?.toLowerCase()[0] === e.toLowerCase()
                }),filterAcro:""
            })
        } else {
            this.setState({
                filteredAcronyms: this.state.acronyms
            })
        }
    }
  
    filterAcrByCompany = async (e) => {
        const comp = e.target.value;
        await this.getAcronyms(comp)
        this.setState({ filterAcro: "", chosenAlpha: "" })
    }

    render() {
        return (
            <div data-role="content" className="mpt-0">
                <h1 id="refHeader">Glossary of Acronyms</h1>
                <label className='d-flex align-items-center px-3'><span className="orange h1"> &#x2022;</span>Please select an acronym category</label>
                <div className='px-3'>
                    <select className="selAcronyms text-center d-flex justify-content-center mb-3" data-mini="true" style={{ "width": "95%", textAlignLast: "center", height: "30px", lineHeight: "30px" }} onChange={this.filterAcrByCompany}>
                        <option value="">Company</option>
                        {this.state.glossarySource.map((item, index) => {
                            return <option value={item.abbr} key={index}>{item.Abbr}</option>
                        })}
                    </select>
                </div>
                <table style={{ "width": "90%" }} className='mx-3'>
                    <tbody>
                        <tr>
                            <td><input type="text" className=" text-center d-flex justify-content-center mb-3" placeholder="Enter acronym to search"
                                style={{ "width": "98%", textAlignLast: "center", height: "30px", lineHeight: "30px" }} onChange={(e) => {
                                    this.populateAcronyms(e);
                                    this.setState({ filterAcro: e.target.value })
                                }} 
                                value={this.state.filterAcro}/></td>
                        </tr>
                    </tbody>
                </table>
                <div className="d-flex justify-content-end mx-4">
                    <div className='alphas'>

                        <Select label="A-Z" className='bg-orange' value={this.state.chosenAlpha} displayEmpty onChange={(e) => {
                            this.setState({ chosenAlpha: e.target.value })
                            this.populateAcronyms(e.target.value)
                        }}>
                            <MenuItem value="">A-Z</MenuItem>
                            {alphabets.map((item, index) => <MenuItem value={item} key={index}>{item}</MenuItem>)}
                        </Select>
                        <div className="fButtonsContainer">
                        </div>
                    </div>
                </div>
                {
                    this.state.filteredAcronyms.length === 0 ? <p className="text-center">No results found!</p> : this.state.filteredAcronyms.map((item, index) => {
                        return <div key={index}>
                            <div className='mx-3 my-2'>
                                <h3 className='teal'>{item.refDescription + "(" + item.refAcronym + ")"}</h3>
                                <p>{item.refExplanation}</p>
                            </div>
                        </div>
                    })
                }

                <ul id="referenceContainerA" data-role="listview" data-autodividers="true"></ul>
            </div >

        )
    }
    async getAcronyms(comp) {
        var bearer = "Bearer " + getAuthToken();
        try {
            const response = await fetch(APIPath() + "/global/Acronym", {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },

            });
            if (response.ok) {
                let data = await response.json();
                if (comp?.length) {
                    data = data.filter(item => {
                        return item?.refCompany?.toLowerCase().includes(comp.toLowerCase().trim().replaceAll(".", "")) || item?.refSource?.toLowerCase().includes(comp.toLowerCase().trim().replaceAll(".", ""))
                    })
                }
                this.setState({ acronyms: data, filteredAcronyms: data })
            } else {
                console.log(response.status + ": " + response.statusText, response);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async getGlossarySources() {
        var bearer = "Bearer " + getAuthToken();
        try {
            const response = await fetch(APIPath() + "/global/GlossarySources", {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                },

            });
            if (response.ok) {
                let data = await response.json();
                this.setState({ glossarySource: data })
            } else {
                console.log(response.status + ": " + response.statusText, response);
            }
        } catch (e) {
            console.error(e);
        }
    }
}

import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { getBase64FromImageUrl } from '../../helpers/base64Helper';



export default class CameraAccess extends Component {

    static img = null;
    static video = null;
    static constraints = null;
    static imageCapture = null;
    static mediaStream = null;
    static deviceCameras = [];
    static parentObj = null;

    constructor(props) {
        super(props);
        CameraAccess.parentObj = this;
        this.state = {
            currentCamera: 0,
            showCameraSwitch: false,
            takenImage: false,
            imgData: "",
            showCamera: false
        }
        this.switchCamera = this.switchCamera.bind(this);
        this.takePhoto = this.takePhoto.bind(this);
        this.retakePhoto = this.retakePhoto.bind(this);
        this.processPhoto = this.processPhoto.bind(this);
    }

    static toggleShowCamera(showCamera) {

        if (!showCamera) {
            CameraAccess.mediaStream.getTracks().forEach(track => {
                track.stop();
            });
        }

        CameraAccess.parentObj.setState({ showCamera });
    }

    static checkPermission() {
        navigator.permissions.query({ name: "camera" }).then(result => {
            if (result.state === "granted") {
                CameraAccess.activateCamera();
            } else {
                let reqResult = navigator.permissions.request({ name: 'camera' });
                if (reqResult.state === "granted")
                    CameraAccess.activateCamera();
                else {
                    CameraAccess.toggleShowCamera(false);
                }
            }
        })
    }


    static activateCamera() {
        CameraAccess.video = document.getElementById('video-camera');
        CameraAccess.img = document.getElementById('img-preview');

        navigator.mediaDevices.enumerateDevices()
            .then(CameraAccess.gotDevices)
            .catch(err => {
                console.error("There was an error get devices: ", err);
            })
            .then(() => CameraAccess.getStream(0))
    }

    static gotDevices(devicesInfo) {
        for (let i = 0; i < devicesInfo.length; i++) {
            let deviceInput = devicesInfo[i];
            if (deviceInput.kind === 'videoinput' && !CameraAccess.deviceCameras.find(c => c.deviceId === deviceInput.deviceId))
                CameraAccess.deviceCameras.push({
                    cameraId: i,
                    deviceId: deviceInput.deviceId,
                });
        }
        CameraAccess.parentObj.setState({ showCameraSwitch: CameraAccess.deviceCameras.length > 1 });
    }

    static getStream(CameraIdx) {
        if (CameraAccess.mediaStream) {
            CameraAccess.mediaStream.getTracks().forEach(track => {
                track.stop();
            });
        }
        var videoSource = CameraAccess.deviceCameras[CameraIdx].deviceId;
        let constraints = {
            video: { deviceId: videoSource ? { exact: videoSource } : undefined }
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(CameraAccess.gotStream)
            .catch(error => {
                console.error('getUserMedia error: ', error);
            });
    }

    static gotStream(mediaStream) {
        CameraAccess.mediaStream = mediaStream;
        CameraAccess.video.srcObject = mediaStream;
        CameraAccess.imageCapture = new ImageCapture(mediaStream.getVideoTracks()[0]);
    }

    switchCamera() {
        let currentCamera = this.state.currentCamera;
        if (CameraAccess.deviceCameras.length > 1) {
            switch (currentCamera) {
                case 0:
                    currentCamera = 1;
                    break;
                default:
                    currentCamera = 0
                    break;
            }
            this.setState({ currentCamera }, CameraAccess.getStream(currentCamera));
        }
    }


    takePhoto() {
        let imgData = "";
        try {
            CameraAccess.imageCapture.takePhoto({})
                .then((blob) => {
                    blob = blob.slice(0, blob.size, "image/jpeg");
                    imgData = URL.createObjectURL(blob);
                    getBase64FromImageUrl(imgData, function (data) {
                        CameraAccess.parentObj.setState({ takenImage: true, imgData: data });
                    })
                    CameraAccess.img.src = imgData;
                })
        }
        catch (e) {
            console.log("Error taking photo:", e);
        }

    }

    retakePhoto() {
        CameraAccess.img.src = "";
        this.setState({ imgData: "", takenImage: false })
    }

    processPhoto() {
        if (this.props.PhotoTaken) {
            this.props.PhotoTaken(this.state.imgData);
            CameraAccess.toggleShowCamera(false);
        }
        this.setState({ takenImage: false })
    }

    render() {

        let { showCameraSwitch, showCamera, takenImage } = this.state;
        return (
            <Modal isOpen={showCamera} onOpened={() => CameraAccess.checkPermission()} fade={false} fullscreen={true} className='mdl-zIndex' zIndex={30000}>
                <div className="camera-container">
                    <div className="display">
                        <video id='video-camera' autoPlay playsInline hidden={takenImage} ></video>
                        <img id='img-preview' className='img-fluid' alt='camera' hidden={!takenImage} />
                        <div className="buttons">
                            <div className="text-center">
                                <div className="row" hidden={takenImage}>
                                    <div className={showCameraSwitch ? "col-4" : "col-6"}>
                                        <a type='button' onClick={() => CameraAccess.toggleShowCamera(false)} ><i className='fas fa-times-circle fa-2x text-white'></i></a>
                                    </div>
                                    <div className={showCameraSwitch ? "col-4" : "col-6"}>
                                        <a type='button' onClick={this.takePhoto}><i className='fas fa-circle-camera fa-2x text-white'></i></a>
                                    </div>
                                    <div className="col-4" hidden={!showCameraSwitch}>
                                        <a type='button' onClick={this.switchCamera}> <i className='fas fa-camera-rotate fa-2x text-white'></i></a>
                                    </div>
                                </div>

                                <div className="row pb-2" hidden={!takenImage}>
                                    <div className="col-6">
                                        <button type='button' onClick={this.retakePhoto} className='btn btn-light btn-sm fw-bold' >Retry</button>
                                    </div>
                                    <div className="col-6">
                                        <button type='button' onClick={this.processPhoto} className='btn btn-light btn-sm fw-bold'>Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

}
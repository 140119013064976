import { TextareaAutosize } from '@mui/material';
import React, { Component } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';
import { getUser, setUser } from '../../helpers/lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';

export default class UpdateProfile extends Component {

  static parentObj = null;
  constructor(props) {
    super(props);

    UpdateProfile.parentObj = this;
    this.state = {
      showUpdateProfile: false,
      Id: "",
      UserMobile: "",
      YearsActive: 0,
      MiniResumeId: "",
      JobProfile: "",
      ProfessionalProfile: "",
      ExperientialProfile: "",
      PersonalProfile: ""
    }

    this.SaveProfile = this.SaveProfile.bind(this);
  }

  componentDidMount() {
    let user = getUser();

    this.setState({
      Id: user.Id,
      UserMobile: user.UserMobile,
      YearsActive: user.YearsActive,
      MiniResumeId: user.MiniResumeId,
      JobProfile: user.JobProfile,
      ProfessionalProfile: user.ProfessionalProfile,
      ExperientialProfile: user.ExperientialProfile,
      PersonalProfile: user.PersonalProfile
    });
  }

  static toggleUpdateProfile(showUpdateProfile) {
    UpdateProfile.parentObj.setState({ showUpdateProfile });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.state.showUpdateProfile} className="modal-cyan" zIndex={30000}>
          <ModalHeader className="shadow-sm">
            MY PROFILE
          </ModalHeader>
          <ModalBody className="border-0 shadow-lg">
            <div className="row mb-3">
              <div className="col-6 mb-2 pe-1 ">
                <label className='form-label'><i className='fas fa-circle me-1 label-icon'></i>Mobile Number</label>
                <input type={"text"} className="form-control form-control-sm" defaultValue={this.state.UserMobile} onChange={e => this.setState({ UserMobile: e.target.value })} />
              </div>
              <div className="col-6 mb-2 ps-1">
                <label className='form-label '><i className='fas fa-circle me-1 label-icon'></i>Years of Experience</label>
                <input type={"number"} className="form-control form-control-sm" value={this.state.YearsActive} onChange={e => this.setState({ YearsActive: e.target.value })} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 w-100 mb-2">
                <div className="d-flex">
                  <i className='fas fa-circle me-1  small label-icon '></i>
                  <label className='form-label'><strong>Job Profile</strong>
                    <br />
                    Business Area/Division/Department/Current Position/Job Title/Specialisation Area/Work related Interests
                  </label>
                </div>
                <TextareaAutosize className='form-control px-1 py-0' minRows={2} defaultValue={this.state.JobProfile} onChange={e => this.setState({ JobProfile: e.target.value })} />
              </div>
              <div className="col-12 w-100 mb-4 mt-2">
                <div className="d-flex">
                  <i className='fas fa-circle me-1 small label-icon '></i>
                  <label className='form-label'><strong>Professional Profile</strong>
                    <br />
                    Academic qualification/Professional Registrations/Business Memberships
                  </label>
                </div>
                <TextareaAutosize className='form-control px-1 py-0' minRows={2} defaultValue={this.state.ProfessionalProfile} onChange={e => this.setState({ ProfessionalProfile: e.target.value })} />
              </div>
              <div className="col-12 w-100 mb-2">
                <div className="d-flex">
                  <i className='fas fa-circle me-1 small label-icon '></i>
                  <label className='form-label'><strong>Experiential Profile</strong>
                    <br />
                    Major Projects and Programs/Major client engagements
                  </label>
                </div>
                {/* <textarea className="form-control auto-resize" value={this.state.editExperiental} onChange={e => this.setState({ editExperiental: e.target.value })} /> */}
                <TextareaAutosize className='form-control px-1 py-0' minRows={2} defaultValue={this.state.ExperientialProfile} onChange={e => this.setState({ ExperientialProfile: e.target.value })}></TextareaAutosize>
              </div>
              <div className="col-12 w-100 mb-2 mt-3">
                <div className="d-flex">
                  <i className='fas fa-circle me-1 label-icon '></i>
                  <label className='form-label'><strong>Personal Profile</strong>
                    <br />
                    Special sport and personal interests/Career aspirations/Personal Vision
                  </label>
                </div>
                <TextareaAutosize className='form-control px-1 py-0' minRows={2} defaultValue={this.state.PersonalProfile} onChange={e => this.setState({ PersonalProfile: e.target.value })} />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button type='button' onClick={e => UpdateProfile.toggleUpdateProfile(false)} className='btn-alt'>CANCEL</button>
              </div>
              <div className="col-6 text-end">
                <button type='button' className='btn-action float-end' onClick={this.SaveProfile}>Save</button>
              </div>
            </div>
            <ThrobbleHelper />
          </ModalBody>
        </Modal>
      </>
    )
  }

  async SaveProfile(evt) {
    ThrobbleHelper.toggleThrobble(true, "Saving profile updates...");
    evt.stopPropagation();
    let data = {
      Id: this.state.Id,
      UserMobile: this.state.UserMobile,
      YearsActive: this.state.YearsActive,
      MiniResumeId: this.state.MiniResumeId,
      JobProfile: this.state.JobProfile,
      ProfessionalProfile: this.state.ProfessionalProfile,
      ExperientialProfile: this.state.ExperientialProfile,
      PersonalProfile: this.state.PersonalProfile
    }

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch(APIPath() + "/global/userprofile", {
        method: "PUT",
        headers: {
          "Authorization": bearer,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const user = await response.json();
        setUser(user);

        if (this.props.onProfileSave)
          this.props.onProfileSave();

        setTimeout(() => {
          UpdateProfile.toggleUpdateProfile(false);
          ThrobbleHelper.toggleThrobble(false);
        }, 500)

      } else {
        swal("Update Profile", "There was an error update your profile", "error")
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }
}

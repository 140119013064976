import React, { Component } from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getUser, getUserId, getWalkthroughDetails, setWalkthroughDetails } from '../../helpers/lookup';
import { getAuthToken } from '../../helpers/authentication';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { APIPath } from '../../helpers/APIHelper';
import { loader } from '../../helpers/loader';
import NumberInput from '../../helpers/NumberInput';

export default class ScreenYearsExperience extends Component {

    constructor(props) {
        super(props);

        this.state = {
            CurrentExperience: 0,
            YearsOfExperience: 1,
            loading: true,
            madeChanges: false
        }

        this.YearChanged = this.YearChanged.bind(this);
        this.ProcessStep = this.ProcessStep.bind(this);
        this.saveYearOfExperience = this.saveYearOfExperience.bind(this);
    }

    componentDidMount() {
        const WalkthroughDetails = getWalkthroughDetails();
        if (WalkthroughDetails)
            this.setState({ CurrentExperience: WalkthroughDetails.YearsOfExperience, YearsOfExperience: WalkthroughDetails.YearsOfExperience, loading: false });
        else
            this.setState({ loading: false });
    }

    YearChanged(YearsOfExperience) {
        const madeChanges = this.state.CurrentExperience === 0 || this.state.CurrentExperience !== YearsOfExperience;
        this.setState({ YearsOfExperience, madeChanges });
    }

    renderWalkthroughStep() {
        return (
            <Modal className='walkthrough-container' isOpen={true} fullscreen fade backdrop="static">
                <ModalHeader className='text-center d-inline'>{this.props.Header}</ModalHeader>
                <ModalBody className='text-justify pb-4'>
                    <h3 className='fs-2 fw-bolder'>YEARS OF EXPERIENCE </h3>
                    <p>Using the scroller, please add your years of relevant work experience - this helps us with pairing appropriate levels for mentoring. If you have just started working, just select 1.</p>
                    <div className="col-12 text-center mb-1">
                        <NumberInput className="text-end" value={this.state.YearsOfExperience} onChange={this.YearChanged} min={1} max={100} step={1} />
                    </div>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between'>
                    <Button color='secondary' outline onClick={() => this.ProcessStep("prev")}><i className='far fa-chevron-left me-2'></i>Prev</Button>
                    <Button color='success' onClick={() => this.ProcessStep("next")}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                </ModalFooter>
            </Modal>
        )
    }

    async ProcessStep(stepChangeValue) {
        if (stepChangeValue === "next" && this.state.madeChanges) {
            await this.saveYearOfExperience();
        }
        else if (this.props.onStepChange)
            this.props.onStepChange(stepChangeValue);
    }

    render() {
        const content = this.state.loading ? loader() : this.renderWalkthroughStep();
        return (
            content
        )
    }

    async saveYearOfExperience() {
        const bearer = "Bearer " + getAuthToken();
        ThrobbleHelper.toggleThrobble(true, "Saving your experience");
        try {
            const Id = getUserId();
            const data = { Id, UpdateType: 3, UpdateValue: this.state.YearsOfExperience.toString() };
            const response = await fetch(APIPath() + "/global/walkthrough/UpdateUserProfile", {
                method: "PUT",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });


            if (response.ok) {
                const result = await response.json();
                if (result) {
                    let WalkthroughDetails = getWalkthroughDetails();
                    WalkthroughDetails.YearsOfExperience = parseInt(result);
                    setWalkthroughDetails(WalkthroughDetails);
                }

                setTimeout(() => {
                    this.props.onStepChange("next", 3);
                }, 1500);
            }
        } catch (e) {

        }
    }
}

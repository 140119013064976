import React, { Component } from 'react';
import swal from 'sweetalert';
import { APIPath } from '../../helpers/APIHelper';
import { setAuthToken, setIsLoggedIn } from '../../helpers/authentication';
import { getSelectGroups, setSelectedGroup, setUserId, setUserGroup } from '../../helpers/lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { Header } from '../Nav/header';
import { LoadingSpinner } from '../../helpers/global';

export class SelectGroup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectId: "",
            groupList: [],
            loading: true
        }
    }

    componentDidMount() {
        this.loadData();
    }

    renderGroups(groups) {
        return (
            <div className="w-100 p-3">
                <p className="small mb-1">Your login is connected to a Multi User configuration and you can collaborate in
                    the groups shown below.</p>
                <p className="small text-wrap">
                    Ensure that you always post appropriate information – the App will assist you by reminding you what
                    group you are posting to – however it is your responsibility to ensure that you are collaborating in the
                    correct one.
                </p>

                <h2 className='mt-2'>Select group to engage</h2>
                <label className="small">
                    Always remember to log out and log in again when you wish to collaborate in another group.
                </label>
                {
                    groups.map((item, index) =>
                        <div key={index} className='w-100 my-2'>
                            <button type='button' className='btn-list w-100' onClick={() => this.onSelectGroup(item.UserId, item.OrganisationName)}>{item.OrganisationFullName} <i className='fas fa-chevrons-right text-dark-orange ms-2 float-end'></i></button></div>
                    )
                }
            </div>
        )
    }



    render() {

        let content = this.state.loading ? LoadingSpinner() : this.renderGroups(this.state.groupList);
        return (
            <div>
                <Header Heading="NOW.CULI&reg;" hideBack={true} />
                <div className='content-padding multigroup'>
                    {content}
                </div>
                <ThrobbleHelper />
            </div>
        );
    }

    loadData() {
        let groupList = getSelectGroups();
        this.setState({ groupList, loading: false });
    }

    async onSelectGroup(userId, orgName) {
        try {
            ThrobbleHelper.toggleThrobble(true, "Selecting group...");
            const response = await fetch(APIPath() + "/global/login/GetUserByUserId?userId=" + userId);
            if (response.ok) {
                let data = await response.json();
                setUserId(data.Id);
                setSelectedGroup(orgName);
                setUserGroup(orgName);
                setAuthToken(data.LoginCookie);
                setIsLoggedIn(true);
                window.location.href = "/";
            } else {
                swal("Select Group", "The was an error selecting your group", "error");
                ThrobbleHelper.toggleThrobble(false);
                console.log(response.status + ": " + response.statusText)
            }
        } catch (e) {
            console.error(e);
        }
    }
}

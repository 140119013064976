import React, { useState, createContext } from 'react'
export const MyContext = createContext("");
const Provider = ({ children }) => {
    const [title, setTitle] = useState("");
    const [companyPurpose, setCompanyPurpose] = useState({})
    const [outcome, setOutcome] = useState({})
    const [collaboration, setCollaboration] = useState({})
    const [trainingVideo, setTrainingVideo] = useState({})
    return (
        <MyContext.Provider value={{ title, setTitle, companyPurpose, setCompanyPurpose, outcome, setOutcome, collaboration, setCollaboration, trainingVideo, setTrainingVideo }}>
            {children}
        </MyContext.Provider>
    )
}

export default Provider
import React, { Component } from 'react'
import { MyContext } from '../../Provider'

export default class OutcomeDetails extends Component {
    render() {
        return (
            <div className="container-toolkit-article" style={{ height: "100vh", width: "100vw", overflowY: "scroll" }}>
                <h1>{this.context.companyPurpose?.Name}</h1>

                <h2>{this.context.title}</h2>
                <p className="plr-10" id="prName"></p>
                <div className='p-2'>
                    <div>{this.context?.outcome?.Outcome}</div>
                </div>
                {(this.context?.outcome?.Qual1 || this.context?.outcome?.Qual2) && <div className='my-2 p-2 dashed'>
                    <div className='d-flex align-items-center'>
                        <h5 className='teal' style={{ fontSize: "25px" }}>Metric</h5>
                        <h5 className='thin mx-2' style={{ paddingTop: "5px" }}>QUALITATIVE:</h5>
                    </div>
                    {this.context?.outcome?.Qual1}
                    <br />
                    <br />
                    {this.context?.outcome?.Qual2}
                </div>}

                {(this.context?.outcome?.Quan1 || this.context?.outcome?.Quan2) && <div className='my-2 p-2 dashed'>
                    <div className='d-flex align-items-center'>
                        <h5 className='teal' style={{ fontSize: "25px" }}>Metric</h5>
                        <h5 className='thin mx-2' style={{ paddingTop: "5px" }}>QUANTITATIVE:</h5>
                    </div>
                    {this.context?.outcome?.Quan1}
                    <br />
                    <br />
                    {this.context?.outcome?.Quan2}
                </div>}
            </div>
        )
    }
}
OutcomeDetails.contextType = MyContext

import $ from 'jquery';

var animDelay = 2000;

const fadeStep1 = (loginAnim) => {
    $("#first-line").fadeTo("slow", 1, "swing", function () {
        setTimeout(function () {
            $("#first-line").fadeTo("fast", 0);
            if (loginAnim)
                fadeStep2(loginAnim);
        }, animDelay);
    });
}

const fadeStep2 = loginAnim => {
    $("#second-line").fadeTo("slow", 1, "swing", function () {
        setTimeout(function () {
            $("#second-line").fadeTo("fast", 0);
            if (loginAnim)
                fadeStep3(loginAnim);
        }, animDelay);
    });
}

const fadeStep3 = (loginAnim) => {
    $("#third-line").fadeTo("slow", 1, "swing", function () {
        setTimeout(function () {
            $("#third-line").fadeTo("fast", 0);
            if (loginAnim)
                fadeStep1(loginAnim);
        }, animDelay);
    });
}

export default fadeStep1;
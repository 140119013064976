/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import { loader } from '../../helpers/loader';
import { getUserId, getWalkthroughDetails, setWalkthroughDetails } from '../../helpers/lookup';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';
import Select from 'react-select';
import { fieldRequired } from '../../helpers/validation';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import moment from 'moment/moment';

export default class ScreenProfessionalBody extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ProfBodyId: 0,
            IsRegistered: false,
            MembershipNum: "",
            StartDate: null,
            TargetDate: null,
            IsMentor: false,
            ProfBodies: [],
            ContentChanged: false,
            loading: true
        };



        this.ProcessStep = this.ProcessStep.bind(this);
        this.SaveProfessionalBody = this.SaveProfessionalBody.bind(this);
        this.SaveData = this.SaveData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    renderWalkthroughStep() {
        const ProfBodies = this.state.ProfBodies;
        const SelectedOption = ProfBodies.find(c => c.value === this.state.ProfBodyId);
        return (<>
            <Modal className='walkthrough-container' isOpen={true} fade fullscreen backdrop="static">
                <ModalHeader>
                    {this.props.Header}
                </ModalHeader>
                <ModalBody>
                    <h3 className='fs-2 fw-bolder'>PROFESSIONAL BODY</h3>
                    <p> From the dropdown list provided please select the main industry discipline, chartered institution or professional registration body under which you fall. If you work under two registration bodies (eg. ICE and PMI) you can add the additional body after you have registered.</p>

                    <div className="mb-3">
                        <Select isClearable={false} isSearchable={true} defaultValue={SelectedOption} options={ProfBodies} onChange={this.onBodyChange.bind(this)} />
                        <div id="errBody" className="ErrorText"></div>
                    </div>
                    <div className="col-12 mb-3" hidden={this.state.ProfBodyId == 0}>
                        <div className="row">
                            <div className='col-9 form-check-label'> {this.RenderLabel()}</div>
                            <div className="col-3 ">
                                <div className=" onoffswitch">
                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="isUrgent" tabIndex="0" checked={this.state.IsRegistered} data-role="none" onChange={(e) => this.setState({ IsRegistered: e.target.checked, ContentChanged: true })} />
                                    <label className="onoffswitch-label" htmlFor="isUrgent">
                                        <span className="onoffswitch-inner"></span>
                                        <span className="onoffswitch-switch"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div hidden={this.state.IsRegistered != 1}>
                        <div className="col-12 mb-3">
                            <label htmlFor="">Please add your Registration Number
                                <span id="errMembershipNum" className="ErrorText"></span>
                            </label>
                            <Input type='text' bsSize={"sm"} defaultValue={this.state.MembershipNum} onChange={e => this.setState({ MembershipNum: e.target.value, ContentChanged: true }, () => fieldRequired(this.state.MembershipNum, "errMembershipNum", "* Required"))} />
                        </div>
                        <div className="col-12 mb-3" hidden={this.state.IsMentor}>
                            <label>Date 1st registered as an Intern / Apprentice / Candidate / Article Clerk
                                <span id="errStartDate" className="ErrorText"></span>
                            </label>
                            <Input type='date' bsSize={"sm"} defaultValue={this.state.StartDate} onChange={e => this.setState({ StartDate: e.target.value, ContentChanged: true }, () => fieldRequired(this.state.StartDate, "errStartDate", "* Required"))} />
                        </div>
                        <div className="col-12 mb-3" hidden={this.state.IsMentor}>
                            <label> Target Date to register for Qualification / Certification / Professional Registration / Chartered Status
                                <span id="errTargetDate" className="ErrorText"></span>
                            </label>
                            <Input type='date' bsSize={"sm"} defaultValue={this.state.TargetDate} onChange={e => this.setState({ TargetDate: e.target.value, ContentChanged: true }, () => fieldRequired(this.state.TargetDate, "errTargetDate", "* Required"))} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between'>
                    <Button color='secondary' outline onClick={() => this.ProcessStep("prev")}><i className='far fa-chevron-left me-2'></i>Prev</Button>
                    <Button color='success' onClick={() => this.ProcessStep("next")}>Next <i className='far fa-chevron-right ms-2'></i></Button>
                </ModalFooter>
            </Modal>
        </>)
    }

    RenderLabel() {
        if (this.state.IsMentor)
            return "Are you a Certificated / Registered / Chartered Professional ";
        else
            return "Are you a registered Intern / Apprentice / Candidate / Article Clerk?";
    }

    onBodyChange(evt) {
        //this.setState({ ProfBodyId: evt.value, ContentChanged: true }, () => fieldRequired(this.state.ProfBodyId, "errBody", "Please select a professional body"));
        this.setState({ ProfBodyId: evt.value, ContentChanged: true });
    }

    async ProcessStep(stepChangeValue) {
        if (stepChangeValue === "next") {
            await this.SaveProfessionalBody();
            //this.props.onStepChange("next", 4);
        }
        else
            if (this.props.onStepChange)
                this.props.onStepChange(stepChangeValue, "4");
    }


    render() {
        const content = this.state.loading ? loader() : this.renderWalkthroughStep();
        return (
            content
        )
    }

    async loadData() {
        const bearer = "Bearer " + getAuthToken();
        try {
            const response = await fetch(APIPath() + "/global/professionalbodies", {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
                const data = await response.json();
                let ProfBodies = [{ value: 0, label: "- Select Professional Body -" }];
                data.map(item => ProfBodies.push({ value: item.Id, label: item.Fullname + " (" + item.Abbrev + ")" }))

                this.setState({ ProfBodies });
                const ProfBodyData = getWalkthroughDetails().ProfessionalBody;
                const UserType = getWalkthroughDetails().UserType;

                if (ProfBodyData) {
                    console.log(ProfBodyData);
                    this.setState({
                        ProfBodyId: ProfBodyData.ProfBodyId,
                        IsRegistered: ProfBodyData.IsRegistered,
                        MembershipNum: ProfBodyData.MembershipNum,
                        StartDate: moment(ProfBodyData.StartDate).format('yyyy-MM-DD'),
                        TargetDate: moment(ProfBodyData.TargetDate).format('yyyy-MM-DD'),
                        IsMentor: UserType.toLowerCase() != "mentee",
                        loading: false
                    });
                }

            } else {
                console.error(response.status, "::", response.statusText);
                if (response.status === 401)
                    window.location.href = "/";
                this.setState({ loading: false });
            }
        } catch (e) {
            console.error("Profession Bodies List ::", e);
            this.setState({ loading: false })
        }
    }

    async SaveProfessionalBody() {
        if (this.state.ContentChanged) {
            let isValid = true;

            // isValid &= fieldRequired(this.state.ProfBodyId, "errBody", "Please select a professional body");
            if (isValid && this.state.IsRegistered) {
                isValid &= fieldRequired(this.state.MembershipNum, "errMembershipNum", "* Required");
                isValid &= fieldRequired(this.state.StartDate, "errStartDate", "* Required");
                isValid &= fieldRequired(this.state.TargetDate, "errTargetDate", "* Required");
            }

            if (isValid)
                this.SaveData();
        }
        else
            this.props.onStepChange("next", 4);
    }


    async SaveData() {
        const bearer = "Bearer " + getAuthToken();
        const UserId = getUserId();
        ThrobbleHelper.toggleThrobble(true, "Saving professional body");
        let data = null;
        if (this.state.IsRegistered)
         data = { UserId, ProfBodyId: this.state.ProfBodyId, IsRegistered: this.state.IsRegistered, MembershipNum: this.state.MembershipNum, StartDate: this.state.StartDate, TargetDate: this.state.TargetDate };
        else
         data = { UserId, ProfBodyId: this.state.ProfBodyId, IsRegistered: this.state.IsRegistered, MembershipNum: null, StartDate: null, TargetDate: null }; 
        console.table(data);
        try {
            const response = await fetch(APIPath() + "/global/userprofbodies", {
                method: "PUT",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const data = await response.json();
                let WalkthroughDetail = getWalkthroughDetails();
                WalkthroughDetail.ProfessionalBody = data;
                setWalkthroughDetails(WalkthroughDetail);

                setTimeout(() => {
                    this.props.onStepChange("next", 4);
                }, 1500);
            } else {
                console.error(response.status, "::", response.statusText);
                if (response.status === 401)
                    window.location.href = "/";
                else
                    this.props.onStepChange("next", 4);
                this.setState({ loading: false });
            }
        } catch (e) {
            console.error("Professional Bodies Save ::", e);
            this.setState({ loading: false })
        }
    }
}

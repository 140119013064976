import React, { Component } from 'react'
import Iframe from 'react-iframe';
import { MyContext } from '../../Provider'
import Row from './Row';

export default class CollaborationToolsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            techOpen: false,
            howToUseOpen: false,
        };
    }
    render() {
        if (this.context.collaboration?.toolDescription === "6 Sources of Learning") {
            return (
                <div className="container-toolkit-article" style={{ height: "100vh", width: "100vw", overflowY: "scroll" }}>
                    <h1 className='my-2'>{this.context.collaboration?.toolDescription}</h1>
                    <div className='p-2 tex-center'>
                        6 Sources of Learning is a great knowledge discovery technique for Mentors and Mentees to use during the information gathering and packaging process of a Mentoring relationship. The multi-source learning approach facilitates an improved contextual understanding and assists in integrating meaningful learning across all the main learning styles.
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Iframe
                            className="video py-2"
                            height='300px'
                            url="//www.youtube.com/embed/viZA4nn2O58?feature=player_embedded"
                            display='block'
                            position='relative'
                            allowFullScreen
                            width='95%'
                            
                        />
                    </div>

                    <div>
                        <div className='my-1'>
                            <Row Title={"TECHNIQUE DESCRIPTION"} arrow={this.state.techOpen ? "up" : "down"} onClick={() => this.setState({ techOpen: !this.state.techOpen })} />
                            <div className={this.state.techOpen ? "m-2" : 'd-none'}>This tool is designed to give the Mentor a wide variety of learning options, tools and interventions in order to maximize the learning.</div>
                        </div>
                        <div className='mb-4'>
                            <Row Title={"HOW TO USE IT"} arrow={this.state.howToUseOpen ? "up" : "down"} onClick={() => this.setState({ howToUseOpen: !this.state.howToUseOpen })} />
                            <div className={this.state.howToUseOpen ? "m-2" : 'd-none'}>The respondent should assess which of the options is best suited to the learning need.  Ideally, the Mentor should suggest at least 3 different options in order to provide an optimal variation in the sources of learning, thereby increasing learning and memory retention.</div>
                        </div>
                        <h2>RESPONSE OPTIONS</h2>
                        <div>
                            <img src="images/Tools Main/6sources.gif" className="img-responsive" />
                        </div>
                        <br />

                        <table className='w-100'>
                        <tbody>
                            <tr className='dashed  px-2'>
                                <td>
                                    <h3 className='th px-2'>Experiential Learning</h3>
                                    <p className='px-2'>
                                        Work Observation, Secondment, Shadowing, Group Work, Team Projects, Job Coaching, Project Work, Simulations.
                                    </p>
                                </td>
                                <td><img className="img-responsive" src="images/tools/6-sources-of-learning/m4s6sources_0001s_0000_experiential-learning.png" /></td>
                            </tr>
                            <tr><td colSpan="2" className="spacer"></td></tr>
                            <tr className='dashed  px-2'>
                                <td>
                                    <h3 className='th px-2'>Learning Materials</h3>
                                    <p className='px-2'>
                                        Books, Journals, E-Learning, Websites, Libraries, Videos, CD’s, Webinars, Universities, Colleges, Publications, Research Documents.
                                    </p>
                                </td>
                                <td><img className="img-responsive" src="images/tools/6-sources-of-learning/m4s6sources_0001s_0004_learning-materials.png" /></td>
                            </tr>
                            <tr><td colSpan="2" className="spacer"></td></tr>
                            <tr className='dashed  px-2'>
                                <td>
                                    <h3 className='th px-2'>Learning Assignments</h3>
                                    <p className='px-2'>
                                        Developmental Projects, Fieldwork, Research, Knowledge Maps, Case Studies, Retrospective Analysis.
                                    </p>
                                </td>
                                <td><img className="img-responsive" src="images/tools/6-sources-of-learning/m4s6sources_0001s_0005_learning-assignments.png" /></td>
                            </tr>
                            <tr><td colSpan="2" className="spacer"></td></tr>
                            <tr className='dashed  px-2'>
                                <td>
                                    <h3 className='th px-2'>Company Specific</h3>
                                    <p className='px-2'>
                                        Policies, Standard Operating Procedures, Forums, Codes of Practice, SHERQ, QMS, ISO.
                                    </p>
                                </td>
                                <td><img className="img-responsive" src="images/tools/6-sources-of-learning/m4s6sources_0001s_0001_organisation-specific.png" /></td>
                            </tr>
                            <tr><td colSpan="2" className="spacer"></td></tr>
                            <tr className='dashed  px-2'>
                                <td>
                                    <h3 className='th px-2'>Classroom Training</h3>
                                    <p className='px-2'>
                                        Workshops, Conferences, Seminars, In-House Training.
                                    </p>
                                </td>
                                <td><img className="img-responsive" src="images/tools/6-sources-of-learning/m4s6sources_0001s_0002_classroom-training-.png" /></td>
                            </tr>
                            <tr><td colSpan="2" className="spacer"></td></tr>
                            <tr className='dashed  px-2'>
                                <td>
                                    <h3 className='th px-2'>People &amp; Benchmarks</h3>
                                    <p className='px-2'>
                                        SME’s, Knowledge Networks, Specialists, Best Practice, Institutions, Innovation Circles, Competitors, Communities of Practice.
                                    </p>
                                </td>
                                <td><img className="img-responsive" src="images/tools/6-sources-of-learning/m4s6sources_0001s_0003_people-and-benchmarks.png" alt="6-sources" /></td>
                            </tr>
                            <tr><td colSpan="2" className="spacer"></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else if (this.context.collaboration?.toolDescription === "Knowledge asset structuring model") {
            return (<div className="container-toolkit-article" style={{ height: "100vh", width: "100vw", overflowY: "scroll" }}>
                <h1 className='my-2'>{this.context.collaboration?.toolDescription}</h1>
                <div className='p-2 tex-center'>
                    Knowledge Asset Structuring is the perfect technique and framework for Mentors and Mentees to discover, gather, package, share and continuously improve knowledge acquisition and assimilation in organisations. The model provides a profound, yet simple methodology, to produce search able, high value knowledge assets, in a consistent and logically structured format.
                </div>
                <div className='d-flex justify-content-center'>
                    <Iframe
                        className="video py-2"
                        height='300px'
                        url="https://www.youtube.com/embed/uiL7lu7VE5I?feature=player_embedded"
                        display='block'
                        position='relative'
                        allowFullScreen
                        width='95%'
                    />
                </div>

                <div>
                    <div className='my-1'>
                        <Row Title={"TECHNIQUE DESCRIPTION"} arrow={this.state.techOpen ? "up" : "down"} onClick={() => this.setState({ techOpen: !this.state.techOpen })} />
                        <div className={this.state.techOpen ? "m-2" : 'd-none'}>This tool is designed to guide the Mentors and Mentees on how to develop dynamic and scalable Knowledge Assets by combining 4 essential elements or "fields of knowledge gathering and packaging" into a context rich, experiential knowledge model and an upgradeable reference tool.</div>
                    </div>
                    <div className='mb-4'>
                        <Row Title={"HOW TO USE IT"} arrow={this.state.howToUseOpen ? "up" : "down"} onClick={() => this.setState({ howToUseOpen: !this.state.howToUseOpen })} />
                        <div className={this.state.howToUseOpen ? "m-2" : 'd-none'}>The Mentors and Mentees usually select a Knowledge Area or a Knowledge Theme and then working as either individuals, partnerships or in collaborative groups, they develop a comprehensive view of either the entire Theme, or an important Sub-Theme, by focusing on gathering and packaging appropriate knowledge and the critical cross-field associations and connections, which provides a context rich and situationally versatile knowledge reference asset.</div>
                    </div>
                    <h2>RESPONSE OPTIONS</h2>
                    <div>
                        <img src="images/Tools Main/kasm.gif" className="img-responsive" />
                    </div>
                    <br />

                    <table className="story-table w-100">
                    <tbody>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Critical Thinking</h3>
                                <p className='px-2'>
                                    This is the situational knowledge and experiential wisdom of experts and other experienced people who have participated in projects or activities in the same Theme and have probably developed powerful, but unrecorded or “un-codified” knowledge in the form of rich tacit memories, pattern recognition, integrated perception, intuitive reasoning and fluid intelligence, which can be re-accessed, analyzed and scrutinized for learning and sharing through structured interviews, stories and retrospective analysis.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/knowledge-asset-structuring-model/m4s_0007s_0001_critical-thinking.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Best Practice</h3>
                                <p className='px-2'>
                                    This consists of all the “codified” conditions and constructs applicable to the Theme such as Codes of Practice, Specifications, Conditions of Contract, Law of Compliance, Statutory and Fiduciary Rules, HR and Financial Laws, Method Statements, Good Practice Guidelines, Policies and Procedures etc – they are essentially the written rules that govern the Theme and any associated Sub-Themes.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/knowledge-asset-structuring-model/m4s_0007s_0002_best-practice.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Lessons Learned</h3>
                                <p className='px-2'>
                                    These are the hard won experiences and lessons learned that will have evolved out of all the previous or current projects, events and activities that have ever been undertaken in (or involve) a particular Theme – they are rich in contextual wisdom – what did we do right?, what did we do wrong?, what must we do differently next time?
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/knowledge-asset-structuring-model/m4s_0007s_0000_lessons-learned.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Knowledge Network</h3>
                                <p className='px-2'>
                                    This field is based on Social Network Analysis and consists of all the experts and people – whether external, internal or even retired, who can add value by contributing their own expert or tacit knowledge, in either facilitated discussions or questions on the Theme, or are available for reference and expert guidance on future activities or projects in the same Theme – usually connected in a Social Network & Contact Structure. They can include large groups of both strong and weak ties who can all add considerable knowledge and value in different ways.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/knowledge-asset-structuring-model/m4s_0007s_0003_knowledge-networks.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        </tbody>
                    </table>
                </div>
            </div>)
        } else if (this.context.collaboration?.toolDescription === "Retrospective Analysis") {
            return (<div className="container-toolkit-article" style={{ height: "100vh", width: "100vw", overflowY: "scroll" }}>
                <h1 className='my-2'>{this.context.collaboration?.toolDescription}</h1>
                <div className='p-2 tex-center'>
                    The Retrospective Analysis is a great technique for Mentors and Mentees to gather, reflect on and critically analyse the contextual wisdom from an event or project. It is a simple questioning technique which if well researched and answered wisely, will provide Mentees with profound situational and contextual understanding.
                </div>
                <div className='d-flex justify-content-center'>
                    <Iframe
                        className="video py-2"
                        height='300px'
                        url="https://www.youtube.com/embed/e44jv90DWNk?feature=player_embedded"
                        display='block'
                        position='relative'
                        allowFullScreen
                        width='95%'
                    />
                </div>

                <div>
                    <div className='my-1'>
                        <Row Title={"TECHNIQUE DESCRIPTION"} arrow={this.state.techOpen ? "up" : "down"} onClick={() => this.setState({ techOpen: !this.state.techOpen })} />
                        <div className={this.state.techOpen ? "m-2" : 'd-none'}>This tool is designed to guide the Mentor in how to use a simple repetitive process to capture key knowledge and learnings about a particular event.</div>
                    </div>
                    <div className='mb-4'>
                        <Row Title={"HOW TO USE IT"} arrow={this.state.howToUseOpen ? "up" : "down"} onClick={() => this.setState({ howToUseOpen: !this.state.howToUseOpen })} />
                        <div className={this.state.howToUseOpen ? "m-2" : 'd-none'}>Choose a specific event that has occurred in your organization you do not want repeated or alternatively that you want to repeat consistently. Utilize the following model to record the key learnings as a record for others to benefit and learn from.</div>
                    </div>
                    <h2>RESPONSE OPTIONS</h2>
                    <div>
                        <img src="../images/Tools Main/retro-analysis.gif" className="img-responsive" />
                    </div>
                    <br />

                    <table className="story-table w-100">
                    <tbody>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>What did we do Right?</h3>
                                <p className='px-2'>
                                    Main 3 to 5 bullet points of things done well.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/retrospective-analysis/m4s_0002s_0006_what-did-we-do-right.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>How and Why did we get the Right things Right?</h3>
                                <p className='px-2'>
                                    Key assumptions, relationships and decisions that enabled things to go well.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/retrospective-analysis/m4s_0002s_0003_how-and-why-did-we-get-_the-right-things-right.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>What did we do Wrong?</h3>
                                <p className='px-2'>
                                    Main 3 to 5 bullet points of things done badly.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/retrospective-analysis/m4s_0002s_0005_what-did-we-do-wrong.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>How and Why did we get the Wrong things Wrong?</h3>
                                <p className='px-2'>
                                    Key assumptions, relationships and decisions that allowed things to go badly.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/retrospective-analysis/m4s_0002s_0007_how-and-why-did-we-get--the-wrong-things-wrong.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>What must we do Differently next time?</h3>
                                <p className='px-2'>
                                    3 to 5 bullets of key Lessons Learned as an organization.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/retrospective-analysis/m4s_0002s_0004_what-must-we-do-_differently-next-time.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>3 Most Important Lessons I have Learnt?</h3>
                                <p className='px-2'>
                                    3 to 5 bullets of key Lessons Learned as an individual.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/retrospective-analysis/m4s_0002s_0001_3-most-important-lessons-_i-have-learned.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Who must we Share this knowledge with?</h3>
                                <p className='px-2'>
                                    Key staff and other personnel to share this analysis and key Lessons Learned with.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/retrospective-analysis/m4s_0002s_0002_who-must-we-share-_this-knowledge-with.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>How must we Package, Distribute and Store this knowledge?</h3>
                                <p className='px-2'>
                                    Identify the optimum ways to ensure that these key Lessons Learned are effectively shared with the most appropriate people.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/retrospective-analysis/m4s_0002s_0000_how-must-we-package-distribute-_and-store-this-knowledge.png" alt="how must we package distribute" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        </tbody>
                    </table>

                </div>
            </div>)
        } else if (this.context.collaboration?.toolDescription.toLowerCase() === "6 roles of a mentor") {
            return (<div className="container-toolkit-article" style={{ height: "100vh", width: "100vw", overflowY: "scroll" }}>
                <h1 className='my-2'>{this.context.collaboration?.toolDescription}</h1>
                <div className='p-2 tex-center'>
                    6 Roles of the Mentor is a great tool or technique to really "change the nature of the conversation" in Mentoring relationships. It transforms the general tone from one of a management or expert default style...to one of a far more empowering and enabling Mentor and Mentee conversation...and unlocks whole new ways of interacting with colleagues and the learning world around them.
                </div>
                <div className='d-flex justify-content-center'>
                    <Iframe
                        className="video py-2"
                        url="https://www.youtube.com/embed/d-SDIUenVBM?feature=player_embedded"
                        display='block'
                        position='relative'
                        allowFullScreen
                        width='95%'
                        height='300px'
                        
                    />
                </div>

                <div>
                    <div className='my-1'>
                        <Row Title={"TECHNIQUE DESCRIPTION"} arrow={this.state.techOpen ? "up" : "down"} onClick={() => this.setState({ techOpen: !this.state.techOpen })} />
                        <div className={this.state.techOpen ? "m-2" : 'd-none'}>This tool is designed to guide the Mentor to use a wide variety of roles and conversation types during the mentoring relationship, which enhances the experiential and contextual learning.</div>
                    </div>
                    <div className='mb-4'>
                        <Row Title={"HOW TO USE IT"} arrow={this.state.howToUseOpen ? "up" : "down"} onClick={() => this.setState({ howToUseOpen: !this.state.howToUseOpen })} />
                        <div className={this.state.howToUseOpen ? "m-2" : 'd-none'}>The Mentor should assess which role or roles and conversation types are most relevant for the development of a particular competency or learning objective.  Only select the most appropriate roles needed - typically not more than 3 for each learning objective.</div>
                    </div>
                    <h2>RESPONSE OPTIONS</h2>
                    <div>

                        <img src="images/Tools Main/6roles.gif" className="img-responsive" />
                    </div>
                    <br />
                    <table className="story-table w-100 px-2">
                    <tbody>
                        <tr className='dashed px-2'>
                            <td className=''>
                                <h3 className='th px-2'>Teacher &amp; Tutor</h3>
                                <p className='px-2'>
                                    The Mentor, or another identified expert, provides
                                    the specific skills and knowledge transfer required
                                    and ensures effective knowledge acquisition and
                                    assimilation, through evaluating feedback from the
                                    Mentee on their learning experiences.
                                </p>
                            </td>
                            <td><img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0005_teacher-and-tutor.png" className="img-responsive" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed px-2'>
                            <td>
                                <h3 className='th px-2'>Challenger &amp; Champion</h3>
                                <p className='px-2'>
                                    The Mentor focuses on the Mentee’s job description and
                                    improving specific key performance areas and then uses
                                    appropriate success stories to enhance the Mentees
                                    internal company profile and thus their career potential.
                                </p>
                            </td>
                            <td>
                                <img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0003_challenger-and-champion.png" className="img-responsive" />
                            </td>
                        </tr>

                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed px-2'>
                            <td>
                                <h3 className='th px-2'>Guide &amp; Guru</h3>
                                <p className='px-2'>
                                    The Mentor provides big picture thinking & strategic insights on
                                    key stakeholders, competitor products or positioning,
                                    socio- political factors, the legal and statutory
                                    environment & any other aspects providing strategic
                                    organisational wisdom.
                                </p>
                            </td>
                            <td><img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0001_guide-and-guru.png" className="img-responsive" /></td>
                        </tr>

                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed px-2'>
                            <td>
                                <h3 className='th px-2'>Counselor &amp; Coach</h3>
                                <p className='px-2'>
                                    The Mentor acts as an objective listener on topics which
                                    may be confidential, sensitive or frustrating and plays
                                    the role of a trusted, non-judgemental change agent and
                                    possibly even offers some coaching skills to assist the
                                    Mentee grow.
                                </p>
                            </td>
                            <td><img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0000_councellor-and-coach.png" className="img-responsive" /></td>
                        </tr>

                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Role Model &amp; Rater</h3>
                                <p className='px-2'>
                                    The Mentor and Mentee identify experts and role models in the
                                    learning area required and the Mentee then benchmarks them self
                                    against the criteria identified (possibly using the M4S Expert
                                    Profiling technique) - and the Mentor then plays the role of the
                                    constructive observer and performance improvement assessor and rater.
                                </p>
                            </td>
                            <td><img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0002_role-model-and-rater.png" className="img-responsive" /></td>
                        </tr>

                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed px-2'>
                            <td>
                                <h3 className='th px-2'>Friend &amp; Facilitator</h3>
                                <p className='px-2'>
                                    The Mentor plays door opener to their own useful
                                    connections & networks and facilitates introductions
                                    to them, specifically for the purposes of gaining access to
                                    enhanced new learning opportunities & experiences.
                                </p>
                            </td>
                            <td><img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0004_friend-and-facilitator-.png" className="img-responsive" /></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>)
        } else if (this.context.collaboration?.toolDescription === "Experiential capability modeling") {
            return (<div className="container-toolkit-article" style={{ height: "100vh", width: "100vw", overflowY: "scroll" }}>
                <h1 className='my-2'>{this.context.collaboration?.toolDescription}</h1>
                <div className='p-2 tex-center'>
                    Experiential Capability Modelling is a great technique for Mentors and Mentees to build increased contextual learning and situational understanding, far beyond the classical 3 elements of most standard competency development models.
                </div>
                <div className='d-flex justify-content-center'>
                    <Iframe
                        className="video py-2"
                        height='300px'
                        url="https://www.youtube.com/embed/d-SDIUenVBM?feature=player_embedded"
                        display='block'
                        position='relative'
                        allowFullScreen
                        width='95%'
                    />
                </div>
                <div>
                    <div className='my-1'>
                        <Row Title={"TECHNIQUE DESCRIPTION"} arrow={this.state.techOpen ? "up" : "down"} onClick={() => this.setState({ techOpen: !this.state.techOpen })} />
                        <div className={this.state.techOpen ? "m-2" : 'd-none'}>This tool is designed to guide the Mentor on how to move from a historical focus on training and competency development, to a more contextually rich and sustainable capability development focus.</div>
                    </div>
                    <div className='mb-4'>
                        <Row Title={"HOW TO USE IT"} arrow={this.state.howToUseOpen ? "up" : "down"} onClick={() => this.setState({ howToUseOpen: !this.state.howToUseOpen })} />
                        <div className={this.state.howToUseOpen ? "m-2" : 'd-none'}>The classical 3 elements of standard competency development models are enhanced with the experiential and episodic learning opportunities provided by engaging in reviewing appropriate lessons learned and the knowledge network of subject matter experts and other experienced people available to the Mentee.</div>
                    </div>
                    <h2>RESPONSE OPTIONS</h2>
                    <div>
                        <img src="images/Tools Main/ecm.gif" className="img-responsive" />
                    </div>
                    <br />

                    <table className="story-table w-100">
                    <tbody>
                        <tr className='dashed'>
                            <td>
                                <h3 className='th px-2'>Technical &amp; Functional Skills and Abilities</h3>
                                <p className='px-2'>
                                    These are typically proficiencies, talents, abilities or capacities which are needed to accomplish tasks and activities and can be acquired through training and experience, which facilitates the ability to achieve new levels of performance.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/experiential-capability-modelling/m4s_0003s_0000_technical-and-functional.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Organisational Knowledge and Know-How</h3>
                                <p className='px-2'>
                                    This is “essential information in context” and provides a more confident understanding of a subject area, which enhances the potential to use or apply new skills and abilities for a specific purpose or outcome.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/experiential-capability-modelling/m4s_0003s_0002_organisation-_knowledge.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Behaviours, Values and Attitude</h3>
                                <p className='px-2'>
                                    These are generally natural, learned or adopted attributes, which usually dictate how we feel and respond, both behaviorally and emotionally, to other people, or some form of external or environmental change or stimulus.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/experiential-capability-modelling/m4s_0003s_0004_behaviour-values.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Experiences and Lessons Learned</h3>
                                <p className='px-2'>
                                    This is the source of essential contextual knowledge and experiential wisdom, which is gained as a result of exposure to, or participation in, an event or happening, or through hearing or watching or reading about these events, leading to profound insights and a more confident understanding of the world, which cannot be gained by training alone.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/experiential-capability-modelling/m4s_0003s_0001_experiences.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Key Contacts, Relationships and Networks</h3>
                                <p className='px-2'>
                                    This is the knowledge network of people and groups, with whom you have or can, develop mutual respect, trust and a level of confidentiality and where a clear and unambiguous understanding exists of what each party offers the other and what, if any, commitments or expectations may exist. As the wise old saying goes, “It’s not WHAT you know, it’s WHO you know!”.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/experiential-capability-modelling/m4s_0003s_0003_contacts-network.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        </tbody>
                    </table>
                </div>
            </div>)
        } else if (this.context.collaboration?.toolDescription === "Intentional story telling") {
            return (<div className="container-toolkit-article" style={{ height: "100vh", width: "100vw", overflowY: "scroll" }}>
                <h1 className='my-2'>{this.context.collaboration?.toolDescription}</h1>
                <div className='p-2 tex-center'>
                    Intentional Story Telling is the perfect technique for Mentors, Subject Matter Experts and other Experienced People to share their knowledge and "experiential wisdom" with a group of young Mentees. This enhances rich associative learning for the Mentees through the brain sensitisation dynamics of group knowledge and experience sharing. It also provides the perfect interactive feedback opportunity for the Mentor or "Story Teller" to evaluate the quality of their story telling, through post-story group discussion and thereby to validating the appropriate transfer of contextual understanding and new knowledge acquisition and assimilation.
                </div>
                <div className='d-flex justify-content-center'>
                    <Iframe
                        className="video py-2"
                        height='300px'
                        url="https://www.youtube.com/embed/gzy8-6605eM?feature=player_embedded"
                        display='block'
                        position='relative'
                        allowFullScreen
                        width='95%'
                    />
                </div>
                <div>
                    <div className='my-1'>
                        <Row Title={"TECHNIQUE DESCRIPTION"} arrow={this.state.techOpen ? "up" : "down"} onClick={() => this.setState({ techOpen: !this.state.techOpen })} />
                        <div className={this.state.techOpen ? "m-2" : 'd-none'}>This tool is designed to guide the Mentor in how to prepare and deliver an 8 to 10 minute only, episodic learning enhanced and rich knowledge transfer story. This will engage both effective cognitive and emotional learning, through engaged story listening.</div>
                    </div>
                    <div className='mb-4'>
                        <Row Title={"HOW TO USE IT"} arrow={this.state.howToUseOpen ? "up" : "down"} onClick={() => this.setState({ howToUseOpen: !this.state.howToUseOpen })} />
                        <div className={this.state.howToUseOpen ? "m-2" : 'd-none'}>The Mentor should think of a significant event or personal experience, whether positive or negative, which had a serious impact on a community, an industry, an organization or their own lives and prepare a rich knowledge sharing story for their Mentee or ideally, a whole group of Mentees & learners.</div>
                    </div>
                    <h2>RESPONSE OPTIONS</h2>
                    <div>
                        <img src="images/Tools Main/intstorytelling.gif" className="img-responsive" />
                    </div>
                    <br />


                    <table className="story-table w-100">
                    <tbody>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Central Message</h3>
                                <p className='px-2'>
                                    Clarify the central purpose & point of the story right up front – do not deviate – ensure focus by stating key benefits to be gained from the story.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/intentional-story-telling/m4s_0005s_0009_central-message.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>

                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Consider Learning Styles</h3>
                                <p className='px-2'>
                                    Appeal to all styles - Audio, Visual, Read-Write, Kin-aesthetic / Feeling, Watching, Thinking, Doing / Activist, Reflector, Theorist, Pragmatist.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/intentional-story-telling/m4s_0005s_0000_consider-learning-styles.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Logical Story Structure</h3>
                                <p className='px-2'>
                                    Provide a short impactful beginning, a logical & well-structured middle and a powerful end.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/intentional-story-telling/m4s_0005s_0003_logical-story-structure-.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Metaphors &amp; Analogies</h3>
                                <p className='px-2'>
                                    Use relevant comparisons, strong associations and common figures of speech.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/intentional-story-telling/m4s_0005s_0007_metaphors.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Connection to Listeners</h3>
                                <p className='px-2'>
                                    Ensure the listeners are engaged through constant involvement via planned questions to targeted individuals who can add value.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/intentional-story-telling/m4s_0005s_0001_connection-to-listeners.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Emotional Impact</h3>
                                <p className='px-2'>
                                    Use powerful and triggering narrative, with changes in inflection and emphasis to vary your style and create or evoke an emotional memory.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/intentional-story-telling/m4s_0005s_0002_emotional-impact.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Influences Thinking</h3>
                                <p className='px-2'>
                                    Identify, reinforce & magnify key story elements to achieve a positive  influence and change in current thinking.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/intentional-story-telling/m4s_0005s_0004_influences-thinking.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Leads to Action </h3>
                                <p className='px-2'>
                                    The ending  must lead to positive stated actions – which can be assessed through targeted questioning of listeners, or suggested actions.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/intentional-story-telling/m4s_0005s_0006_leads-to-action.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Relative Value Concept</h3>
                                <p className='px-2'>
                                    If appropriate, include the bigger picture & any potential knock-on impacts & effects, whether financial, operational, reputational, morale etc.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/intentional-story-telling/m4s_0005s_0008_relative-value-concepts.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Key Lessons Learned</h3>
                                <p className='px-2'>
                                    End the story by summarizing the most important lessons learned and experiential knowledge gained – invite listener engagement.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/intentional-story-telling/m4s_0005s_0005_key-lessons.png" /></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>)
        } else if (this.context.collaboration?.toolDescription === "SCARF Model") {
            return (<div className="container-toolkit-article" style={{ height: "100vh", width: "100vw", overflowY: "scroll" }}>
                <h1 className='my-2'>{this.context.collaboration?.toolDescription}</h1>
                <div className='p-2 tex-center'>
                    The SCARF Model developed by Dr. David Rock is an excellent technique for Mentors to help Mentees develop Emotional Maturity in antagonistic situations and environments. This technique will be specifically useful to young Mentees regularly immersed in highly stressful, competitive or acrimonious situations, where the ability to restrain one's natural Threat Response will always provide a far higher level of effective communication, cooperation and collaboration. This will always produce a better outcome.
                </div>
                <div className='d-flex justify-content-center'>
                    <Iframe
                        className="video py-2"
                        height='300px'
                        url="https://www.youtube.com/embed/bY6RabDEQ9c?feature=player_embedded"
                        display='block'
                        position='relative'
                        allowFullScreen
                        width='95%'
                    />
                </div>
                <div>
                    <div className='my-1'>
                        <Row Title={"TECHNIQUE DESCRIPTION"} arrow={this.state.techOpen ? "up" : "down"} onClick={() => this.setState({ techOpen: !this.state.techOpen })} />
                        <div className={this.state.techOpen ? "m-2" : 'd-none'}>SCARF is an acronym for a proven brain based process for improving our ability to communicate and collaborate with others, using deliberate emotional maturity, thus reducing potential conflict and negative emotional and behavioural consequences.</div>
                    </div>
                    <div className='mb-4'>
                        <Row Title={"HOW TO USE IT"} arrow={this.state.howToUseOpen ? "up" : "down"} onClick={() => this.setState({ howToUseOpen: !this.state.howToUseOpen })} />
                        <div className={this.state.howToUseOpen ? "m-2" : 'd-none'}>Identify an incident / interaction / conversation / meeting that you believe went badly and identify how each of the 5 SCARF Social Domains were being negatively impacted, thus causing either you or the recipient to feel threatened. Then describe a scenario in how the situation could, or should, have been handled differently with a positive approach to each Domain.</div>
                    </div>
                    <h2>RESPONSE OPTIONS</h2>
                    <div>
                        <img src="images/Tools Main/scarf.gif" className="img-responsive" />
                    </div>
                    <br />

                    <table className="story-table w-100">
                    <tbody>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Status</h3>
                                <p className='px-2'>
                                    Our perception of our relative importance and status amongst peers, seniors and others.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/scarf-modelling/m4s_0006s_0000_status.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Certainty</h3>
                                <p className='px-2'>
                                    Our feeling of need to be able to predict outcomes, and understand or future.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/scarf-modelling/m4s_0006s_0002_certainty.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Autonomy</h3>
                                <p className='px-2'>
                                    Our sense of need to have control over events that impact upon us and have a say in our future.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/scarf-modelling/m4s_0006s_0003_autonomy.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Relatedness</h3>
                                <p className='px-2'>
                                    Our sense of need to enjoy safety, respect & comfort amongst others.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/scarf-modelling/m4s_0006s_0004_-relatedness.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className='dashed  px-2'>
                            <td>
                                <h3 className='th px-2'>Fairness</h3>
                                <p className='px-2'>
                                    Our perception of need for honest and fair exchange between people.
                                </p>
                            </td>
                            <td><img className="img-responsive" src="images/tools/scarf-modelling/m4s_0006s_0005_fairness.png" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        </tbody>
                    </table>
                </div>
            </div>)
        } else if (this.context.collaboration?.toolDescription === "Knowledge Mapping") {
            return (<div className="container-toolkit-article" style={{ height: "100vh", width: "100vw", overflowY: "scroll" }}>
                <h1 className='my-2'>{this.context.collaboration?.toolDescription}</h1>
                <div className='p-2 tex-center'>
                    6 Roles of the Mentor is a great tool or technique to really "change the nature of the conversation" in Mentoring relationships. It transforms the general tone from one of a management or expert default style...to one of a far more empowering and enabling Mentor and Mentee conversation...and unlocks whole new ways of interacting with colleagues and the learning world around them.
                </div>
                <div className='d-flex justify-content-center'>
                    <Iframe
                        className="video py-2"
                        height='300px'
                        url="https://www.youtube.com/embed/d-SDIUenVBM?feature=player_embedded"
                        display='block'
                        position='relative'
                        allowFullScreen
                        width='95%'
                    />
                </div>
                <div>
                    <div className='my-1'>
                        <Row Title={"TECHNIQUE DESCRIPTION"} arrow={this.state.techOpen ? "up" : "down"} onClick={() => this.setState({ techOpen: !this.state.techOpen })} />
                        <div className={this.state.techOpen ? "m-2" : 'd-none'}>This tool is designed to guide the Mentor to use a wide variety of roles and conversation types during the mentoring relationship, which enhances the experiential and contextual learning.</div>
                    </div>
                    <div className='mb-4'>
                        <Row Title={"HOW TO USE IT"} arrow={this.state.howToUseOpen ? "up" : "down"} onClick={() => this.setState({ howToUseOpen: !this.state.howToUseOpen })} />
                        <div className={this.state.howToUseOpen ? "m-2" : 'd-none'}>The Mentor should assess which role or roles and conversation types are most relevant for the development of a particular competency or learning objective.  Only select the most appropriate roles needed - typically not more than 3 for each learning objective.</div>
                    </div>
                    <h2>RESPONSE OPTIONS</h2>
                    <div>

                        <img src="images/Tools Main/6roles.gif" className="img-responsive" />
                    </div>
                    <br />
                    <table className="story-table w-100 px-2">
                    <tbody>
                        <tr className="dashed  px-2">
                            <td>
                                <h3 className='th px-2'>Teacher &amp; Tutor</h3>
                                <p className='px-2'>
                                    The Mentor, or another identified expert, provides
                                    the specific skills and knowledge transfer required
                                    and ensures effective knowledge acquisition and
                                    assimilation, through evaluating feedback from the
                                    Mentee on their learning experiences.
                                </p>
                            </td>
                            <td><img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0005_teacher-and-tutor.png" className="img-responsive" /></td>
                        </tr>
                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className="dashed  px-2">
                            <td>
                                <h3 className='th px-2'>Challenger &amp; Champion</h3>
                                <p className='px-2'>
                                    The Mentor focuses on the Mentee’s job description and
                                    improving specific key performance areas and then uses
                                    appropriate success stories to enhance the Mentees
                                    internal company profile and thus their career potential.
                                </p>
                            </td>
                            <td>
                                <img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0003_challenger-and-champion.png" className="img-responsive" />
                            </td>
                        </tr>

                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className="dashed  px-2">
                            <td>
                                <h3 className='th px-2'>Guide &amp; Guru</h3>
                                <p className='px-2'>
                                    The Mentor provides big picture thinking & strategic insights on
                                    key stakeholders, competitor products or positioning,
                                    socio- political factors, the legal and statutory
                                    environment & any other aspects providing strategic
                                    organisational wisdom.
                                </p>
                            </td>
                            <td><img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0001_guide-and-guru.png" className="img-responsive" /></td>
                        </tr>

                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className="dashed  px-2">
                            <td>
                                <h3 className='th px-2'>Counselor &amp; Coach</h3>
                                <p className='px-2'>
                                    The Mentor acts as an objective listener on topics which
                                    may be confidential, sensitive or frustrating and plays
                                    the role of a trusted, non-judgemental change agent and
                                    possibly even offers some coaching skills to assist the
                                    Mentee grow.
                                </p>
                            </td>
                            <td><img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0000_councellor-and-coach.png" className="img-responsive" /></td>
                        </tr>

                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className="dashed  px-2">
                            <td>
                                <h3 className='th px-2'>Role Model &amp; Rater</h3>
                                <p className='px-2'>
                                    The Mentor and Mentee identify experts and role models in the
                                    learning area required and the Mentee then benchmarks them self
                                    against the criteria identified (possibly using the M4S Expert
                                    Profiling technique) - and the Mentor then plays the role of the
                                    constructive observer and performance improvement assessor and rater.
                                </p>
                            </td>
                            <td><img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0002_role-model-and-rater.png" className="img-responsive" /></td>
                        </tr>

                        <tr><td colSpan="2" className="spacer"></td></tr>
                        <tr className="dashed  px-2">
                            <td>
                                <h3 className='th px-2'>Friend &amp; Facilitator</h3>
                                <p className='px-2'>
                                    The Mentor plays door opener to their own useful
                                    connections & networks and facilitates introductions
                                    to them, specifically for the purposes of gaining access to
                                    enhanced new learning opportunities & experiences.
                                </p>
                            </td>
                            <td><img src="images/tools/6-roles-of-a-mentor/m4s_0000s_0004_friend-and-facilitator-.png" className="img-responsive" /></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>)
        }
    }
}

CollaborationToolsDetails.contextType = MyContext;

import React, { Component } from "react";
import {
  GetAvatar,
  GetCurrentLevel,
  GetDiamondIcon,
  GetFrameClass,
  GetNextLevel,
  GetUserRanking,
  LoadingSpinner
} from "../../helpers/global";
import { getUser, getUserId, setUser } from "../../helpers/lookup";
import { UserLevels } from "./UserLevels";
import { APIPath } from "../../helpers/APIHelper";
import { getAuthToken, setIsLoggedIn } from "../../helpers/authentication";
import CameraAccess from "../Attachments/CameraAccess";
import PhotoCropper from "../Attachments/photo-cropper";
import { getBase64FromFile } from "../../helpers/base64Helper";
import MiniCV from "./mini-cv";
import UpdateProfile from "./UpdateProfile";
import {
  isMobileSafari,
  isIOS,
  isIOS13,
  isIPhone13,
} from "react-device-detect";

export default class ProfileInfo extends Component {
  static parentObj = null;
  constructor(props) {
    super(props);

    ProfileInfo.parentObj = this;
    this.state = {
      userId: "",
      avatar: "",
      username: "",
      levelNo: 0,
      level: "",
      nextLevel: "",
      cycleDiamonds: 0,
      score: 0,
      country: "",
      userType: "",
      division: "",
      mobile: "",
      region: "",
      experience: "",
      team: "",
      email: "",
      miniResumeId: "",
      jobProfile: "",
      jobTitle: "",
      professionalProfile: "",
      experientialProfile: "",
      personalProfile: "",
      editMobile: "",
      editYearsOfExperience: 0,
      editJobProfile: "",
      editProfessional: "",
      editExperiental: "",
      editPersonal: "",
      loading: true,
      showUpdateProfile: false,
      editImage: false,
      imageData: "",
      filename: "",
      primaryBody: "",
      showNewAvatar: false,
      isViewing: false,
      isAppleDevice: false,
    };

    this.avatarFile = React.createRef("");
    this.toggleEditImage = this.toggleEditImage.bind(this);
    this.openFileBrowser = this.openFileBrowser.bind(this);
    this.UpdateProfile = this.UpdateProfile.bind(this);
    this.PhotoTaken = this.PhotoTaken.bind(this);
    this.PhotoSelected = this.PhotoSelected.bind(this);
    this.PhotoCropped = this.PhotoCropped.bind(this);
    this.ConfirmImage = this.ConfirmImage.bind(this);
  }

  toggleEditImage() {
    if (this.props.viewing) return false;
    if (this.state.showNewAvatar) {
      PhotoCropper.toggleShowCropper(true);
    } else this.setState({ editImage: !this.state.editImage });
  }

  updateProfile() {
    window.location.href = "/updateprofile";
  }

  componentDidMount() {
    this.loadData();
    setIsLoggedIn(true);
    this.checkIsAppleDevice();
  }

  checkIsAppleDevice() {
    if (isMobileSafari || isIOS || isIOS13 || isIPhone13) {
      this.setState({
        isAppleDevice: true,
      });
    }
  }

  openCamera() {
    CameraAccess.toggleShowCamera(true);
  }

  PhotoTaken(imageData) {
    let filename = "camera_image.jpg";
    this.setState({ showNewAvatar: false, filename, imageData });
    PhotoCropper.toggleShowCropper(true);
  }

  openFileBrowser() {
    this.avatarFile.current.click();
  }

  PhotoSelected() {
    let file = this.avatarFile.current.files[0];
    getBase64FromFile(file, (imageData) => {
      this.setState({ showNewAvatar: false, imageData, filename: file.name });
      PhotoCropper.toggleShowCropper(true);
    });
  }

  PhotoCropped(imageData) {
    this.setState({ imageData, showNewAvatar: true });
  }

  renderProfileInfo(data) {
    return (
      <>
        <div className="container fw-light full-profile">
          <div className="row">
            <div className="col pb-3" style={{ maxWidth: "140px" }}>
              <div
                className={
                  "avatar-container mx-auto large " + GetFrameClass(data.score)
                }
                onClick={this.toggleEditImage}
              >
                <img
                  src={
                    data.showNewAvatar
                      ? GetAvatar(data.imageData)
                      : GetAvatar(data.avatar)
                  }
                  className="img-fluid"
                  alt={data.username}
                />
              </div>
              <div hidden={data.editImage || data.isViewing}>
                <p className="tapText">
                  Tap Image to
                  <br /> Change
                </p>
                <p className="tapText fw-semibold">
                  Please ensure you use a portrait Image
                </p>
              </div>
            </div>
            <div className="col text-center">
              <div className="mb-2 username">{data.username}</div>
              <div className="mb-1 small " hidden={data.isViewing}>
                <div className="mb-2 pb-1">
                  You are currently on:{" "}
                  <span className="fw-bold">Level {GetUserRanking(data.score)}</span>
                  <br />
                  {/* {data.level ? data.level : "1. Erudite Explorer"} */}
                  Level:{GetCurrentLevel(data.score)}
                </div>
                <div className="mb-2 pb-1">
                  <span className="fw-bold p-5">Next Level:</span>
                  <br />
                  {data.nextLevel}
                </div>
              </div>

              <img
                src={GetDiamondIcon(data.cycleDiamonds)}
                className="img-fluid mw-25"
                alt={"Diamonds Awarded"}
                style={{ maxWidth: "18%" }}
              />
              <br />
              <span className="fw-bold">Diamonds Awarded</span>
              <br />
              <span className="fw-normal">
                This cycle: {data.cycleDiamonds}
              </span>
            </div>
          </div>

          <div className="row pb-2" hidden={!data.editImage}>
            {
              data.isAppleDevice ? "" : (
                <div className="col-6 my-1 pe-1">
                  <button
                    type="button"
                    className="btn-icon w-100"
                    style={{ border: "1px solid #f1592a" }}
                    onClick={this.openCamera}
                  >
                    Take Photo <i className="fas fa-camera ms-2"></i>
                  </button>
                </div>
              )
            }
            <div className="col-6 my-1 ps-1">
              <button
                type="button"
                className="btn-icon  w-100"
                style={{ border: "1px solid #f1592a" }}
                onClick={this.openFileBrowser}
              >
                {data.isAppleDevice ? "Take/Load Image" : "Load Image"} <i className="fas fa-image ms-2"></i>
              </button>
              <input
                type="file"
                className="d-none"
                accept="image/*"
                ref={this.avatarFile}
                onChange={this.PhotoSelected}
              />
            </div>
            <div className="col-12">
              <button
                type="button"
                className="btn-action fw-normal"
                onClick={this.ConfirmImage}
              >
                CONFIRM IMAGE
              </button>
            </div>
          </div>

          <div className="row user-info">
            <div className="col-12">
              <UserLevels Score={data.score} hidePoints={true} />
            </div>
            <div className="col-6 mb-1">
              <b className="fw-bold me-2">{data.userId === getUserId() && "MY"} POINTS:</b>
              {data.score}
            </div>
            <div className="col-6 mb-1">
              <b className="fw-bold me-2">EXPERIENCE:</b>
              {data.experience} years
            </div>
            <div className="col-6 mb-1">
              <b className="fw-bold me-2">Job Title:</b>
              {data.jobTitle}
            </div>
            <div className="col-6 mb-1">
              <b className="fw-bold me-2">Professional Body:</b>
              {this.state.primaryBody !== "" ? this.state.primaryBody : "Not specified"}
            </div>
            <div className="col-6 mb-1">
              <b className="fw-bold me-2">COUNTRY:</b>
              {data.country}
            </div>
            {/* <div className="col-6 mb-1">
              <b className="fw-bold me-2">USER TYPE:</b>
              {data.userType}
            </div> */}
            {/* <div className="col-6 mb-1">
              <b className="fw-bold me-2">DIVISION:</b>
              {data.division}
            </div> */}
            <div className="col-6 mb-1">
              <b className="fw-bold me-2">MOBILE:</b>
              <a href={"tel:" + data.mobile}>{data.mobile}</a>
            </div>
            {/* <div className="col-6 mb-1">
              <b className="fw-bold me-2">REGION:</b>
              {data.region}
            </div> */}
            
            {/* <div className="col-6 mb-1">
              <b className="fw-bold me-2">TEAM:</b>
              {data.team}
            </div> */}
            <div className="col-12 mb-1">
              <b className="fw-bold me-2">E-MAIL:</b>{" "}
              <a href={"mailto:" + data.email.trim()}> {data.email}</a>
            </div>
            <div className="col-12" hidden={data.isViewing}>
              <button
                type="button"
                className="btn-alt btn-list"
                onClick={() => UpdateProfile.toggleUpdateProfile(true)}
              >
                Update your profile{" "}
              </button>
            </div>
          </div>
        </div>
        <MiniCV
          id={data.miniResumeId}
          jobProfile={data.jobProfile}
          professionalProfile={data.professionalProfile}
          experientialProfile={data.experientialProfile}
          personalProfile={data.personalProfile}
        />
        <CameraAccess PhotoTaken={this.PhotoTaken} />
        <PhotoCropper
          imgSrc={this.state.imageData}
          OnPhotoCropped={this.PhotoCropped}
        />
      </>
    );
  }

  render() {
    let content = this.state.loading ? LoadingSpinner() : (
      this.renderProfileInfo(this.state)
    );
    return (
      <>
        {content}

        <UpdateProfile onProfileSave={this.UpdateProfile} />
        <PhotoCropper imgSrc={this.state.imageData} />
      </>
    );
  }

  loadData() {
    let user = getUser();
    let isViewing = this.props.viewing;
    console.log(user);
    if (isViewing) user = this.props.User;

    this.setState({
      userId: user.Id,
      avatar: user.Avatar,
      username: user.UserName,
      levelNo: GetUserRanking(user.Score),
      level: user.Level,
      nextLevel: GetNextLevel(user.Score),
      cycleDiamonds: user.CycleDiamonds,
      score: user.Score,
      country: user.UserCountry,
      userType: user.UserType,
      division: user.Division,
      mobile: user.UserMobile,
      region: user.Region,
      jobTitle: user.JobTitle,
      experience: user.YearsActive,
      team: user.Team ? user.Team : "N/A",
      email: user.Email,
      miniResumeId: user.MiniResumeId,
      jobProfile: user.JobProfile,
      professionalProfile: user.ProfessionalProfile,
      experientialProfile: user.ExperientialProfile,
      personalProfile: user.PersonalProfile,
      loading: false,
      editImage: false,
      imageData: "",
      filename: "",
      showNewAvatar: false,
      isViewing,
    });

    if (user.ProfessionalBodies.length > 0) {
      this.setState({
        primaryBody: user.ProfessionalBodies[0].ProfBodyAbbr,
      });
    }
  }

  async UpdateProfile() {
    this.loadData();
  }

  async ConfirmImage(evt) {
    evt.stopPropagation();
    var bearer = "Bearer " + getAuthToken();

    if (this.state.showNewAvatar) {
      let data = {
        Id: this.state.userId,
        Filename: this.state.filename,
        ImageData: this.state.imageData,
      };
      try {
        const response = await fetch(
          APIPath() + "/global/userprofile/updateavatar",
          {
            method: "PUT",
            headers: {
              Authorization: bearer,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );

        if (response.ok) {
          const user = await response.json();
          setUser(user);
          this.loadData();
        } else console.log(response.status + ": " + response.statusText);
      } catch (e) {
        console.error(e);
      }
    } else alert("No image selected");
  }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { APIPath } from '../../helpers/APIHelper';
import { getAuthToken } from '../../helpers/authentication';
import { MyContext } from '../../Provider'

export default class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            outcomes: [],
            themes: [],
            benefits: {},
            tools: []
        };
    }

    componentDidMount() {
        this.loadOutcomes();
        this.loadThemes();
        this.loadProgramBenefits();
        this.loadCompTools()
    }
    render() {
        return (
            <>
                <div className="container-toolkit-article">
                    <h1>{this.context.companyPurpose?.Name}</h1>

                    <h2>{this.context.title}</h2>
                    <p className="plr-10" id="prName"></p>
                    <div className="container-purpose mp-0 ">
                        {this.context.title.toUpperCase() === "PROGRAM" ?
                            <div className=''>
                                <div>
                                    <h6 className='thin px-2'>DESCRIPTION</h6>
                                    <div className='dashed py-3 px-2'>
                                        {this.context.companyPurpose?.Description}
                                    </div>
                                </div>
                                <div>
                                    <h6 className='thin px-2'>KNOWLEDGE MENTORING STRUCTURE</h6>
                                    <div className='dashed py-3 px-2'>
                                        {this.context.companyPurpose?.Structure?.replace("\n", ",\n")}
                                    </div>
                                </div>
                                <div>
                                    <h6 className='thin px-2'>KNOWLEDGE MENTORING ROLES</h6>
                                    <div className='dashed py-3 px-2'>
                                        {this.context.companyPurpose?.Roles?.replace("\n", ",\n")}
                                    </div>
                                </div>
                            </div>
                            : this.context.title.toUpperCase() === "PURPOSE" ?
                                <div>
                                    <div>
                                        <h6 className='thin px-2'>STRATEGIC INTENT</h6>
                                        <div className='dashed py-3 px-2'>
                                            {this.context.companyPurpose?.StratIntent}
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className='thin px-2'>PROGRAM PURPOSE</h6>
                                        <div className='dashed py-3 px-2'>
                                            {this.context.companyPurpose?.ProgramPurpose}
                                        </div>
                                    </div>
                                    <div className='dashed mb-2 py-2 px-2'>
                                        <h6 className='thin px-2'>COMPANY LINK</h6>
                                        <a href={this.context.companyPurpose?.CompanyUrl} className="text-light" target={"_blank"} rel="noreferrer"><button className='link d-flex justify-content-center align-items-center'>VISIT COMPANY WEBSITE &nbsp;<i className='fa fa-angle-right'></i></button></a>
                                    </div>
                                </div>
                                : this.context.title.toUpperCase() === "OUTCOMES AND METRICS" ?
                                    <div>
                                        <div className='px-2'>
                                            Critical Outcomes are the strategic or operational outcomes of sharing knowledge effectively in order to achieve stated organisational or community objectives. Success in achieving these knowledge sharing objectives can be measured through
                                            combinations of either qualitative and opinion-based metrics (subjective or intangible) or quantitative and fact-based metrics (objective and tangible).
                                        </div>
                                        {this.state.outcomes.map((item, index) => (
                                            <Link to='/outcomes' onClick={() => this.context.setOutcome(item)} key={index}>
                                                <div className='dashed text-muted my-4 p-2 d-flex'>
                                                    <div>
                                                        {item.Outcome}
                                                    </div>
                                                    <div><i className="fa fa-angle-right gray"></i></div>
                                                </div>
                                            </Link>))
                                        }
                                    </div> : this.context.title.toUpperCase() === "THEMES AND KNOWLEDGE AREAS" ? <div>
                                        <div className='px-2'>Knowledge Themes are used to focus contextual conversations into easily filtered and searchable clusters and can consist of several Knowledge Areas. For example, FINANCIAL MATTERS as a Knowledge Theme could consist of Invoicing, Month End and Accounting Practice as the different Knowledge Areas. They are essentially structured conversation topics to form a dynamic mobile knowledge repository.</div>
                                        {this.state.themes.map((theme, index) => (
                                            <div className='my-2 dashed p-2' key={index}>
                                                <div className='my-2'>
                                                    <b>THEME: </b>{theme?.ThemeName}
                                                </div>
                                                {theme?.KnowledgeFields && <div >
                                                    <b>Area: </b>
                                                    <div>{theme?.KnowledgeFields} </div>
                                                </div>}
                                            </div>
                                        ))}
                                    </div>
                                        : this.context.title.toUpperCase() === "PROGRAM BENEFITS" ? <div>
                                            <div className='px-2'>
                                                The effective sharing of contextual knowledge in organisations and across large widely dispersed groups has been shown to have significant benefits in multiple areas of human capital development. These can include both organisation benefits as well as personal benefits and understanding these and how participants can contribute meaningfully builds improved engagement and commitment.
                                            </div>
                                            <div>
                                                <h5 className='my-2 text-muted thin px-2'>ORGANSATIONAL BENEFIT</h5>
                                                <div className='dashed p-2'>{this.state?.benefits?.OrgBenefits}</div>
                                            </div>
                                            <div>
                                                <h5 className='my-2 text-muted thin px-2'>INDIVIDUAL BENEFIT</h5>
                                                <div className='dashed p-2'>{this.state?.benefits?.IndBenefits} </div>
                                            </div>
                                        </div>
                                            : this.context.title.toUpperCase() === "COLLABORATION TOOLS" ? <div>
                                                <div className='px-2'>
                                                    Research show that human-to-human learning is accelerated and enhanced many times over if people use consistent and mutually understood terminologies, mental models and knowledge sharing techniques. The objective of suggesting specific knowledge sharing tools is to drive a level of consistent collaboration using techniques that become increasing familiar and powerful.
                                                </div>
                                                {this.state.tools.map((tool, index) => (
                                                    <Link className='dashed py-2 d-flex justify-content-between align-items-center' key={index} to="/collaborationdetails" onClick={() => { this.context.setCollaboration(tool) }}>
                                                        <h6 className='thin px-2'>{tool?.toolDescription}</h6>
                                                        <div className='px-2'><i className='fa fa-angle-right gray'></i></div>
                                                    </Link>
                                                ))}
                                            </div> : <></>}
                    </div>
                </div>
            </>
        )
    }

    async loadOutcomes() {
        const bearer = "Bearer " + getAuthToken();
        const UserData = JSON.parse(localStorage.getItem("CurrentUser"))
        try {
            const response = await fetch(
                APIPath() + `/nowculi/OutcomesAndMetrics?id=${UserData?.OrganisationId}&userId=${UserData?.Id}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                const outcomes = await response.json();
                this.setState({ outcomes })
            } else {
                console.log(response.status + ": " + response.statusText);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async loadThemes() {
        const bearer = "Bearer " + getAuthToken();
        try {
            const response = await fetch(
                APIPath() + `/nowculi/Themes/ListForCompany`,
                {
                    method: "GET",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                const themes = await response.json();
                this.setState({ themes })
            } else {
                console.log(response.status + ": " + response.statusText);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async loadProgramBenefits() {
        const bearer = "Bearer " + getAuthToken();
        const UserData = JSON.parse(localStorage.getItem("CurrentUser"))
        try {
            const response = await fetch(
                APIPath() + `/nowculi/ProgramBenefits?id=${UserData?.OrganisationId}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                const benefits = await response.json();
                this.setState({ benefits })
            } else {
                console.log(response.status + ": " + response.statusText);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async loadCompTools() {
        const bearer = "Bearer " + getAuthToken();
        const UserData = JSON.parse(localStorage.getItem("CurrentUser"))
        try {
            const response = await fetch(
                APIPath() + `/nowculi/compTools?id=${UserData?.OrganisationId}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.ok) {
                const tools = await response.json();
                this.setState({ tools })
            } else {
                console.log(response.status + ": " + response.statusText);
            }
        } catch (e) {
            console.error(e);
        }
    }
}

Detail.contextType = MyContext
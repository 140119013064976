import React, { Component } from 'react';

import { getAuthToken } from '../../helpers/authentication';
import { APIPath } from '../../helpers/APIHelper';
import oneStar from '../../assets/questions/1star.png';
import twoStar from '../../assets/questions/2star.png';
import threeStar from '../../assets/questions/3star.png';
import fourStar from '../../assets/questions/4star.png';
import fiveStar from '../../assets/questions/5star.png';
import executive from '../../assets/questions/executive.png';
import { getUser } from '../../helpers/lookup';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import swal from 'sweetalert';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';

export class Rating extends Component {


  constructor(props) {
    super(props);
    this.state = { initiation: [], loading: true, modal: true, AvgRate: 0, ExecRateCount: 0, TotalVotes: 0, ratings: [oneStar, twoStar, threeStar, fourStar, fiveStar, executive], score: 0, groupCycle: getUser().Cycle };
  }

  componentDidMount() {
    document.title = "NOW.CULI® :: Rating";
    this.loadData();

  }

  async handleRating(data) {
    let score;
    if (data === 0) {
      score = 1
    } else if (data === 1) {
      score = 2
    } else if (data === 2) {
      score = 3
    } else if (data === 3) {
      score = 4
    } else if (data === 4) {
      score = 5
    } else {
      score = 50
    }
    await this.setState({ score: score })
    this.updateRating();
  }

  render() {
    let userType = getUser().UserType;
    let type = this.state.initiation.CollaborateType;
    return (
      <>
        <Modal isOpen={this.state.modal} className="modal-cyan" toggle={this.toggle} zIndex={30000}>
          <ModalHeader className="shadow-sm" toggle={this.toggle}>{type === "INSIGHT" ? "PROVIDE A RATING" : "RATE RESPONSE"}</ModalHeader>
          <ModalBody>
            <div className='text-center' data-role="content">
              <p>Please select the rating you would like to give</p>
              {this.state.ratings.map((rating, index) => {
                return (
                // return (userType === "Executive" || userType === "Executor" || userType === "SME" || index !== (this.state.ratings.length - 1)) &&
                  <div key={index} className='mb-3' onClick={e => { this.updateRating(index); }}>
                    <div role="button" id="score1" className='btn-rating'><img src={rating} alt="" className="img-fluid" /></div>
                  </div>
                )
              })}
              <Link to={-1} role="button" className="btn-small-action" data-transition="flow" data-rel="back">Cancel</Link>
            </div>
            <ThrobbleHelper />
          </ModalBody>
        </Modal>
      </>
    );
  }

  async loadData() {
     
    let idx = window.location.href.lastIndexOf("/");
    let initId = window.location.href.substring(idx + 1);
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch(APIPath() + '/nowculi/initiations/getsingle/' + initId, {
        method: 'GET',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ initiation: data, AvgRate: data.AvgRate, ExecRateCount: data.ExecRateCount, TotalVotes: data.TotalVotes });
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(APIPath() + '/nowculi/Responses?Offset=0&Limit=1&initiationId=' + initId, {
        method: 'GET',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ responses: data[0], loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }
  }

  async updateRating(idx) {

    ThrobbleHelper.toggleThrobble(true, "Updating score...");
    var bearer = 'Bearer ' + getAuthToken();

    let score;
    if (idx === 0) {
      score = 1
    } else if (idx === 1) {
      score = 2
    } else if (idx === 2) {
      score = 3
    } else if (idx === 3) {
      score = 4
    } else if (idx === 4) {
      score = 5
    } else {
      score = 50
    }
    let user = getUser();
    let data = {
      Id: this.state.initiation.Id,
      GroupCycle: user.Cycle,
      Score: score,
      RatingUser: user.Id
    }

    try {
      const response = await fetch(APIPath() + "/nowculi/initiations/", {
        method: "PUT",
        headers: {
          "Authorization": bearer,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        ThrobbleHelper.toggleThrobble(false);
        swal("Thank you!", "Your rating has been saved!", "success")
          .then(() => window.history.back());
      } else {
        ThrobbleHelper.toggleThrobble(false);
        swal("Rating", "There was an error with rating. Please try again!", "error")
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

  }
}


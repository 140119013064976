/* eslint-disable eqeqeq */
/* eslint-disable default-case */
import React, {Fragment} from "react";
import { getCreatedAt, getStepsWithPoints, getUserEmail, getUserGroup, getWalkthroughPoints, setStepsWithPoints, setWalkThroughPoints } from "./lookup";
import sha256 from "./EncryptionHelper";
import { getAuthToken, GetUserMobile, SetUserMobile } from "./authentication";
import { APIPath } from "./APIHelper";

const bearer = "bearer " + getAuthToken();

const UrlMatcher = /\b(?:https?:\/\/|www\.)[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})[^\s]*/ig;
const UrlValidator = /^(https?:\/\/)?(?:www\.)?[a-z\d.-]+\.[a-z]{2,}(?:\/[^?\s]*)?(?:\?[^#\s]*)?(?:#[^\s]*)?$/;

export const LoadingSpinner = () => { 
  return (
    <div className="text-center">
      <i className="fas fa-spinner fa-spin fa-2x"></i><br />Loading...
    </div>
  );

}

export const AddListData = (title, data) => {
  if (data != null && data !== "" && data !== undefined)
    return (
      <>
        <dt className="col-md-6 text-end">{title} </dt>
        <dd className="col-md-6">{data} </dd>
      </>
    );
  else return <div className="d-none"></div>;
};

const DaysOfTheWeekData = [
  { value: 0, text: "Sunday" },
  { value: 1, text: "Monday" },
  { value: 2, text: "Tuesday" },
  { value: 3, text: "Wednesday" },
  { value: 4, text: "Thursday" },
  { value: 5, text: "Friday" },
  { value: 6, text: "Saturday" },
];

export const GetDaysOfTheWeekList = () => {
  return DaysOfTheWeekData;
};

export const RenderStringWithTags = (text) => {
  return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
};

export const GetAvatar = (avatar) => {
  var avatarImageUrl;
  if (
    avatar &&
    !avatar.includes("android_asset") &&
    avatar.length > 7
  ) {
    if (avatar.startsWith("data:image") || avatar.startsWith("http")) {
      avatarImageUrl = avatar;
    } else {
      avatarImageUrl = avatar ? "https://" + avatar : avatar;
    }
  } else {
    avatarImageUrl = process.env.PUBLIC_URL + "/img/noAvatar1.png";
  }
  return avatarImageUrl;
};

export const GetDiamondIcon = (Diamonds) => {
  let iconUrl;
  if (Diamonds > 0) iconUrl = "/img/questions/diamond-active.png";
  else iconUrl = "/img/questions/diamond-inactive.png";
  return iconUrl;
};

export const GetFrameClass = (score) => {
  let rank = GetUserRanking(score);
  if (rank > 0) return "frame" + rank;
  else return "";
};

export const GetUserRanking = (score) => {
  if (score < 1200) return 1;
  else if (score >= 1200 && score < 1800) return 2;
  else if (score >= 1800 && score < 2800) return 3;
  else if (score >= 2800 && score < 4400) return 4;
  else if (score >= 4400 && score < 6900) return 5;
  else if (score >= 6900 && score <= 10750) return 6;
  else if (score > 10750) return 7;
};

export const GetCurrentLevel = (Score) => {
  const rank = GetUserRanking(Score);
  switch (rank) {
    case 2:
      return "2. Aspirant Adventurer";
    case 3:
      return "3. Daring Discoverer";
    case 4:
      return "4. Solid Sage";
    case 5:
      return "5. Knowledge Knight";
    case 6:
      return "6. Mentoring Master";
    case 7:
      return "7. Worthy Wizard";
    default:
      return "1. Erudite Explorer";
  }
}

export const GetNextLevel = (score) => {
  let rank = GetUserRanking(score);
  switch (rank) {
    case 1:
      return "2. Aspirant Adventurer";
    case 2:
      return "3. Daring Discoverer";
    case 3:
      return "4. Solid Sage";
    case 4:
      return "5. Knowledge Knight";
    case 5:
      return "6. Mentoring Master";
    case 6:
      return "7. Worthy Wizard";
    default:
      return "None";
  }
};

export const GetNextLevelDiff = (score) => {
  let rank = GetUserRanking(score);

  switch (rank) {
    case 1:
      return 1200 - score;
    case 2:
      return 1800 - score;
    case 3:
      return 2800 - score;
    case 4:
      return 4400 - score;
    case 5:
      return 6900 - score;
    case 6:
      return 10750 - score;
    default:
      return 0;
  }
};

export const EncryptPassword = (password) => {
  let key = GetPersonalKey();
  let output = sha256(key + password);

  return output;
};

const GetPersonalKey = () => {
  let org = getUserGroup();
  let os = org.split(" ");
  let oKey = org.charAt(0) + org.charAt(os[0].length - 1);

  let es = getUserEmail();
  let es1 = es[1].split(".");
  let eKey =
    es[0].charAt(0) +
    es[0].charAt(es[0].length - 1) +
    es1[0].charAt(0) +
    es1[0].charAt(es1[0].length - 1);

  let cDate = new Date(getCreatedAt());
  let yr = String(cDate.getFullYear());
  var dKey = yr.charAt(2) + yr.charAt(3) + cDate.getDate() + cDate.getMinutes();

  return sha256(oKey + eKey + dKey);
};

export const linter = (iText) => {
  // const urlRegex = /(?:https?:\/\/)?(?i)[a-z]+(?:[.\/](?!http)[a-z]+)+\/?(?:\?[^\s,.]+)?/g;
  // const urlRegex = /\b(?:https?:\/\/)?(?:www\.)?([\w-]+(?:\.\S+[\w-]+)+)\b/gi;
  // const urlRegex = /\b(?:https?:\/\/)?(?:www\.)?([\w-]+(?:\.(?:com|org|net|co\.za)\b)(?:\S+[\w-]+)+)\b/gi;
  const matches = iText.match(UrlMatcher);
  if (matches) {
    iText = replaceLink(UrlMatcher, iText);
    return iText;
  } else {
    return iText;
  }
};

const replaceLink = (urlRegex, strValue) => {
  return strValue.replace(urlRegex, function (url) {
    if (UrlValidator.test(url)) {
      let hrefUrl = url;
      if (url.toLowerCase().startsWith("www")) {
        hrefUrl = "https://" + url;
      }
      url = SimplifyLinkText(url);
      return `<a href='${hrefUrl}' target='_blank' rel='noreferrer'>${url}</a>`
    } else
      return url;
  })
}

const SimplifyLinkText = (url) => {
  try {
    if (url && url != "") {
      return new URL(url).origin;
    }
    else return url;
  } catch (E) {
    return url;
  }
}

export const delint = (iText) => {
  var tagPattern = /<a\s+[^>]*>.*?<\/a>/gi;
  var hasLink = tagPattern.test(iText);
  if (hasLink) {
    const replaceLinkTextRegex = /(<a\s+[^>]*>)(.*?)(<\/a>)/gi;
    return iText.replace(replaceLinkTextRegex, (_match, _openTag, LinkText) => {
      const GetLink = _openTag.match(/(?<=href=')(.*?)(?=')/gi);
      if (GetLink) {
        if (GetLink > 1)
          return GetLink[1];
        else
          return GetLink[0];
      }
    })
  } else
    return iText;
}

export const ShortenLink = (InputString) => {
  const replaceLinkTextRegex = /(<a\s+[^>]*>)(.*?)(<\/a>)/gi;
  return InputString.replace(replaceLinkTextRegex, (_match, openTag, LinkText, closeTag) => {
    var result = "";
    var matches = LinkText.match(UrlMatcher);
    if (matches && matches.length > 0) {
      if (UrlValidator.test(matches[0]))
        result = SimplifyLinkText(matches[0]);
      else
        result = matches[0];
    }
    return `${openTag}${result}${closeTag}`;
  })
}

const scrumbleString = (str) => {
  const charMap = {
    'a': 1,
    'b': 2,
    'c': 3,
    'd': 4,
    'e': 5,
    'f': 6,
    'g': 7,
    'h': 8,
    'i': 9,
    'j': 0,
    'k': 1,
    'l': 2,
    'm': 3,
    'n': 4,
    'o': 5,
    'p': 6,
    'q': 7,
    'r': 8,
    's': 9,
    't': 0,
    'u': 1,
    'v': 2,
    'w': 3,
    'x': 4,
    'y': 5,
    'z': 6,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    '0': 0,
  };

  const result = str.replace(/\W/g, "").split("").map(c => charMap[c.toLowerCase()]).join("");
  return result;
}

export const UpdateWalkthroughPoints = (step) => {

  let CurrentPoints = getWalkthroughPoints();
  let StepsWithPoints = getStepsWithPoints();

  CurrentPoints += 50;
  StepsWithPoints.push(step);

  setWalkThroughPoints(CurrentPoints);
  setStepsWithPoints(StepsWithPoints);
}

export const GetAddressString = (AddressComponent) => {
  let result = "";
  if (AddressComponent) {
    if (typeof AddressComponent == "string")
      AddressComponent = JSON.parse(AddressComponent);

    result = [
      ((AddressComponent[0] && AddressComponent[0].short_name) ? AddressComponent[0].short_name : ''),
      ((AddressComponent[1] && AddressComponent[1].short_name) ? AddressComponent[1].short_name : ''),
      ((AddressComponent[2] && AddressComponent[2].short_name) ? AddressComponent[2].short_name : ''),
      ((AddressComponent[3] && AddressComponent[3].short_name) ? AddressComponent[3].short_name : ''),
      ((AddressComponent[4] && AddressComponent[4].short_name) ? AddressComponent[4].short_name : ''),
    ].join(', ');
  }
  return result;
}

export const SummariseString = (data, maxCharacters) => {
  if (data && data.length > maxCharacters) {
    return data.substring(0, maxCharacters) + "...";
  } else {
    return data;
  }
}

export const Ordinate = function (data) {
  var str = data.toString().slice(-1),
    ord = '';
  switch (str) {
    case '1':
      ord = 'st';
      break;
    case '2':
      ord = 'nd';
      break;
    case '3':
      ord = 'rd';
      break;
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
    case '0':
      ord = 'th';
      break;
  }
  return (<Fragment>{data}<sup>{ord}</sup></Fragment>);
}
